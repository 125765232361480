import React, { useCallback, useContext, useReducer } from 'react';
import { sammyBeautyApi } from '../service';
import { ErrorResponse, PageDto } from '../api/types';

enum ActionKind {
  FETCH_START = 'PAGE/FETCH_START',
  FETCHED_SUCCESS = 'PAGE/FETCHED_SUCCESS',
  FETCHED_FAIL = 'PAGE/FETCHED_FAIL',
}

type FetchStartAction = {
  type: ActionKind.FETCH_START;
  payload: null;
};

type FetchedSuccessAction = {
  type: ActionKind.FETCHED_SUCCESS;
  payload: { page: PageDto };
};

type FetchedFailedAction = {
  type: ActionKind.FETCHED_FAIL;
  payload: { error: ErrorResponse };
};

type Action = FetchStartAction | FetchedSuccessAction | FetchedFailedAction;

type State = {
  isLoading: boolean;
  page: PageDto | null;
  error: ErrorResponse | null;
  load: (slug: string) => Promise<void>;
};

const initialState: State = {
  isLoading: true,
  page: null,
  error: null,
  load: () => Promise.resolve(),
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionKind.FETCH_START:
      return { ...state, isLoading: true, page: null, error: null };

    case ActionKind.FETCHED_SUCCESS:
      return { ...state, isLoading: false, page: action.payload.page, error: null };

    case ActionKind.FETCHED_FAIL:
      return { ...state, isLoading: false, page: null, error: action.payload.error };

    default:
      return state;
  }
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isLoading, page, error } = state;

  const load = useCallback((slug: string) => {
    dispatch({ type: ActionKind.FETCH_START, payload: null });

    return sammyBeautyApi()
      .page(slug)
      .then((loadedPage: PageDto) => {
        const localPage = {
          ...loadedPage,
          banner_desktop: loadedPage.banner_desktop?.id ? loadedPage.banner_desktop : null,
          banner_mobile: loadedPage.banner_mobile?.id ? loadedPage.banner_mobile : null,
        };

        dispatch({ type: ActionKind.FETCHED_SUCCESS, payload: { page: localPage } });
      })
      .catch((localError: ErrorResponse) => {
        dispatch({ type: ActionKind.FETCHED_FAIL, payload: { error: localError } });
      });
  }, []);

  return { isLoading, page, error, load };
};

const PageContext = React.createContext<State>(initialState);
PageContext.displayName = 'PageContext';

export const usePage = () => useContext(PageContext);

export const PageStore: React.FC = ({ children }) => (
  <PageContext.Provider value={useHook()}>{children}</PageContext.Provider>
);
