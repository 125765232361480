import React from 'react';
import cn from 'classnames';
import cardImage from '../../data/cake-awards/cake-award.png';
import cardx2Image from '../../data/cake-awards/cake-award-x2.png';
import './cake-awards-feature.css';

export const CakeAwardsFeature = ({ cakeCount, cakesIncrease }) => {
  const isX2 = cakesIncrease >= 2;
  const twoDigits = cakeCount > 9;

  return (
    <div className="CakeAwardsFeature">
      <div
        className={cn('CakeAwardsFeature-tooltip', {
          'CakeAwardsFeature-tooltip--x2': isX2,
        })}>
        <div
          className={cn('CakeAwardsFeature-text', {
            'CakeAwardsFeature-text--x2': isX2,
            'CakeAwardsFeature-text--twoDigits': twoDigits,
          })}>
          {cakeCount}
          <img className="CakeAwardsFeature-img" src={isX2 ? cardx2Image : cardImage} alt="" />
          <div className="CakeAwardsFeature-border" />
        </div>
      </div>
    </div>
  );
};
