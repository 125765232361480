export const getLocalStorage = (key) => {
  const localStorageValue = localStorage.getItem(key);

  return localStorageValue ? JSON.parse(localStorageValue) : null;
};

export const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

// get/set cakeCount
export const getPersistedCakeCount = () => {
  return getLocalStorage('__persisted_cake_count') || 0;
};

export const setPersistedCakeCount = (value) => setLocalStorage('__persisted_cake_count', value);

// get/set wonProducts
export const getPersistedWonProducts = () => {
  return getLocalStorage('__persisted_won_products') || [];
};

export const setPersistedWonProducts = (value) => setLocalStorage('__persisted_won_products', value);

// get/set picked for change products
export const getPersistedPickedForChangeProducts = () => {
  return getLocalStorage('__persisted_picked_products') || [];
};

export const setPersistedPickedForChangeProducts = (value) => setLocalStorage('__persisted_picked_products', value);

// get/set isCakeActive
export const getPersistedIsCakeActive = () => {
  return getLocalStorage('__persisted_is_cake_active') || false;
};

export const setPersistedIsCakeActive = (value) => setLocalStorage('__persisted_is_cake_active', value);

// get/set cakeTimer
export const getPersistedCakeTimer = () => {
  return getLocalStorage('__persisted_cake_timer') || 1200;
};

export const setPersistedCakeTimer = (value) => setLocalStorage('__persisted_cake_timer', value);

// get/set validationOrderNumber
export const getPersistedValidationOrderNumber = () => {
  return getLocalStorage('__persisted_validation_order_number') || null;
};

export const setPersistedValidationOrderNumber = (value) =>
  setLocalStorage('__persisted_validation_order_number', value);

// get/set isValidUser
export const getPersistedIsValidUser = () => {
  return getLocalStorage('__persisted_is_valid_user') || false;
};

export const setPersistedIsValidUser = (value) => setLocalStorage('__persisted_is_valid_user', value);
