import React, { useContext, createContext, useEffect, useMemo, useReducer } from 'react';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';

// Types
const FETCH = 'COUNTRIES/FETCH';
const RESPONSE = 'COUNTRIES/RESPONSE';

// actions

const actions = {
  fetch: () => {
    return createAction(FETCH);
  },
  response: (countries) => {
    return createAction(RESPONSE, { countries });
  },
};

const initialState = {
  countries: null,
};

// reducers

const reducer = (state, { type, payload }) => {
  switch (type) {
    case RESPONSE: {
      return { countries: [...payload.countries] };
    }
    default:
      return state;
  }
  // return state
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { countries } = state;

  const { response } = bindActionsToDispatch(actions, dispatch);

  const isFetched = useMemo(() => countries !== null, [countries]);
  const isListExist = useMemo(() => Array.isArray(countries) && countries.length > 0, [countries]);

  useEffect(() => {
    //   setTimeout(()=>{
    //     response(data)
    // }, 1000)
    sammyBeautyApi()
      .countries()
      .then((data) => response(data))
      .catch((error) => {
        // response(data)
        console.warn('sammyBeautyApi().countries()', error);
      });
  }, []);

  return [{ countries, isFetched, isListExist }, {}];
};

const CountriesContext = createContext();
const useCountries = () => useContext(CountriesContext);

const CountriesStore = ({ children }) => (
  <CountriesContext.Provider value={useHook()}>{children}</CountriesContext.Provider>
);

CountriesContext.displayName = 'CountriesContext';

export { CountriesStore, useCountries };
