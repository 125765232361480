import React from 'react';
import { YMaps, Map, ObjectManager } from 'react-yandex-maps';
import isEqual from 'react-fast-compare';

import { PickPointInfo } from '../../pick-point-selected/pick-point-selected';

export const PickPointDialogMapWrapper = React.memo(
  ({
    setMapElementRef,
    mapState,
    setIsMapLoading,
    placeMarks,
    getRef,
    selectedPickPoint,
    closePickPointInfo,
    handleSelect,
    style,
    selectedId,
    chosenId,
    setYmaps,
    onClick,
    isListSelected,
  }) => (
    <div ref={setMapElementRef} className="PickPointDialog-mapWrapper" style={style} onClick={onClick}>
      <YMaps>
        <Map
          className="PickPointDialog-map"
          state={mapState}
          onLoad={(ymaps) => {
            setIsMapLoading(false);
            setYmaps(ymaps);
          }}
          style={style}>
          <ObjectManager
            options={{
              clusterize: true,
              gridSize: 70,
            }}
            clusters={{
              clusterIconColor: '#916EB8',
            }}
            features={placeMarks}
            modules={['objectManager.addon.objectsBalloon', 'suggest']}
            instanceRef={getRef}
          />
        </Map>
      </YMaps>

      {!isListSelected && selectedPickPoint && (
        <PickPointInfo
          isSelected={chosenId === selectedId}
          pickPoint={selectedPickPoint}
          onClose={closePickPointInfo}
          onChoose={handleSelect}
        />
      )}
    </div>
  ),
  isEqual,
);
