import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import cn from 'classnames';
import Highlighter from 'react-highlight-words';
import { plural } from 'utils';
import { Input } from '../../input/input';
import { IconSearch } from '../../icons';

const COUNT_TYPES = ['пункт', 'пункта', 'пунктов'];
const MAX_SUGGESTS = 10;

const renderHighLightText = (text, searchWords) => {
  return (
    <Highlighter
      highlightClassName="SearchQuery-highlight"
      activeIndex={-1}
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={text}
    />
  );
};

export const PickPointDialogFilter = React.memo(({ isDropdownOpen, setIsDropdownOpen, points, selectPickPoint }) => {
  const [suggests, setSuggests] = useState([]);
  const [value, setValue] = useState('');

  const getSuggests = async (address) => {
    const parts = address.toLowerCase().split(' ');

    return points
      .filter((p) => {
        const addressLowerCased = p.address.toLowerCase();
        const titleLowerCased = p.title.toLowerCase();
        return (
          parts.every((part) => addressLowerCased.includes(part)) ||
          parts.every((part) => titleLowerCased.includes(part))
        );
      })
      .slice(0, MAX_SUGGESTS);
  };

  const setSuggestsByValue = async (address) => {
    const newSuggests = await getSuggests(address);
    setSuggests(newSuggests);
  };

  const onChange = (e) => {
    setValue(e);
    setSuggestsByValue(e);
  };

  const handleSearchFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownItemClick = (item) => {
    setValue(item.address);
    setIsDropdownOpen(false);
    selectPickPoint(item);
  };

  return (
    <div className="PickPointDialog-filter">
      <Input
        isExpanded
        placeholder="Введите название или адрес"
        appended={<IconSearch />}
        onFocus={handleSearchFocus}
        onChange={onChange}
        value={value}
      />
      <div className="PickPointDialog-filterAllCount">Доступно {plural(points.length, COUNT_TYPES)} выдачи</div>
      <div
        className={cn('PickPointDialog-filterDropdown', {
          'PickPointDialog-filterDropdown--closed': !isDropdownOpen,
        })}>
        {Array.isArray(suggests) &&
          suggests.map((item, idx) => (
            <div className="PickPointDialog-dropdownItem" key={idx} onClick={() => handleDropdownItemClick(item)}>
              {renderHighLightText(item.address, value.split(' '))}
            </div>
          ))}
      </div>
    </div>
  );
}, isEqual);
