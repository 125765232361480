import { useState, useEffect } from 'react';

const getViewportWidth = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const getViewportHeight = () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
const getSizes = () => {
  const isSSR = typeof window === 'undefined';
  return {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    // width: undefined,
    // height: undefined
    width: isSSR ? 1200 : getViewportWidth(),
    height: isSSR ? 800 : getViewportHeight(),
  };
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSizes());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSizes());
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
