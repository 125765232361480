import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { animated, SpringValue } from '@react-spring/web';
import styles from './styles.module.scss';

export type TOverlay = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  key?: string;
  style?: CSSProperties | { opacity: SpringValue };
};

export const Overlay: React.FunctionComponent<TOverlay> = ({ children, onClick, style, className }) => {
  return (
    <animated.div onClick={onClick} style={style} className={cn(styles.overlay, className)}>
      {children}
    </animated.div>
  );
};
