import React, { RefObject, useRef } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { IconContainer } from 'tsComponents/@ui-kit/IconContainer';
import cn from 'classnames';
import { Portal } from 'react-portal';
import styles from './styles.module.scss';
import { IconCross } from '../../../../components/icons';
import { useScrollBarWidth } from '../../../../hooks';
import { Overlay } from '../common/Overlay';

function checkIsOverlay(windowElement: RefObject<HTMLDivElement>, target: EventTarget) {
  return !windowElement.current?.contains(target as Node);
}

const springConfig = {
  mass: 1,
  friction: 25,
};

export type TSideModal = {
  visible: boolean;
  onClose: () => void;
  isRight?: boolean;
};

export const SideModal: React.FunctionComponent<TSideModal> = ({ visible, isRight, onClose, children }) => {
  const windowElement = useRef<HTMLDivElement>(null);
  const scrollBarWidth = useScrollBarWidth();

  const right = visible ? '0' : `-${scrollBarWidth}px`;

  const transform = isRight ? 100 : -100;

  const transitions = useTransition([visible], {
    from: { opacity: 0, transform: `translateX(${transform}%)` },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: `translateX(${transform}%)` },
    config: springConfig,
  });

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target && checkIsOverlay(windowElement, e.target)) {
      onClose();
    }
  };

  return transitions(
    (props, item) =>
      item && (
        <Portal>
          <Overlay onClick={handleOverlayClick} style={{ right, opacity: props.opacity }}>
            <div className={styles.container}>
              <animated.div
                style={props}
                ref={windowElement}
                className={cn(styles.modal, {
                  [styles.rightOrientation]: isRight,
                })}>
                <button className={styles.close} onClick={onClose}>
                  <IconContainer>
                    <IconCross />
                  </IconContainer>
                </button>
                {children}
              </animated.div>
            </div>
          </Overlay>
        </Portal>
      ),
  );
};
