import React from 'react';
import './icon-faq.css';

export function IconFAQ() {
  return (
    <svg className="IconFAQ" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        strokeWidth="0.5"
        d="M7.1 8.4h.5c0-1.3.7-2.6 2.4-2.6 1.3 0 2.3.8 2.3 1.8s-.8 1.6-1.5 2.2c-.8.8-1 1.2-1 2.4v.1h.5c0-1.2.1-1.4.9-2.2.9-.7 1.7-1.4 1.7-2.6 0-1.3-1.1-2.3-2.8-2.3-2.1.2-3 1.6-3 3.2zm3.2 4.9h-.6v1.3h.6v-1.3z"
      />
      <circle cx="10" cy="10" r="9" fill="none" strokeWidth=".5" />
    </svg>
  );
}
