import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { usePromocode } from '../../stores';
import { Button } from '../button/button';
import { IconFlatLoader } from '../icons';
import './gifts-dialog.css';

export const GiftsDialogItem = ({ gift }) => {
  const [isLoadGift, setIsLoadGift] = useState(false);
  const [{ isDisabled }, { setPromoCodeGift }] = usePromocode();

  const handleChangeGifts = async (evt) => {
    evt.preventDefault();

    if (isDisabled) {
      return;
    }

    setIsLoadGift(true);
    await setPromoCodeGift(gift);
    setIsLoadGift(false);
  };

  return (
    <div className="GiftsDialog__item" key={gift.variant_id}>
      <div className="GiftsDialog__img">
        <img src={gift.product.media && gift.product.media[0].sources[0].main.normal} alt={gift.product.title} />
      </div>
      <div className="GiftsDialog__title">{gift.product.title}</div>
      <div className="GiftsDialog__button">
        <Button
          onClick={handleChangeGifts}
          title="Выбрать"
          component={Link}
          isPrimary
          isExpanded
          appendIcon={isLoadGift && <IconFlatLoader />}
        />
      </div>
    </div>
  );
};
