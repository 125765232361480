import React from 'react';
import './form.css';

export const Form = ({ children, onSubmit, ...rest }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form className="Form" onSubmit={handleSubmit} {...rest}>
      {children}
    </form>
  );
};

Form.Row = ({ children }) => <div className="Form-row">{children}</div>;
