import React, { useState } from 'react';
import { PromocodeInputSuggestion } from '../promocode-input-suggestion/promocode-input-suggestion';
import { PromocodeField } from '../promocode-field/promocode-field';
import { PromocodeInput } from '../promocode-input/promocode-input';
import { AppliedPromocode } from '../applied-promocode/applied-promocode';
import { Form } from '../form/form';
import { OpacityTransitionElement } from '../opacity-transition-element/opacity-transition-element';
import { usePromocodeDelayedResetting } from './use-promocode-delayed-resetting';
import { useOrderDeliveryMethods, useOrderDeliveryData } from '../../stores/order';
import './promocode.css';

const TRANSITION_DURATION_MS = 300;

export const Promocode = ({
  promocode,
  inputValue,
  onInputValueChange,
  error,
  isDisabled,
  onCancel,
  onSubmit: submit,
}) => {
  const [, { request }] = useOrderDeliveryMethods();
  const [{ data }] = useOrderDeliveryData();
  const [wasChanged, setWasChanged] = useState(!!promocode);
  const localPromocode = usePromocodeDelayedResetting(promocode, TRANSITION_DURATION_MS);

  const onSubmit = () => {
    const { orderer } = data;
    submit(request, orderer.ordererCity);
  };

  return (
    <div className="Promocode">
      <OpacityTransitionElement _in={!!promocode} transitionDuration={TRANSITION_DURATION_MS}>
        <AppliedPromocode
          promocodeString={localPromocode?.code}
          description={localPromocode?.description}
          onCancel={() => onCancel(promocode)}
        />
      </OpacityTransitionElement>

      <OpacityTransitionElement _in={!promocode && wasChanged} transitionDuration={TRANSITION_DURATION_MS}>
        <Form onSubmit={() => onSubmit(inputValue)}>
          <PromocodeField error={error}>
            <PromocodeInput
              value={inputValue}
              isDisabled={isDisabled}
              onChange={onInputValueChange}
              wasChanged={wasChanged}
            />
          </PromocodeField>
        </Form>
      </OpacityTransitionElement>

      <OpacityTransitionElement _in={!promocode && !wasChanged} transitionDuration={TRANSITION_DURATION_MS}>
        <PromocodeInputSuggestion onClick={() => setWasChanged(true)} />
      </OpacityTransitionElement>
    </div>
  );
};
