import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSettings } from 'stores';
import { BasketProduct } from '../basket-product/basket-product';
import { CustomScrollbar } from '../custom-scrollbar/custom-scrollbar';
import { useMedia } from '../../hooks';
import { CakeAwardsCard } from '../cake-awards-card/cake-awards-card';
import { plural } from '../../utils';
import './basket-product-list.css';

const Wrapper = ({ children }) => {
  const isMobile = useMedia('(max-width: 840px)');

  if (isMobile) {
    return children;
  }

  return <CustomScrollbar verticalTrackRight="-20px">{children}</CustomScrollbar>;
};

export const ItemInfoBlock = ({ text }) => <div className="BasketProductList-item--info">{text}</div>;

export const BasketProductList = ({
  productList,
  cakesIncrease,
  onRemoveClick,
  onAmountChange,
  isLoading,
  cakeCount,
}) => {
  const cakeCountNum = cakesIncrease ? cakesIncrease * cakeCount : cakeCount;

  const [{ lotteryAvailable }] = useSettings();
  return (
    <div className="BasketProductList">
      <Wrapper>
        <div className="BasketProductList-el">
          {productList.map((goodsItem) => {
            const { product, amount, price, id, gift, alert_messege: messageAlert } = goodsItem;

            return (
              <div key={id} className="BasketProductList-item">
                <BasketProduct
                  product={product}
                  amount={amount}
                  onRemoveClick={onRemoveClick}
                  onAmountChange={onAmountChange}
                  isLoading={isLoading}
                  price={price}
                  row={goodsItem}
                  isGift={gift}
                />

                {isLoading && messageAlert ? (
                  <Skeleton height={'100%'} containerClassName="BasketProductList-alertSkeleton" />
                ) : messageAlert ? (
                  <ItemInfoBlock text={messageAlert} />
                ) : null}
              </div>
            );
          })}

          {Boolean(cakeCount) && lotteryAvailable && (
            <div className="BasketProductList-cakeAwardsCard">
              {isLoading ? (
                <Skeleton height="100%" containerClassName="BasketProductList-cakeSkeleton" />
              ) : (
                <CakeAwardsCard
                  cakeCount={cakeCountNum}
                  textClassName="BasketProductList-customText"
                  imageClassName="BasketProductList-customImage"
                  inCart={true}>
                  <span className="BasketProductList-textContent">
                    <b className="BasketProductList-boldTextContent">
                      У Вас {plural(cakeCountNum, ['подарок', 'подарка', 'подарков'])}!
                    </b>
                    <br />
                    Получите их после оплаты
                  </span>
                </CakeAwardsCard>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};
