import { useState, useEffect } from 'react';

export const usePromocodeDelayedResetting = (promocode, transitionDurationMs) => {
  const [localPromocode, setPromocode] = useState(promocode);

  useEffect(() => {
    if (!promocode) {
      setTimeout(() => {
        setPromocode(null);
      }, transitionDurationMs);
      return;
    }

    setPromocode(promocode);
  }, [promocode, transitionDurationMs]);

  return localPromocode;
};
