import React, { useReducer, useContext, createContext } from 'react';

import { bindActionsToDispatch, createAction } from './utils';

const OPEN_DIALOG = 'MAGNIT_GIFT_DIALOG_INFO/OPEN_DIALOG';
const CLOSE_DIALOG = 'MAGNIT_GIFT_DIALOG_INFO/CLOSE_DIALOG';

const actions = {
  closeMagnitGiftsDialogInfo: () => createAction(CLOSE_DIALOG),
  openMagnitGiftsDialogInfo: () => createAction(OPEN_DIALOG),
};

const initialState = {
  isMagnitGiftsDialogInfoOpen: false,
};

const reducer = (state, { type }) => {
  switch (type) {
    case OPEN_DIALOG: {
      return {
        isMagnitGiftsDialogInfoOpen: true,
      };
    }

    case CLOSE_DIALOG: {
      return {
        isMagnitGiftsDialogInfoOpen: false,
      };
    }

    default:
      return state;
  }
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isMagnitGiftsDialogInfoOpen } = state;

  const { closeMagnitGiftsDialogInfo, openMagnitGiftsDialogInfo } = bindActionsToDispatch(actions, dispatch);

  return [{ isMagnitGiftsDialogInfoOpen }, { closeMagnitGiftsDialogInfo, openMagnitGiftsDialogInfo }];
};

const MagnitGiftsDialogInfoContext = createContext();

export const useMagnitGiftsDialogInfo = () => {
  return useContext(MagnitGiftsDialogInfoContext);
};

export const MagnitGiftsDialogInfoContextStore = ({ children }) => (
  <MagnitGiftsDialogInfoContext.Provider value={useHook()}>{children}</MagnitGiftsDialogInfoContext.Provider>
);

MagnitGiftsDialogInfoContext.displayName = 'MagnitGiftsDialogInfoContext';
