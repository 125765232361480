import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { MediaVideo } from './components/MediaVideo';
import { MediaItemType, TMedia } from './types';

export const Media: React.FunctionComponent<
  TMedia & {
    coverImage?: string;
  }
> = ({ media, isAdaptable, coverImage, isFullClickable, ratio, imageRef, onLoad }) => {
  const hasRatio = useMemo(() => Boolean(ratio), [ratio]);
  if (!media) {
    return null;
  }

  if (media.type === MediaItemType.VIDEO) {
    return (
      <div className={cn(styles.media, { [styles.adaptable]: isAdaptable })}>
        <MediaVideo coverImage={coverImage} media={media} isFullClickable={isFullClickable} />
      </div>
    );
  }

  const ratioStyle = ratio ? { paddingTop: `${ratio * 100}%` } : null;
  const holderStyle = {
    ...(hasRatio && ratioStyle),
  };
  const imageProperties = {
    ...(Boolean(imageRef) && { ref: imageRef }),
  };

  const [src, src2x] = media.sources;

  return (
    <div
      className={cn(styles.media, {
        [styles.adaptable]: isAdaptable,
      })}
      style={holderStyle}>
      <div
        className={cn({
          [styles.imageHolder]: true,
          [styles.withRatio]: ratio,
        })}>
        <img
          className={styles.image}
          src={src}
          srcSet={src2x ? `${src2x} 2x` : undefined}
          alt={media.title}
          ref={imageRef}
          onLoad={onLoad}
          {...imageProperties}
        />
      </div>
    </div>
  );
};
