import { loadingStatesData } from '../data';

export const bindActionsToDispatch = (actionsObject, dispatch) => {
  const bindedActions = {};
  for (const key of Object.keys(actionsObject)) {
    const action = actionsObject[key];
    bindedActions[key] = (...args) => dispatch(action(...args));
  }

  return bindedActions;
};

export const createAction = (type, payload) => ({ type, payload });

export const checkIsLoading = (loadingState) => loadingState === loadingStatesData.loading;

export const checkIsReady = (loadingState) => loadingState === loadingStatesData.ready;

export const checkIsError = (loadingState) => loadingState === loadingStatesData.error;

export const findSubString = (str1, str2) => {
  return str1.toUpperCase().includes(str2.toUpperCase());
};
