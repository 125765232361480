import React from 'react';
import { Media } from 'tsComponents/Media';
import { CustomScrollbarWrapper } from '../custom-scrollbar/custom-scrollbar';
import './magnit-gifts-dialog-.css.css';

export const MagnitGiftsDialogProducts = ({ products }) => {
  return (
    <div className="MagnitGiftsDialog-productGrid">
      <div className="MagnitGiftsDialog-productGridPositioner">
        <CustomScrollbarWrapper verticalTrackRight="0">
          <h2 className="MagnitGiftsDialog-productGridTitle">Товары в наличии</h2>

          <div className="MagnitGiftsDialog-productGridList">
            {products.map((item) => (
              <div className="MagnitGiftsDialog-productGridItem">
                <div className="MagnitGiftsDialog-productGridItemMedia">
                  <Media media={item.image} />
                </div>
                <div className="MagnitGiftsDialog-productGridItemInfo">
                  <div className="MagnitGiftsDialog-productGridItemInfoTitle">{item.name}</div>

                  {/* <div className="MagnitGiftsDialog-productGridItemInfoDescription">
                                    Вы можете прийти в магазин по адресу <b>{currentShop}</b> и
                                    купить данный товар.
                                </div> */}

                  <div className="MagnitGiftsDialog-productGridItemInfoDescription">
                    Вы можете прийти в выбранный магазин и купить этот товар.
                  </div>

                  <div className="MagnitGiftsDialog-productGridItemInfoNote">
                    Цена в магазине может отличаться от цены, указанной <nobr>на сайте.</nobr>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CustomScrollbarWrapper>
      </div>
    </div>
  );
};
