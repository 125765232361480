import React from 'react';
import cn from 'classnames';
import { IconContainer } from 'tsComponents/@ui-kit/IconContainer';
import styles from './styles.module.scss';
import { useSmoothItemOpening } from '../../../../../tsHooks/useSmoothItemOpening';
import { IconAngleArrowBottom, IconAngleArrowTop, IconMinus, IconPlus } from '../../../../../components/icons';
import { IconContainerSizes } from '../../../IconContainer/IconContainer';

enum IconType {
  DEFAULT = 'DEFAULT',
  ARROW = 'ARROW',
}

const ICONS = {
  DEFAULT: {
    open: <IconMinus />,
    close: <IconPlus />,
  },

  ARROW: {
    open: <IconAngleArrowTop />,
    close: <IconAngleArrowBottom />,
  },
};

export type TItem = {
  title: string | React.ReactElement;
  isOpened?: boolean;
  noSideGaps?: boolean;
  isDefaultOpened?: boolean;
  onClick: () => void;
  iconSize?: IconContainerSizes;
  iconType?: keyof typeof ICONS;
  isFlexEndAligned?: boolean;
  variant?: string;
  onImageLoad?: () => void;
  className?: string;
  titleClassname?: string;
  iconClassname?: string;
};

export const Item: React.FunctionComponent<TItem> = ({
  children,
  title,
  isOpened,
  noSideGaps,
  onClick,
  iconSize,
  isFlexEndAligned,
  iconType = IconType.DEFAULT,
  iconClassname,
  titleClassname = '',
  className,
}) => {
  const { itemEl, contentEl, titleEl } = useSmoothItemOpening(!!isOpened);

  const icon = isOpened ? ICONS[iconType as keyof typeof ICONS].open : ICONS[iconType as keyof typeof ICONS].close;

  return (
    <div
      className={cn(styles.item, className, {
        [styles.noSideGaps]: noSideGaps,
      })}
      ref={itemEl}>
      <div ref={titleEl}>
        <button
          className={cn(styles.itemHeader, {
            [styles.flexEndAligned]: isFlexEndAligned,
          })}
          onClick={onClick}>
          <span
            className={cn(styles.title, {
              [titleClassname]: titleClassname,
            })}>
            {title}
          </span>

          <span>
            <IconContainer className={iconClassname} icon={icon} size={iconSize ?? 'xsmall'} />
          </span>
        </button>
      </div>

      <div className={styles.content} ref={contentEl}>
        {children}
      </div>
    </div>
  );
};
