import React, { Children, ReactElement, useMemo } from 'react';
import { useAccordion } from 'tsHooks/useAccordion';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Item } from './components/Item';

export type TAccordion = {
  isClosedAllOnOpenOne: boolean;
  noBorders?: boolean;
  className?: string;
};

export const Accordion: React.FunctionComponent<TAccordion> & { Item: typeof Item } = ({
  isClosedAllOnOpenOne,
  noBorders,
  className = '',
  children,
}) => {
  const defaultOpenedIndexList = useMemo(
    () =>
      Children.map(children, (child) => {
        const childN = child as ReactElement;
        return !!childN?.props.isDefaultOpened;
      }),
    [],
  );

  const { openedIndexList, toggle } = useAccordion(defaultOpenedIndexList ?? [], isClosedAllOnOpenOne);

  const handleToggle = (index: number) => () => toggle(index);

  return (
    <div
      className={cn({
        [styles.noBorders]: noBorders,
        [className]: className,
      })}>
      {Children.map(
        children,
        (item, index) =>
          React.isValidElement(item) &&
          React.cloneElement(item, {
            isOpened: openedIndexList[index],
            onClick: handleToggle(index),
            className: styles.item,
          }),
      )}
    </div>
  );
};

Accordion.Item = Item;
