import React, { useState, useContext, createContext, useEffect, useMemo } from 'react';
import md5 from 'md5';
import { useLocation } from 'react-router-dom';
import { emailRegExp } from 'utils/email-reg-exp';
import {
  getBrowser,
  getPersistedValidationOrderNumber,
  getPersistedCakeCount,
  getPersistedWonProducts,
  getPersistedCakeTimer,
  getCurrentFullTime,
} from '../utils';
import { useDebounce } from '../hooks';
import { deliveryMethodsTitle } from '../data/delivery-methods';

const replace = (slug) => slug.replace('sammy_beauty_', '');

const createDataLayerMethods = () => {
  const dataLayer = window.dataLayer || [];
  const BRAND = 'Sammybeauty';

  const dataLayerMethods = {
    fieldStartDL(fieldName) {
      try {
        dataLayer.push({
          event: 'field-start',
          'event-content': fieldName,
        });
      } catch (error) {
        console.log('fieldStartDL -> e', error);
      }
    },
    fieldCompleteDL(fieldName, value) {
      try {
        dataLayer.push({
          event: 'field-complete',
          'event-content': fieldName,
          ...(Boolean(value) && { 'event-content-2': value }),
        });
      } catch (error) {
        console.log('fieldCompleteDL -> e', error);
      }
    },
    locationChangeDL(location) {
      // completed
      try {
        dataLayer.push({
          event: 'pageview',
          // type: 'page',
          page_location: location,
          url: location,
        });
      } catch (error) {
        console.log('locationChangeDL -> e', error);
      }
    },
    impressionsDL(products) {
      try {
        dataLayer.push({
          event: 'impressions',
          ecommerce: {
            currencyCode: 'RUB', // валюта стоимости товара
            impressions: products.map((product) => ({
              name: replace(product.slug),
              id: product.id,
              price: product.price.current,
              brand: BRAND,
              category: product.category.name,
              list: product.from,
              position: product.index + 1,
            })),
            // [
            //  {
            //    'name': '',       // название продукта
            //    'id': '5460', // ID продукта из БД
            //    'price': '45500.00', // цена реализации продукта из БД
            //    'brand': BRAND, // константа
            //    'category': 'Взрослые', // пока одна категория, далее, по мере появления должна передавать вся влож
            //    'list': 'Главная страница', // если это показы товара из блока на главной странице, либо “Категория, если это страница категории”
            //    'position': position + 1 // позиция программы в блоке или на странице
            //  },
            // ]
          },
        });
      } catch (error) {
        console.log('impressionsDL -> e', error);
      }
    },
    productViewDL(product) {
      // completed
      try {
        dataLayer.push({
          event: 'detail',
          ecommerce: {
            detail: {
              // 'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
              products: [
                {
                  name: replace(product.slug), // Name or ID is required.
                  id: product.id,
                  price: product.price.current,
                  brand: BRAND,
                  category: product.category.name,
                },
              ],
            },
          },
        });
      } catch (error) {
        console.log('productViewDL -> e', error);
      }
    },
    productClickDL(product, position = 0, from, fromPath) {
      // completed
      try {
        dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              // 'actionField': {'list': 'Search Results'},      // Optional list property.
              products: [
                {
                  name: replace(product.slug), // Name or ID is required.
                  id: product.id,
                  price: product.price.current,
                  brand: BRAND,
                  category: product.category.name,
                  position: position + 1,
                },
              ],
              from,
              fromPath,
            },
          },
        });
      } catch (error) {
        console.log('productClickDL -> e', error);
      }
    },

    addToBasketDL(item, amount) {
      // completed
      try {
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'RUB',
            add: {
              // 'add' actionFieldObject measures.
              products: [
                {
                  //  adding a product to a shopping cart.
                  name: replace(item.slug),
                  id: item.id,
                  price: item.price.current,
                  brand: BRAND,
                  category: item.category.name,
                  quantity: amount,
                },
              ],
            },
          },
        });
      } catch (error) {
        console.log('addToBasketDL -> e', error);
      }
    },

    checkName(value) {
      const normalizedValue = value.trim();

      const specialCharactersRegExp = new RegExp(`^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"\`\\/<>?:;|=.,{}()[\\]]+$`);

      return normalizedValue.length > 1 && specialCharactersRegExp.test(normalizedValue);
    },

    checkPhone(value) {
      const normalizedValue = value.trim().replace(/\D+/g, '');

      return normalizedValue.length > 10;
    },

    checkEmail(value) {
      const normalizedValue = value.trim();

      return emailRegExp.test(normalizedValue);
    },
    buttonOrderCreateDL(title) {
      try {
        dataLayer.push({
          event: 'button-order-create',
          'event-content': title,
        });
      } catch (error) {
        console.log('fieldCompleteDL -> e', error);
      }
    },

    checkoutDL(step) {
      return (basketData) => {
        const { productList } = basketData;
        try {
          dataLayer.push({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step },
                products: productList.map((item) => {
                  const { product, quantity } = item;
                  return {
                    name: replace(product.slug),
                    id: product.id,
                    price: product.price.current,
                    brand: BRAND,
                    category: product.category.name,
                    quantity,
                  };
                }),
              },
            },
          });
        } catch (error) {
          console.log('checkoutDL -> e', error);
        }

        if (step === 2) {
          try {
            dataLayer.push({
              event: 'checkout-custom-1st-block-complete',
            });
          } catch (error) {
            console.log('checkoutDL -> e', error);
          }
        }
      };
    },

    purchaseDL(order) {
      const purchaseId = `${order.id}${order.index_number ? order.index_number : ''}`;

      try {
        const payload = {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: purchaseId,
                affiliation: BRAND,
                revenue: order.price.basket_price,
                shipping: order.price.delivery_price,
                coupon: order.basket?.promos[0]?.code,
              },
              email: md5(order?.contacts?.email || ''),
              phone: md5(order?.contacts?.phone ? order.contacts.phone.replace(/\D/g, '') : ''),
              deliverytype: order?.payment?.delivery_method?.description || '',
              paymenttype: order?.payment?.payment_method?.title || '',
              products: order.basket.goods_list.map((element) => {
                return {
                  name: replace(element.product.slug),
                  id: element.product.id,
                  price: element.product.price.default,
                  brand: BRAND,
                  category: element.product.category.name,
                  quantity: element.quantity,
                };
              }),
            },
          },
        };

        dataLayer.push(payload);
      } catch (error) {
        console.log('purchaseDL -> e', error);
      }
    },
    checkout4DL(order) {
      try {
        const payload = {
          event: 'checkout',
          ecommerce: {
            'step-4': {
              actionField: {
                id: order.id,
                affiliation: BRAND,
                revenue: order.price.basket_price,
                shipping: order.price.delivery_price,
                coupon: order.basket?.promos[0]?.code,
              },
              products: order.basket.goods_list.map((element) => {
                return {
                  name: replace(element.product.slug),
                  id: element.product.id,
                  price: element.product.price.default,
                  brand: BRAND,
                  category: element.product.category.name,
                  quantity: element.quantity,
                };
              }),
            },
          },
        };
        dataLayer.push(payload);
      } catch (error) {
        console.log('checkout4DL -> e', error);
      }
    },
    paymentSuccessDL(order) {
      try {
        dataLayer.push({
          event: 'payment-success',
          'order-id': order?.id, // идентификатор заказа, по которому полученна оплата
          'order-sum': order?.price?.total_price, // сумма онлайн платежа
        });
      } catch (error) {
        console.log('paymentSuccessDL -> e', error);
      }
    },

    /* CAKE CHEST GA METHODS */

    // успешная оплата заказа
    bombPaymentSuccess() {
      dataLayer.push({
        event: 'bomb-payment-success',
        content: localStorage.getItem('__persisted_order_id'),
      });
    },

    // переход на страницу редиректа
    showRedirectPage() {
      dataLayer.push({
        event: 'bomb-redirect',
        content: localStorage.getItem('__persisted_order_id'),
      });
    },

    // открытие страницы с акцией
    showCakeChestPage() {
      dataLayer.push({
        event: 'bomb-pageview',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        closed: getPersistedCakeCount(),
        opened: getPersistedWonProducts().length,
        'user-agent': getBrowser(),
      });
    },

    // showCakeAwardsPage(){
    //   dataLayer.push({
    //     'event': 'bomb-timer-start',
    //     'content': getPersistedValidationOrderNumber()
    //   })
    // },

    // переход на страницу "спасибо за заказ"
    showThanksOrderingPage() {
      dataLayer.push({
        event: 'bomb-pageview-thanks',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
      });
    },

    // открытия страницы "приветственный попап"
    showValidationUserPage() {
      dataLayer.push({
        event: 'bomb-popup-view',
      });
    },

    // открытие страницы подтверждения смс
    showSMSConfirmationPage() {
      dataLayer.push({
        event: 'bomb-order-correct',
        content: getPersistedValidationOrderNumber(),
      });
    },

    // выбор подарка
    chooseChest(id) {
      dataLayer.push({
        event: 'bomb-select',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        'bomb-selector': id,
      });
    },

    // таймер закончился и редиректит на "спасибо за заказ"
    timerRedirect() {
      dataLayer.push({
        event: 'bomb-timer-finish',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
      });
    },

    // старт таймера
    startTimer() {
      dataLayer.push({
        event: 'bomb-timer-start',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        time: getPersistedCakeTimer(),
      });
    },

    // запрос на бэк отправлен
    cakeChestRequest() {
      dataLayer.push({
        event: 'bomb-back-send',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        time: getCurrentFullTime(),
      });
    },

    // запрос вернулся с бэка
    cakeChestResponse(time, error) {
      dataLayer.push({
        event: 'bomb-back-back',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        time,
        'request-failed': Boolean(error),
      });
    },

    // ошибки которые могли возникнуть в js
    jsError(error, errorInfo) {
      dataLayer.push({
        event: 'bomb-js-errors',
        content: getPersistedValidationOrderNumber() || localStorage.getItem('__persisted_order_id'),
        error,
        'error-info': errorInfo,
      });
    },

    // ввод номера заказа и отправка для проверки на бэке
    checkOrdering() {
      dataLayer.push({
        event: 'bomb-order-send',
        content: getPersistedValidationOrderNumber(),
        time: getCurrentFullTime(),
      });
    },

    // запрос на подтверждение кода из смс
    checkSMS() {
      dataLayer.push({
        event: 'bomb-code-success',
        content: getCurrentFullTime(),
      });
    },
    /* END CAKE CHEST METHODS */
  };
  return dataLayerMethods;
};

const dataLayerMethods = createDataLayerMethods();

const useLocationChange = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      dataLayerMethods.locationChangeDL(location.pathname);
    }, 500);
  }, [location.pathname]);
};

function useProductApears(callback) {
  const [appearedElements, setApearedElements] = useState([]);

  const onProductApears = (product, index, from) => {
    const alreadyExist = appearedElements.some((localProduct) => localProduct.slug === product.slug);
    const productListItem = { ...product, index, from };
    if (!alreadyExist) {
      setApearedElements([...appearedElements, productListItem]);
    }
  };

  const debouncedAppearedElements = useDebounce(appearedElements, 500);
  const appearedElementsAmount = useMemo(() => debouncedAppearedElements.length, [debouncedAppearedElements]);

  useEffect(() => {
    if (appearedElementsAmount) {
      // dataLayerMethods.impressionsDL(debouncedAppearedElements)
      callback(debouncedAppearedElements);
      setApearedElements([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appearedElementsAmount]);

  return onProductApears;
}

const useHook = () => {
  useLocationChange();

  const onProductApears = useProductApears(dataLayerMethods.impressionsDL);

  const ecommerceFocusField = (name) => {
    const { fieldStartDL } = dataLayerMethods;

    return () => {
      fieldStartDL(name === 'cheburashka' ? 'phone' : name);
    };
  };

  const ecommerceBlurField = (name, value) => {
    const { checkName, checkPhone, checkEmail, fieldCompleteDL } = dataLayerMethods;

    switch (name) {
      case 'firstName':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'lastName':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'phone':
        if (checkPhone(value)) {
          fieldCompleteDL(name, value.trim().replace(/\D+/g, ''));
        }
        break;

      case 'email':
        if (checkEmail(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'city':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'delivery-method':
        fieldCompleteDL(name, deliveryMethodsTitle[value]);
        break;

      case 'choose-pick-point':
        fieldCompleteDL(name, value);
        break;

      case 'recipientPhone':
        if (checkPhone(value)) {
          fieldCompleteDL(name, value.trim().replace(/\D+/g, ''));
        }
        break;

      case 'recipientLastName':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'recipientFirstName':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'cheburashka':
        if (checkPhone(value)) {
          fieldCompleteDL('phone', value.trim().replace(/\D+/g, ''));
        }
        break;

      case 'ordererApartment':
        if (value.length > 1) {
          fieldCompleteDL('ordererApartment', value);
        }
        break;

      case 'street':
        if (checkName(value)) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'house':
        if (value.length > 1) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'ordererPostId':
        if (value.length > 1) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'ordererRegion':
        if (value.length > 1) {
          fieldCompleteDL(name, value);
        }
        break;

      case 'payment-method':
        fieldCompleteDL(name, value);
        break;

      default:
        break;
    }
  };

  return [{ fdfdfd: 'dsdsd' }, { ...dataLayerMethods, onProductApears, ecommerceFocusField, ecommerceBlurField }];
};

const DataLayerContext = createContext();
const useDataLayer = () => useContext(DataLayerContext);

const DataLayerStore = ({ children }) => (
  <DataLayerContext.Provider value={useHook()}>{children}</DataLayerContext.Provider>
);

DataLayerContext.displayName = 'DataLayerContext';

export { DataLayerStore, useDataLayer };
