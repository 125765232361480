import { useLayoutEffect } from 'react';
import { useScrollLock } from '../stores/scroll-lock';

export const useModalScrollLock = (isOpened) => {
  const [, { setLock, resetLock }] = useScrollLock();

  useLayoutEffect(() => {
    if (isOpened) {
      setLock();
      return;
    }

    resetLock();
  }, [isOpened]);
};
