import React, { useReducer, useContext, createContext, useEffect } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { bindActionsToDispatch, createAction, checkIsLoading, checkIsReady, checkIsError } from '../utils';
import { sammyBeautyApi } from '../../service';
import { loadingStatesData } from '../../data';
import { useModals } from '../modals';
import pickPointIconSrc from '../../assets/images/pickpoint.svg';
import imlIconSrc from '../../assets/images/iml.svg';
import dpdIconSrc from '../../assets/images/dpd.svg';
import cdekIconSrc from '../../assets/images/cdek.svg';
import sberlogIconSrc from '../../assets/images/sberlog.svg';
import pickPointSelected from '../../assets/images/pickPointSelected.svg';
import cdekSelected from '../../assets/images/cdekSelected.svg';
import sberlogSelected from '../../assets/images/sberlogSelected.svg';
import fivepost from '../../assets/images/fivepost.svg';
import yandexDelivery from '../../assets/images/yandex_delivery.svg';
import { ScheduleNormalizer } from '../../utils/normalize-pick-point-time';

function getLogoSrc(deliveryCompany) {
  const srcList = {
    IML: imlIconSrc,
    PICKPOINT: pickPointIconSrc,
    DPD: dpdIconSrc,
    CDEK: cdekIconSrc,
    SBERLOG: sberlogIconSrc,
    X5: fivepost,
    YATAXI: yandexDelivery,
  };

  if (deliveryCompany?.logo) {
    return deliveryCompany.logo;
  }

  return srcList[deliveryCompany?.type] || '';
}
function getSelectedSrc(type) {
  return (
    {
      PICKPOINT_SELECTED: pickPointSelected,
      CDEK_SELECTED: cdekSelected,
      SBERLOG_SELECTED: sberlogSelected,
    }[type] || ''
  );
}

const deserializePickPoint = (pickPoint) => {
  const normalizer = new ScheduleNormalizer();
  const { id, title, schedule_raw, geography, type, delivery_company, price } = pickPoint;

  return {
    id: `${id}`,
    title,
    schedule: normalizer.normalize(delivery_company?.type, schedule_raw),
    schedule_html: schedule_raw.split(', ').join(', </br>'),
    scheduleWithNewLines: schedule_raw.split(', ').join(',\n').split('-').join(' - '),
    address: geography.address.raw,
    price,
    // price: {default: 568},
    _raw: pickPoint,
    deliveryCompany: {
      ...delivery_company,
      logo: getLogoSrc(delivery_company),
      logoSelected: getSelectedSrc(`${delivery_company?.type}_SELECTED`),
    },
    deliveryCompanyTitle: delivery_company.type,
    type,
  };
};

const deserializeList = (list) => list.map((element) => deserializePickPoint(element));

const START_LOAD = 'ORDER_PICK_POINTS/START_LOAD';
const END_LOAD = 'ORDER_PICK_POINTS/END_LOAD';

const actions = {
  startLoad: () => createAction(START_LOAD),
  endLoad: ({ error, list = [] }) => createAction(END_LOAD, { error, list }),
};

const initialState = {
  list: [],
  loadingState: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case START_LOAD: {
      return {
        ...state,
        list: [],
        loadingState: loadingStatesData.loading,
      };
    }

    case END_LOAD: {
      const { error, list } = payload;

      return {
        ...state,
        list,
        loadingState: error ? loadingStatesData.error : loadingStatesData.ready,
      };
    }

    default:
      return state;
  }
};

const useHook = () => {
  const [, { close }] = useModals();
  const [{ list, loadingState }, dispatch] = useReducer(reducer, initialState);

  const { startLoad, endLoad } = bindActionsToDispatch(actions, dispatch);

  const request = ({ fiasId, cityTitle, type, countryTitle }) => {
    startLoad();
    sammyBeautyApi()
      .orderPickPoints({ fiasId, cityTitle: capitalizeFirstLetter(cityTitle), type, countryTitle })
      .then((pickPoints) => endLoad({ list: deserializeList(pickPoints) }))
      .catch((error) => endLoad({ error }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => close(), []);

  return [
    {
      list,
      isLoading: checkIsLoading(loadingState),
      isReady: checkIsReady(loadingState),
      isError: checkIsError(loadingState),
      isEmpty: list.length === 0,
    },
    { request },
  ];
};

const OrderPickPointsContext = createContext();

export const useOrderPickPoints = () => useContext(OrderPickPointsContext);

export const OrderPickPointsStore = ({ children }) => (
  <OrderPickPointsContext.Provider value={useHook()}>{children}</OrderPickPointsContext.Provider>
);

OrderPickPointsContext.displayName = 'OrderPickPointsContext';
