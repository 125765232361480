import React, { useReducer, useContext, createContext, useCallback } from 'react';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';

const MAIN_BANNER_RESPONSE = 'MAIN_BANNER/RESPONSE';
const MAIN_BANNER_ERROR = 'MAIN_BANNER/ERROR';

const mediaBannerNormalize = (media) => {
  return {
    ...media,
    sources: media.sources.map((source) => source.main.double),
    _sources: [...media.sources],
  };
};

const normalizeMainBanner = (order) => {
  const title = order?.title ? order.title : null;
  const mobTitle = order?.mobile_title ? order.mobile_title : null;
  const description = order?.description ? order.description : null;
  const mobileDescription = order?.mobile_description ? order.mobile_description : null;
  const image = order?.image ? mediaBannerNormalize(order.image) : null;
  const imageMobile = order?.image_mobile ? mediaBannerNormalize(order.image_mobile) : null;
  const link = order?.url ? order.url : null;
  const aspectRatios =
    order?.image?.sources[0]?.dimensions?.width && order?.image?.sources[0]?.dimensions?.height
      ? `${order.image.sources[0].dimensions.width}:${order.image.sources[0].dimensions.height}`
      : null;
  const mobileAspectRatios =
    order?.image_mobile?.sources[0]?.dimensions?.width && order?.image_mobile?.sources[0]?.dimensions?.height
      ? `${order.image_mobile.sources[0].dimensions.width}:${order.image_mobile.sources[0].dimensions.height}`
      : null;

  return {
    title,
    mobTitle,
    description,
    mobileDescription,
    image,
    imageMobile,
    link,
    aspectRatios: { desktop: aspectRatios, mobile: mobileAspectRatios },
  };
};

const initialState = {
  mainBannerList: null,
  isLoaded: false,
};

const actions = {
  mainBannerResponse: (mainBannerResponse) => {
    return createAction(MAIN_BANNER_RESPONSE, { mainBannerResponse });
  },

  mainBannerError: () => createAction(MAIN_BANNER_ERROR),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case MAIN_BANNER_RESPONSE: {
      const { mainBannerResponse: mainBannerList } = payload;

      const localMainBannerList = mainBannerList && [...mainBannerList];

      return {
        mainBanners: localMainBannerList && localMainBannerList.map((element) => normalizeMainBanner(element)),
        isLoaded: true,
      };
    }

    case MAIN_BANNER_ERROR: {
      return {
        isLoaded: false,
      };
    }

    default:
      return state;
  }
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { mainBanners, isLoaded } = state;

  const { mainBannerResponse, mainBannerError } = bindActionsToDispatch(actions, dispatch);

  const requestMainBanner = useCallback(() => {
    sammyBeautyApi()
      .mainBanner()
      .then((data) => {
        return mainBannerResponse(data);
      })
      .catch((error) => {
        console.warn('requestCategory -> e', error);
        mainBannerError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [{ mainBanners, isLoaded }, { requestMainBanner }];
};

const MainBannerContext = createContext();

export const useMainBanner = () => {
  return useContext(MainBannerContext);
};

export const MainBannerStore = ({ children }) => (
  <MainBannerContext.Provider value={useHook()}>{children}</MainBannerContext.Provider>
);

MainBannerContext.displayName = 'InstagramReviewsContext';
