import React from 'react';
import { Dialog } from '../dialog/dialog';
import { MagnitGiftsDialogShops as MagnitShops } from './magnit-gifts-dialog-shops';
import { IconContainer } from '../icon-container/icon-container';
import { IconArrowLeft } from '../icons';
import { useMagnitGifts } from '../../stores';
import './magnit-gifts-dialog-.css.css';
import { MagnitGiftsDialogProducts } from './magnit-gifts-dialog-products';

export const MagnitGiftsDialog = ({ onClose, ...rest }) => {
  const [
    { currentCity, magnitCurrentDialogState, currentShop, products },
    { magnitSetCurrentDialogState },
  ] = useMagnitGifts();

  const renderTitle = () => {
    if (magnitCurrentDialogState === 'SHOPS') {
      return (
        <div className="MagnitGiftsDialog-title">
          Пункты самовывоза в <b>{currentCity}</b>
        </div>
      );
    }

    return (
      <div className="MagnitGiftsDialog-backAction">
        <button
          className="MagnitGiftsDialog-backActionButton"
          onClick={() => {
            magnitSetCurrentDialogState('SHOPS');
          }}>
          <div className="MagnitGiftsDialog-backActionButton-iconWrapper">
            <IconContainer>
              <IconArrowLeft />
            </IconContainer>
          </div>

          <div className="MagnitGiftsDialog-backActionTitle">{currentShop}</div>
        </button>
      </div>
    );
  };

  const handleClose = () => {
    onClose();
    magnitSetCurrentDialogState('SHOPS');
  };

  return (
    <Dialog
      noTopIndens={magnitCurrentDialogState !== 'SHOPS'}
      title={renderTitle()}
      onClose={handleClose}
      noSideInnerIndents
      {...rest}>
      {magnitCurrentDialogState === 'SHOPS' ? (
        <MagnitShops {...rest} />
      ) : (
        <MagnitGiftsDialogProducts products={products[currentShop]} currentShop={currentShop} />
      )}
    </Dialog>
  );
};
