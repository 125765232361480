/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

let isCooldown = false;
const debounce = (f, ms) => {
  return function () {
    if (isCooldown) return;

    Reflect.apply(f, this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
};

export default debounce;
