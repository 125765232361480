import React from 'react';
import { IconContainer } from '../icon-container/icon-container';
import { IconCross } from '../icons';
import { Button } from '../button/button';
import './pick-point-selected.css';

const IconSuccess = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0001 1L4.00004 8.00002L1 5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PickPointInfo = ({ pickPoint, onClose, onChoose, isSelected }) => {
  const { deliveryCompanyTitle, title, id, schedule, address } = pickPoint;

  return (
    <div className="PickPointDialog-selectedPickPoint">
      <div className="PickPointDialog-selectedPickPoint-closeButton">
        <button className="Dialog-closeButton" onClick={onClose}>
          <IconContainer>
            <IconCross />
          </IconContainer>
        </button>
      </div>

      <div className="PickPointDialog-wrapper">
        <div className="PickPointRow-company">
          <div className="PickPointRow-companyTitle">
            <div>
              {deliveryCompanyTitle} <br />
              {title}
            </div>
          </div>

          <div className="PickPointRow-selectedPickPoint-address">{address}</div>
        </div>

        <div className="PickPointRow-selectedPickPoint-time">{schedule}</div>

        <Button
          title={
            !isSelected ? (
              'забрать тут'
            ) : (
              <span>
                Выбрано <IconSuccess />
              </span>
            )
          }
          type="submit"
          isPrimary
          onClick={() => onChoose(id)}
          variant={isSelected && 'dark'}
        />
      </div>
    </div>
  );
};
