import React from 'react';
import { Svg } from '../svg/svg';
import './question.css';

export const IconQuestion = () => (
  <Svg viewBox="0 0 11 11" className="IconQuestion">
    <path
      d="M5.47294 10C5.08742 10 4.77489 9.6858 4.77489 9.30028C4.77489 8.91475 5.08742 8.60055 5.47294 8.60055C5.85847 8.60055 6.171 8.91475 6.171 9.30028C6.171 9.6858 5.85847 10 5.47294 10ZM5.47835 7.8292C5.15559 7.8292 4.88797 7.56665 4.92187 7.24567C4.94504 7.02627 4.98262 6.83511 5.03463 6.67218C5.13564 6.37098 5.33766 6.07713 5.64069 5.79063C5.66955 5.76125 5.73809 5.69881 5.84632 5.60331C5.96176 5.50046 6.03752 5.43067 6.07359 5.39394C6.10967 5.35721 6.171 5.29477 6.25758 5.20661C6.34416 5.11111 6.40188 5.03398 6.43074 4.97521C6.46681 4.91644 6.50649 4.84298 6.54978 4.75482C6.60029 4.65932 6.63276 4.56749 6.64719 4.47934C6.66883 4.38384 6.67965 4.28466 6.67965 4.18182C6.67965 3.85859 6.57504 3.59412 6.3658 3.38843C6.15657 3.18274 5.886 3.07989 5.55411 3.07989C4.88938 3.07989 4.47742 3.40331 4.31824 4.05015C4.23843 4.37446 3.97864 4.64463 3.64465 4.64463C3.29333 4.64463 3.00598 4.34937 3.07599 4.00508C3.18165 3.48542 3.39081 3.05581 3.70346 2.71625C4.13636 2.23875 4.76046 2 5.57576 2C6.30447 2 6.88889 2.20202 7.329 2.60606C7.77633 3.00275 8 3.52801 8 4.18182C8 4.42424 7.97475 4.64095 7.92424 4.83196C7.87374 5.02296 7.78355 5.20294 7.65368 5.3719C7.52381 5.54086 7.40837 5.67677 7.30736 5.77961C7.20635 5.88246 7.04401 6.02938 6.82035 6.22039C6.4596 6.53627 6.24315 6.76768 6.171 6.9146C6.1351 6.98405 6.10813 7.09442 6.09009 7.24571C6.05188 7.56621 5.80112 7.8292 5.47835 7.8292Z"
      fill="#636363"
    />
  </Svg>
);
