import React, { useContext, createContext } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { SUBMIT_EVENT_TYPE } from '../constants';
import { checkIsSubmitButtonDisabled as checkIsDisabled } from './helpers';

const validationSchema = object({
  paymentMethod: string().required('Выберите способ оплаты'),
});

const initialValues = { paymentMethod: '', hasComment: '', comment: '' };

const formData = ({ paymentMethod, comment }) => {
  return {
    payment: paymentMethod,
    comment,
  };
};

const onSubmit = () => window.dispatchEvent(new Event(SUBMIT_EVENT_TYPE));

const useHook = () => {
  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  return [
    {
      values,
      data: formData(values),
      errors,
      touched,
      isButtonDisabled: checkIsDisabled(errors, touched),
    },
    {
      handleChange,
      handleSubmit,
      resetData: () => resetForm({ values: initialValues, errors: {}, touched: {} }),
    },
  ];
};

const OrderPaymentDataContext = createContext();

export const useOrderPaymentData = () => useContext(OrderPaymentDataContext);

export const OrderPaymentDataStore = ({ children }) => (
  <OrderPaymentDataContext.Provider value={useHook()}>{children}</OrderPaymentDataContext.Provider>
);

OrderPaymentDataContext.displayName = 'OrderPaymentDataContext';
