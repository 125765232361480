/**
 * @function replaceInvalidPhoneNumber - делает перестановку +8 и 8 в начале на +7
 * @param {string} phone - номер телефона
 * @returns очищенный для торговых компаний номер телефона
 */
export const replaceInvalidPhoneNumber = (phone) => {
  if (phone?.replace) return phone?.replace(/^\+8/, '+7').replace(/^8/, '+7');

  const firstSymbol = phone[0];
  const firstTwoSlice = phone.slice(0, 2);
  return firstTwoSlice === '+8' ? `+7${phone.slice(2)}` : firstSymbol === '8' ? `+7${phone.slice(1)}` : phone;
};
