import React from 'react';
import cn from 'classnames';
import Loader from 'react-loaders';

export const PickPointDialogLoader = React.memo(({ isMapLoading }) => (
  <div
    className={cn('PickPointDialog-loader', {
      'PickPointDialog-loader--disabled': !isMapLoading,
    })}>
    <Loader type="line-spin-fade-loader" active color="#916EB8" />
  </div>
));
