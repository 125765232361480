import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';

const TRACK_ORDER_REQUEST = 'TRACK_ORDER/REQUEST';
const TRACK_ORDER_RESPONSE = 'TRACK_ORDER/RESPONSE';
const TRACK_ORDER_RESPONSE_FAILED = 'TRACK_ORDER/RESPONSE_FAILED';
const TRACK_ORDER_OPEN_DIALOG = 'TRACK_ORDER/OPEN_DIALOG';
const TRACK_ORDER_CLOSE_DIALOG = 'TRACK_ORDER/CLOSE_DIALOG';

const normalizeOrder = (order) => {
  const orderID = order?.id ? order.id : null;
  const orderNumber = order?.number ? order.number : null;
  const orderDate = order?.placing_date ? order.placing_date : null;
  const status = order?.status ? order.status : null;
  const statusTitle = order?.status_text ? order.status_text : null;
  const statusDescription = order?.status_description ? order.status_description : null;
  const goodAmount = order?.basket?.total_count ? order.basket.total_count : null;
  const deliveryTitle = order?.delivery?.delivery_method?.title ? order.delivery.delivery_method.title : null;
  const pickPoint = order?.pick_point?.title ? order.pick_point.title : null;

  const country = order?.delivery?.geography?.country?.title;
  const city = `г.${order?.delivery?.geography?.city?.title}`;

  const pickpointAdress = order?.pick_point?.geography?.address?.raw || '';
  const fullPickpointAdress = `${country} ${city} ${pickpointAdress}`.replace(/\s\s+/g, ' ').trim();
  const recipientAdress = order?.delivery?.geography?.address?.raw || '';
  const fullRecipientAdress = `${country} ${recipientAdress}`.replace(/\s\s+/g, ' ').trim();

  const adress = pickpointAdress ? fullPickpointAdress : fullRecipientAdress || null;

  const paymentMethod = order?.payment?.payment_method?.title ? order.payment.payment_method.title : null;
  const deliveryCompanyLink = order?.delivery?.delivery_company_link ? order.delivery.delivery_company_link : null;
  const trackNumber = order?.delivery?.tracking_number ? order.delivery.tracking_number : null;

  // const userName = (order?.contacts?.first_name && order?.contacts?.last_name) ?
  //   `${order?.contacts?.first_name} ${order?.contacts?.last_name}` : null;

  const recipientPhone = order?.delivery?.recipient_phone;
  const profilePhone = order?.contacts?.phone;
  const phone = recipientPhone || profilePhone || null;

  const recipientName = order?.delivery?.recipient_name;
  const profileName = `${order?.contacts?.first_name} ${order?.contacts?.last_name}`.trim();
  const userName = recipientName || profileName || null;

  const email = order?.contacts?.email;

  return {
    orderID,
    orderDate,
    status,
    statusTitle,
    statusDescription,
    goodAmount,
    deliveryTitle,
    pickPoint,
    adress,
    paymentMethod,
    deliveryCompanyLink,
    trackNumber,
    phone,
    userName,
    orderNumber,
    email,
    basket: order.basket,
    lottery_status: order.lottery_status,
    lottery_list: order.lottery_list,
    rawOrder: order,
  };
};

const actions = {
  trackOrderRequest: () => createAction(TRACK_ORDER_REQUEST),
  trackOrderResponseFail: () => createAction(TRACK_ORDER_RESPONSE_FAILED),
  trackOrderResponse: (orderInfo) => {
    return createAction(TRACK_ORDER_RESPONSE, { orderInfo });
  },
  trackOrderDialogOpen: () => createAction(TRACK_ORDER_OPEN_DIALOG),
  trackOrderDialogClose: () => createAction(TRACK_ORDER_CLOSE_DIALOG),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case TRACK_ORDER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case TRACK_ORDER_RESPONSE_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case TRACK_ORDER_RESPONSE: {
      const { orderInfo } = payload;
      return {
        ...state,
        order: normalizeOrder(orderInfo),
        isLoading: false,
      };
    }

    case TRACK_ORDER_OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
      };
    }

    case TRACK_ORDER_CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
      };
    }

    default:
      return state;
  }
};

const initialState = {
  order: null,
  isLoading: false,
  isDialogOpen: false,
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { order, isLoading, isDialogOpen } = state;

  const {
    trackOrderRequest,
    trackOrderResponseFail,
    trackOrderResponse,
    trackOrderDialogOpen,
    trackOrderDialogClose,
  } = bindActionsToDispatch(actions, dispatch);

  const requestOrderStatus = useCallback(
    (number, phone) => {
      sammyBeautyApi()
        .orderStatus(number, phone)
        .then((data) => trackOrderResponse(data))
        .catch((error) => {
          console.log('requestCategory -> e', error);
          trackOrderResponseFail();
          trackOrderDialogOpen();
        });
    },
    [trackOrderDialogOpen, trackOrderResponse, trackOrderResponseFail],
  );

  const handleRequestOrderStatus = (number, phone) => {
    trackOrderRequest();
    requestOrderStatus(number, phone);
  };

  return [
    { order, isLoading, isDialogOpen },
    { handleRequestOrderStatus, trackOrderDialogClose },
  ];
};

const TrackOrderFormContext = createContext();

export const useTrackOrderForm = () => {
  return useContext(TrackOrderFormContext);
};

export const TrackOrderFormStore = ({ children }) => (
  <TrackOrderFormContext.Provider value={useHook()}>{children}</TrackOrderFormContext.Provider>
);

TrackOrderFormContext.displayName = 'TrackOrderFormContext';
