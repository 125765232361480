import { useState, useEffect } from 'react';

const getElHeight = (el) => {
  if (!el) {
    return 0;
  }

  return el.getBoundingClientRect().height;
};

export const useErrorSmoothBehaviour = (error, transitionDurationMs) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_error, setError] = useState(error);
  const [errorEl, setErrorEl] = useState(null);

  useEffect(() => {
    if (!error) {
      setTimeout(() => setError(''), transitionDurationMs);
      return;
    }

    setError(error);
  }, [error, transitionDurationMs]);

  return [{ _error, errorElHeight: getElHeight(errorEl) }, setErrorEl];
};
