import React, { useReducer, useContext, createContext, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionsToDispatch, createAction } from './utils';
import {
  getPersistedCakeCount,
  getPersistedWonProducts,
  setPersistedCakeCount,
  setPersistedWonProducts,
  delay,
  getPersistedIsCakeActive,
  setPersistedIsCakeActive,
  getPersistedCakeTimer,
  setPersistedCakeTimer,
  randomSortArray,
  getPersistedIsValidUser,
  setPersistedIsValidUser,
  setPersistedValidationOrderNumber,
  getDiffTime,
  getPersistedValidationOrderNumber,
  getPersistedPickedForChangeProducts,
  setPersistedPickedForChangeProducts,
} from '../utils';

import { sammyBeautyApi } from '../service';
import { useDataLayer } from './data-layer';

/**  @constant isChestActive Для включения/отключения акции true/false соответсвенно */
export const isChestActive = true;
/**  @constant isValidationChestActive Для включения/отключения страницы валидации true/false соответсвенно */
export const isValidationChestActive = true;
/**  @constant retries количество попыток для получения сообщения об успешной оплате */
const chestRetries = 5;
/**  @constant retryTimeout время между попытками в мс */
const retryTimeout = 2000;

const wait = (interval) => new Promise((resolve) => setTimeout(resolve, interval));
export async function retry(promise, retriesLeft = chestRetries, interval = retryTimeout) {
  try {
    return promise();
  } catch (error) {
    await wait(interval);
    if (retriesLeft === 0) {
      throw new Error(error);
    }
    return retry(promise, retriesLeft - 1, interval);
  }
}

const CAKE_CHEST_RESPONSE = 'CAKE_CHEST/DIALOG_OPEN_RESPONSE';
const CAKE_CHEST_PRODUCT_RESPONSE = 'CAKE_CHEST/PRODUCT_RESPONSE';
const CAKE_CHEST_LOADING = 'CAKE_CHEST/START_LOADING';
const CAKE_CHEST_END_LOADING = 'CAKE_CHEST/CAKE_CHEST_END_LOADING';
const CAKE_CHEST_RESET_GIFT_PRODUCTS = 'CAKE_CHEST/RESET_GIFT_PRODUCTS';
const CAKE_CHEST_DECREASE_ATTEMPT = 'CAKE_CHEST/CAKE_CHEST_DECREASE_ATTEMPT';
const CAKE_CHEST_PUSH_WON_PRODUCT = 'CAKE_CHEST/CAKE_CHEST_PUSH_WON_PRODUCT';
const CAKE_CHEST_RESET_STORE = 'CAKE_CHEST/CAKE_CHEST_RESET_STORE';
const CAKE_CHEST_SET_VALIDATION_DATA = 'CAKE_CHEST/CAKE_CHEST_SET_VALIDATION_DATA';
const CAKE_IS_SECTOR_PRIZE = 'CAKE_CHEST/IS_SECTOR_PRIZE';
const CAKE_SECTOR_PRIZE = 'CAKE_CHEST/SECTOR_PRIZE';
const CAKE_CHEST_NEW_WON_PRODUCTS = 'CAKE_CHEST/SET_WON_PRODUCTS';
const CAKE_CHEST_PICKED_FOR_CHANGE_PRODUCTS = 'CAKE_CHEST/PICKED_FOR_CHANGE_PRODUCTS';

const endAnimationDelay = 8000;
const prizeEndAnimationDelay = 5000;
const resetChestsDelay = 8000;
const blinkDelay = 300;
// const arrayOfPages = ['/cake-chest', '/cake-awards', '/validation', '/order-result-preloader'];

const actions = {
  cakeChestResponse: (isCakeActive, cakeCount) =>
    createAction(CAKE_CHEST_RESPONSE, {
      isCakeActive,
      attempt: cakeCount,
    }),
  cakeChestProductResponse: (giftProducts) => createAction(CAKE_CHEST_PRODUCT_RESPONSE, { giftProducts }),
  cakeChestResetGiftProducts: () => createAction(CAKE_CHEST_RESET_GIFT_PRODUCTS),
  startLoading: () => createAction(CAKE_CHEST_LOADING),
  endLoading: () => createAction(CAKE_CHEST_END_LOADING),
  decreaseAttempt: () => createAction(CAKE_CHEST_DECREASE_ATTEMPT),
  updateWonProducts: (product) => createAction(CAKE_CHEST_PUSH_WON_PRODUCT, { product }),
  setValidationUserData: (data) => createAction(CAKE_CHEST_SET_VALIDATION_DATA, { isValidUser: true, data }),
  resetStore: () => createAction(CAKE_CHEST_RESET_STORE),
  setIsSectorPrize: (isSectorPrize) => createAction(CAKE_IS_SECTOR_PRIZE, { isSectorPrize }),
  setSectorPrize: (sectorPrize) => createAction(CAKE_SECTOR_PRIZE, { sectorPrize }),
  setWonProducts: (wonProducts) => createAction(CAKE_CHEST_NEW_WON_PRODUCTS, { wonProducts }),
  setPickedForChangeProducts: (products) => createAction(CAKE_CHEST_PICKED_FOR_CHANGE_PRODUCTS, { products }),
};

const initialAttempt = getPersistedCakeCount();
const initialWonProducts = getPersistedWonProducts();
const initialPickedForChangeProducts = getPersistedPickedForChangeProducts();
const initialIsCakeActive = getPersistedIsCakeActive();
const initialCakeTimer = getPersistedCakeTimer();
const initialIsValidUser = getPersistedIsValidUser();

const initialState = {
  isCakeActive: initialIsCakeActive,
  // товары для розыгрыша
  giftProducts: null,
  isLoading: false,
  // массив подарков
  wonProducts: initialWonProducts,
  pickedForChangeProducts: initialPickedForChangeProducts,
  // количество попыток
  attempt: initialAttempt,
  timer: initialCakeTimer,
  // валидация пользователя на доп. вход, если его выкинуло со страницы акции
  isValidUser: initialIsValidUser,
  isSectorPrize: false,
  sectorPrize: {},
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CAKE_CHEST_RESPONSE: {
      const { isCakeActive, attempt } = payload;

      return {
        ...state,
        isCakeActive,
        attempt,
      };
    }

    case CAKE_CHEST_PRODUCT_RESPONSE: {
      const { giftProducts } = payload;

      return {
        ...state,
        giftProducts,
        isLoading: false,
      };
    }

    case CAKE_CHEST_PICKED_FOR_CHANGE_PRODUCTS: {
      return {
        ...state,
        pickedForChangeProducts: payload.products,
      };
    }

    case CAKE_CHEST_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CAKE_CHEST_END_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case CAKE_CHEST_NEW_WON_PRODUCTS: {
      return {
        ...state,
        wonProducts: payload.wonProducts,
      };
    }

    case CAKE_CHEST_RESET_GIFT_PRODUCTS: {
      return {
        ...state,
        giftProducts: initialState.giftProducts,
      };
    }

    case CAKE_CHEST_DECREASE_ATTEMPT: {
      return {
        ...state,
        attempt: state.attempt - 1,
      };
    }

    case CAKE_CHEST_PUSH_WON_PRODUCT: {
      const { product } = payload;

      return {
        ...state,
        wonProducts: [...state.wonProducts, product],
      };
    }

    case CAKE_CHEST_SET_VALIDATION_DATA: {
      const { data } = payload;

      setPersistedWonProducts(data.order_gifts);
      setPersistedIsCakeActive(data['chest-gift']);
      setPersistedCakeCount(data.cake_count);
      setPersistedIsValidUser(true);

      return {
        ...state,
        isCakeActive: data['chest-gift'],
        attempt: data.cake_count,
        wonProducts: data.order_gifts,
        isValidUser: true,
      };
    }

    case CAKE_CHEST_RESET_STORE: {
      return initialState;
    }

    case CAKE_IS_SECTOR_PRIZE: {
      return {
        ...state,
        ...payload,
      };
    }

    case CAKE_SECTOR_PRIZE: {
      return {
        ...state,
        ...payload,
      };
    }

    default:
      return state;
  }
};

const normaliseData = (data) => {
  if (!data || data.length === 0) {
    return false;
  }

  const prise = data.find((item) => item.isPrise);
  const products = data.filter((item) => !item.isPrise);
  const randomSortedProducts = randomSortArray(products);

  if (!prise || !products) {
    return false;
  }

  return {
    prise,
    products: randomSortedProducts,
  };
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { push } = useHistory();
  const {
    giftProducts,
    wonProducts,
    isLoading,
    isCakeActive,
    attempt,
    timer,
    pickedForChangeProducts,
    isValidUser,
    isSectorPrize,
    sectorPrize,
  } = state;
  const [
    ,
    { chooseChest, cakeChestRequest: GAcakeChestRequest, cakeChestResponse: GAcakeChestResponse },
  ] = useDataLayer();

  const {
    cakeChestResponse,
    cakeChestProductResponse,
    startLoading,
    decreaseAttempt,
    cakeChestResetGiftProducts,
    updateWonProducts,
    resetStore,
    endLoading,
    setValidationUserData,
    setIsSectorPrize,
    setSectorPrize,
    setWonProducts,
    setPickedForChangeProducts,
  } = bindActionsToDispatch(actions, dispatch);

  const pushToWonProducts = (product) => {
    setPersistedWonProducts([...wonProducts, product]);
    updateWonProducts(product);
  };

  /* const animatedProductGrid = (normalisedData, newAttempt, callback) => {
    delay(endAnimationDelay).then(() => {
      startLoading();
      setPersistedCakeCount(newAttempt);
      decreaseAttempt();
      pushToWonProducts(normalisedData.prise);
      cakeChestResetGiftProducts();
      if (callback) {
        callback();
      }

      const card = document.querySelectorAll('.CakeAwardPage-attemptCard')[0];
      if (card) {
        card.classList.add('CakeAwardPage-attemptCardAnimation');
      }

      delay(blinkDelay).then(() => {
        if (card) {
          card.classList.remove('CakeAwardPage-attemptCardAnimation');
        }
      });
    });

    delay(resetChestsDelay).then(() => {
      cakeChestResetGiftProducts();
      endLoading();

      if (normalisedData.prise.isSectorPrize) {
        setSectorPrize(normalisedData.prise);
      }
    });
  }; */

  const cakeChestRequest = useCallback((number) => {
    if (getPersistedIsValidUser()) {
      push(`/cake-chest?order_number=${getPersistedValidationOrderNumber()}`);
      return;
    }

    const localTimer = getDiffTime();
    /**
     * get cake active flag and cake attempts count
     * @param {Number} order id
     * @return {{chest-gift:Boolean, cake_count:Number}}
     */
    GAcakeChestRequest();

    const cakeChestPromise = () =>
      new Promise((resolve, reject) =>
        sammyBeautyApi()
          .cakeChest(number)
          .then((data) => {
            if (!data['chest-gift']) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('Ошибка подтверждения оплаты');
            }

            resolve(data);
          }),
      );

    retry(cakeChestPromise)
      .then((data) => {
        GAcakeChestResponse(localTimer(Date.now()));
        setPersistedIsCakeActive(data['chest-gift']);
        setPersistedCakeCount(data.cake_count);
        cakeChestResponse(data['chest-gift'], data.cake_count);
        push(`/cake-chest?order_number=${number}`);
      })
      .catch((error) => {
        console.error('cakeChestRequest error ->', error);
        GAcakeChestResponse(localTimer(Date.now()), error);
        push(`/order-thanks?order_number=${number}`);
      });
  }, []);

  const cakePrizeChestChoseProductRequest = (productId, orderId, callback) => {
    const localTimer = getDiffTime();

    chooseChest(productId);
    GAcakeChestRequest();
    /**
     * choose prise chest id and get gift products and prise
     * @param {Number} picked chest id
     * @param {Number} order id
     * @return {Array.<{id: Number, isPrise: Boolean, picture: String, title: String}>}
     */
    sammyBeautyApi()
      .cakeChestChosePrizeProduct({ 'picked-chest': productId, number: orderId, 'opened-link': isValidUser })
      .then((data) => {
        GAcakeChestResponse(localTimer(Date.now()));
        const normalisedData = normaliseData(data);
        const newAttempt = attempt - 1;

        if (newAttempt > 0) {
          cakeChestProductResponse(normalisedData);
          delay(prizeEndAnimationDelay).then(() => {
            startLoading();
            setPersistedCakeCount(newAttempt);
            decreaseAttempt();
            pushToWonProducts(normalisedData.prise);
            cakeChestResetGiftProducts();
            callback();

            const card = document.querySelectorAll('.CakeAwardPage-attemptCard')[0];
            if (card) {
              card.classList.add('CakeAwardPage-attemptCardAnimation');
            }

            delay(blinkDelay).then(() => {
              if (card) {
                card.classList.remove('CakeAwardPage-attemptCardAnimation');
              }
            });
          });

          delay(resetChestsDelay).then(() => {
            cakeChestResetGiftProducts();
            endLoading();

            if (normalisedData.prise.isSectorPrize) {
              setSectorPrize(normalisedData.prise);
            }
          });

          return;
        }

        cakeChestProductResponse(normalisedData, attempt);
        setPersistedCakeCount(newAttempt);

        delay(prizeEndAnimationDelay).then(() => {
          pushToWonProducts(normalisedData.prise);
          cakeChestResetGiftProducts();

          if (normalisedData.prise.isSectorPrize) {
            setSectorPrize(normalisedData.prise);
          }

          if (newAttempt <= 0) {
            setTimeout(() => decreaseAttempt(), 2000);
          }
        });
      })
      .catch((error) => {
        console.error('cakeChestChoseProductRequest error ->', error);
        GAcakeChestResponse(localTimer(Date.now()), error);
        push(`/order-thanks?order_number=${orderId}`);
      });
  };

  const cakeChestChoseProductRequest = (productId, orderId, callback) => {
    const localTimer = getDiffTime();

    chooseChest(productId);
    GAcakeChestRequest();
    /**
     * choose prise chest id and get gift products and prise
     * @param {Number} picked chest id
     * @param {Number} order id
     * @return {Array.<{id: Number, isPrise: Boolean, picture: String, title: String}>}
     */
    sammyBeautyApi()
      .cakeChestChoseProduct({ 'picked-chest': productId, number: orderId, 'opened-link': isValidUser })
      .then((data) => {
        GAcakeChestResponse(localTimer(Date.now()));
        const normalisedData = normaliseData(data);
        let newAttempt = attempt;

        if (!normalisedData.prise.isSectorPrize) {
          newAttempt -= 1;
        }

        if (newAttempt > 0) {
          cakeChestProductResponse(normalisedData);
          delay(endAnimationDelay).then(() => {
            if (normalisedData.prise.isSectorPrize) {
              setIsSectorPrize(true);
            }

            // startLoading();
            setPersistedCakeCount(newAttempt);
            if (!normalisedData.prise.isSectorPrize) {
              decreaseAttempt();
              pushToWonProducts(normalisedData.prise);
              return;
            }
            cakeChestResetGiftProducts();
            callback();

            const card = document.querySelectorAll('.CakeAwardPage-attemptCard')[0];
            if (card) {
              card.classList.add('CakeAwardPage-attemptCardAnimation');
            }

            delay(blinkDelay).then(() => {
              if (card) {
                card.classList.remove('CakeAwardPage-attemptCardAnimation');
              }
            });
          });

          delay(resetChestsDelay).then(() => {
            cakeChestResetGiftProducts();
            endLoading();

            if (normalisedData.prise.isSectorPrize) {
              // setIsSectorPrize(true);
            }
          });

          return;
        }

        cakeChestProductResponse(normalisedData, attempt);
        setPersistedCakeCount(newAttempt);

        delay(endAnimationDelay).then(() => {
          if (normalisedData.prise.isSectorPrize) {
            // setIsSectorPrize(true);
            cakeChestResetGiftProducts();
            endLoading();
            return;
          }

          pushToWonProducts(normalisedData.prise);
          cakeChestResetGiftProducts();
          decreaseAttempt();
        });
      })
      .catch((error) => {
        console.error('cakeChestChoseProductRequest error ->', error);
        GAcakeChestResponse(localTimer(Date.now()), error);
        push(`/order-thanks?order_number=${orderId}`);
      });
  };

  const resetCakeChests = () => {
    resetStore();
    setPersistedPickedForChangeProducts([]);
    setPersistedWonProducts([]);
    setPersistedCakeCount(0);
    setPersistedIsCakeActive(false);
    setPersistedCakeTimer(1200);
    setPersistedIsValidUser(false);
    setPersistedValidationOrderNumber(null);
  };

  const setNewWon = (newWon) => {
    setPersistedWonProducts(newWon);
    setWonProducts(newWon);
  };

  useEffect(() => {
    const handleOrderCreated = () => {
      resetCakeChests();
    };
    window.addEventListener('order_created', handleOrderCreated);
    return () => window.removeEventListener('order_created', handleOrderCreated);
  }, [resetCakeChests]);

  return [
    {
      wonProducts,
      giftProducts,
      isLoading,
      isCakeActive,
      attempt,
      cakeTimer: timer,
      pickedForChangeProducts,
      isValidUser,
      isSectorPrize,
      sectorPrize,
    },
    {
      cakeChestRequest,
      cakeChestChoseProductRequest,
      cakePrizeChestChoseProductRequest,
      startLoading,
      decreaseAttempt,
      cakeChestResetGiftProducts,
      pushToWonProducts,
      setNewWon,
      resetCakeChests,
      setValidationUserData,
      setIsSectorPrize,
      setSectorPrize,
      setPickedForChangeProducts,
    },
  ];
};

const CakeChestContext = createContext();

export const useCakeChest = () => {
  return useContext(CakeChestContext);
};

export const CakeChestStore = ({ children }) => (
  <CakeChestContext.Provider value={useHook()}>{children}</CakeChestContext.Provider>
);

CakeChestContext.displayName = 'CakeChestContext';
