import React from 'react';
import styles from './PlaitBanner.module.scss';

export const PlaitBanner: React.FunctionComponent = () => {
  return (
    <div className={styles.plaitBanner}>
      <span className={styles.text}>Остались вопросы?</span>
      <a className={styles.phone} href="tel:88003020251">
        8 800 302-02-51
      </a>
      <a className={styles.site} href="https://plait.ru/" target="blank">
        plait.ru
      </a>
    </div>
  );
};
