import { MenuTypeItem } from 'api/types';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import styles from './PrimaryNav.module.scss';

type MenuSideDialogPrimaryNavProps = {
  className?: string;
  menu: MenuTypeItem[];
  isMobile?: boolean;
};

type NavLinkProps = {
  item: MenuTypeItem;
};
const NavLink: React.FC<NavLinkProps> = ({ item: m }) => {
  if (m.href) {
    return (
      <a
        href={m.href}
        className={styles.item}
        target={m.is_new_window ? '_blank' : undefined}
        style={{
          color: m.color,
        }}>
        {m.title}
      </a>
    );
  }

  return (
    <RouterLink
      to={m.path}
      className={styles.item}
      target={m.is_new_window ? '_blank' : undefined}
      style={{
        color: m.color,
      }}>
      {m.title}
    </RouterLink>
  );
};

export const PrimaryNav: React.FC<MenuSideDialogPrimaryNavProps> = ({ menu }) => {
  return (
    <nav className={styles.nav}>
      {menu.map((item) => (
        <NavLink item={item} />
      ))}
    </nav>
  );
};
