function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

const smallWeekDaysByLongDay = {
  понедельник: 'пн',
  вторник: 'вт',
  среда: 'ср',
  четверг: 'чт',
  пятница: 'пт',
  суббота: 'сб',
  воскресенье: 'вс',
};

function getSmallWeekDay(string) {
  return smallWeekDaysByLongDay[string.toLowerCase().trim()] || string;
}

function chunkDaysOntoIntervals(days, dayTimeSplitSymbol = ' ') {
  const weekDays = Object.values(smallWeekDaysByLongDay);

  return days.reduce(
    (acc, cur) => {
      let [curDay, curTime] = cur.split(dayTimeSplitSymbol);

      if (!curTime) curTime = '';
      curDay = getSmallWeekDay(curDay).toLowerCase();

      if (acc.prevTime && acc.prevTime === curTime && weekDays[weekDays.indexOf(curDay) - 1] === acc.prevDay) {
        const lastIntervalIndex = acc.intervals.length - 1;

        acc.intervals[lastIntervalIndex].push({ day: curDay, time: curTime });
      } else {
        const nextIntervalIndex = acc.intervals.length;

        acc.intervals[nextIntervalIndex] = [];
        acc.intervals[nextIntervalIndex].push({ day: curDay, time: curTime });
      }

      acc.prevDay = curDay;
      acc.prevTime = curTime;

      return acc;
    },
    { intervals: [], prevTime: null, prevDay: null },
  ).intervals;
}

function getTimeGap(interval) {
  if (interval.length === 0) return;

  if (!interval[0]?.day) return;

  const fistDayWeekDay = titleCase(interval[0].day);
  const firstDayTime = interval[0].time;

  if (interval.length === 1) return `${fistDayWeekDay} ${firstDayTime}`;

  const lastDayInIntervalWeekDay = titleCase(interval.slice(-1)[0].day);

  return `${fistDayWeekDay}-${lastDayInIntervalWeekDay} ${firstDayTime}`;
}

function PickPointTimeNormalizer() {
  return {
    normalize(schedule) {
      const days = schedule.split(', ');

      return chunkDaysOntoIntervals(days)
        .map((interval) => getTimeGap(interval))
        .filter(Boolean)
        .join(', ');
    },
  };
}

function SBERLOGTimeNormalizer() {
  return {
    normalize(schedule) {
      const days = schedule.split(';');

      return chunkDaysOntoIntervals(days)
        .map((interval) => getTimeGap(interval))
        .filter(Boolean)
        .join(', ')
        .replace(/\s-\s/gi, ' - Выходной');
    },
  };
}

export class ScheduleNormalizer {
  constructor() {
    this.PickPointTimeNormalizer = new PickPointTimeNormalizer();
    this.SBERLOGTimeNormalizer = new SBERLOGTimeNormalizer();
  }

  normalize(type, schedule) {
    switch (type) {
      case 'PICKPOINT':
        return this.PickPointTimeNormalizer.normalize(schedule);
      case 'SBERLOG':
        return this.SBERLOGTimeNormalizer.normalize(schedule);
      default:
        return schedule;
    }
  }
}
