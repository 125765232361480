import React from 'react';
import { CombineStores } from '../combine-stores';
import { ActiveOrderStepStore, useActiveOrderStep } from './active-order-step';
import { OrderDeliveryMethodsStore, useOrderDeliveryMethods } from './order-delivery-methods';
import { OrderPaymentMethodsStore, useOrderPaymentMethods } from './order-payment-methods';
import { OrderPersonalDataStore, useOrderPersonalData } from './order-personal-data';
import { OrderDeliveryDataStore, useOrderDeliveryData } from './order-delivery-data';
import { OrderPaymentDataStore, useOrderPaymentData } from './order-payment-data';
import { OrderSubmitStore, useOrderSubmit } from './order-submit';
import { OrderPickPointsStore, useOrderPickPoints } from './order-pick-points';
import { useOrderDataResetting } from './use-order-data-resetting';

const OrderStore = ({ children }) => (
  <CombineStores
    stores={[
      ActiveOrderStepStore,
      OrderDeliveryMethodsStore,
      OrderPaymentMethodsStore,
      OrderPickPointsStore,
      OrderPersonalDataStore,
      OrderDeliveryDataStore,
      OrderPaymentDataStore,
      OrderSubmitStore,
    ]}>
    {children}
  </CombineStores>
);

export {
  useActiveOrderStep,
  useOrderDeliveryMethods,
  useOrderPersonalData,
  useOrderDeliveryData,
  useOrderPaymentData,
  useOrderDataResetting,
  useOrderPaymentMethods,
  useOrderSubmit,
  useOrderPickPoints,
  OrderStore,
};
