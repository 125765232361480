import React from 'react';
import cn from 'classnames';
import { formatPrice } from './format-price';
import './price.css';

export const Price = ({ number, variant = 'current' }) => (
  <span className={cn('Price', `Price--variant-${variant}`)}>
    <nobr>
      <span className="Price-number">{formatPrice(number)}</span>
      <span className="Price-currency">&nbsp;&#x20bd;</span>
    </nobr>
  </span>
);
