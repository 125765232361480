export function getSeconds(timer) {
  if (timer % 60 < 10) {
    return `0${timer % 60}`;
  }

  return timer % 60;
}

export function getMinutes(timer) {
  const minutes = Math.floor(timer / 60);

  if (minutes < 10) {
    return `0${Math.floor(timer / 60)}`;
  }
  return `${Math.floor(timer / 60)}`;
}

export function getCurrentFullTime() {
  const currentTime = Date.now();
  const today = new Date(currentTime);

  return today.toISOString();
}

export function getDiffTime() {
  const currentTime = Date.now();
  return (time) => {
    if (time) {
      return time - currentTime;
    }

    return null;
  };
}
