import { getBuyLink } from '../routes';

export const footerNavigation = [
  {
    id: 'company',
    title: 'Компания',
    list: [
      { title: 'О бренде', link: '/about-us' },
      // { title: "Видео от оксаны", link: "/" },
      { title: 'Сертификаты', link: '/rewards' },
    ],
  },

  {
    id: 'help',
    title: 'Помощь',
    list: [
      { title: 'Статус заказа', link: '/track-order' },
      { title: 'Доставка и оплата', link: '/delivery-and-payment' },
      { title: 'Контакты', link: '/contacts' },
      { title: 'Вопрос-ответ', link: '/faq' },
      // { title: "Пользовательские соглашения", link: "/agreements" },
    ],
  },

  {
    id: 'shop',
    title: 'Магазин',
    list: [
      { title: 'Каталог', link: '/category/vzroslye' },
      { title: 'Где купить', link: getBuyLink() },
      { title: 'Отзывы', link: '/reviews' },
    ],
  },
];
