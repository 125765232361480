import React, { useRef } from 'react';
import isEqual from 'react-fast-compare';
import cn from 'classnames';
import { Button } from '../button/button';
import './pick-point-row.css';

const IconSuccess = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0001 1L4.00004 8.00002L1 5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PickPointRow = React.memo(({ pickPoint, isSelected, onChoose, isChosen, onClick }) => {
  const PickPointRowRef = useRef(null);

  const handleChoose = () => {
    onChoose(pickPoint.id);
  };
  const handleClick = () => {
    onClick(pickPoint);
  };

  return (
    <div
      ref={PickPointRowRef}
      className={cn('PickPointRow', { 'PickPointRow--selected': isSelected || isChosen })}
      onClick={handleClick}>
      {pickPoint.deliveryCompany.logo && (
        <img
          className="PickPointRow-companyLogoImage"
          src={pickPoint.deliveryCompany.logo}
          alt={pickPoint.deliveryCompanyTitle}
          width={38}
          height={38}
        />
      )}
      <div className="PickPointRow-content">
        <div className="PickPointRow-titleValue">
          {' '}
          <img
            className="PickPointRow-companyLogoImage"
            src={pickPoint.deliveryCompany.logo}
            alt={pickPoint.deliveryCompanyTitle}
            width={38}
            height={38}
          />
          <span>{pickPoint.title}</span>
        </div>

        <div className="PickPointRow-descriptionValue">
          <div className="PickPointRow-addressValue">{pickPoint.address}</div>
          <div className="PickPointRow-workTime">{pickPoint.schedule}</div>
        </div>

        <Button
          title={
            isSelected ? (
              <>
                Выбрано <IconSuccess />
              </>
            ) : (
              'забрать тут'
            )
          }
          type="submit"
          isPrimary
          onClick={handleChoose}
          variant={isSelected && 'dark'}
        />
      </div>
    </div>
  );
}, isEqual);
