export const getIndexLink = () => '/';
export const getOrderStatusLink = () => '/';
export const getReviewLink = (id) => `/review/${id}`;
export const getReviewListLink = () => '/';
export const getProductLink = (slug) => `/product/${slug}`;
export const getProductLinkForKids = (slug) => `/product-for-kids/${slug}`;
export const getBasketLink = () => '/basket';
export const getOrderLink = () => '/order';
export const getOrderThanksLink = () => '/order-thanks';
export const getCatalogLink = () => '/catalog';
export const getContactsLink = () => '/contacts';
export const getCategoryLink = (slug) => `/category/${slug}`;

export const getPrivacyPolicyLink = () => '/';
export const getCookiesLink = () => '/';
export const getTermsOfUseLink = () => '/';
export const get404Link = () => '/error-404';
export const getDeliveryAndPaymentLink = () => '/delivery-and-payment';
export const getRewardsLink = () => '/rewards';
export const getAboutUsLink = () => '/about-us';
export const getAboutUsForKidsLink = () => '/detskie/about-us';
export const getReviewsLink = () => '/reviews';
export const getTrackOrderLink = () => '/track-order';
export const getAgreementsLink = () => '/agreements';
export const getKidsIndexLink = () => '/detskie';

export const getCartLink = () => '/?dialog=basket';

export const validationLink = '/validation';

export const getBuyLink = () => '/buy';
