import React from 'react';
import { Svg } from '../svg/svg';
import './document-icon.css';

export const IconDocument = () => (
  <Svg className="IconDocument">
    <path d="M19.1 2v6.2c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h6.2" />
    <path d="M23.8 30H8.2c-.8 0-1.6-.3-2.2-.9-.6-.6-.9-1.4-.9-2.2V5.1c0-.8.3-1.6.9-2.2.6-.6 1.4-.9 2.2-.9h10.9l7.8 7.8v17.1c0 .8-.3 1.6-.9 2.2-.6.6-1.4.9-2.2.9zM11.3 11.3h1.6M11.3 17.6h9.3M11.3 23.8h9.3" />
  </Svg>
);
