import React from 'react';
import styles from './TooltipTrigger.module.scss';

type Props = {
  onTrigger: () => void;
};

export const TooltipTrigger: React.FC<Props> = ({ onTrigger }) => {
  return (
    <div onClick={onTrigger} className={styles.tooltip}>
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.44385 5.06963V5.34238H2.33369L2.32891 5.01699C2.28584 4.29922 2.56338 3.86855 3.22373 3.48096C3.81709 3.12207 4.01807 2.86846 4.01807 2.39473C4.01807 1.89707 3.61611 1.53818 3.01318 1.53818C2.3959 1.53818 1.99873 1.91143 1.96045 2.49521H0.835938C0.874219 1.36113 1.6542 0.538086 3.07539 0.538086C4.38652 0.538086 5.26221 1.29414 5.26221 2.35166C5.26221 3.08379 4.89853 3.5958 4.24775 3.9834C3.6209 4.35186 3.44385 4.5959 3.44385 5.06963ZM3.73711 6.8644C3.73711 7.32378 3.43086 7.62524 2.94277 7.62524C2.46426 7.62524 2.14844 7.32378 2.14844 6.8644C2.14844 6.40024 2.46426 6.09878 2.94277 6.09878C3.43086 6.09878 3.73711 6.40024 3.73711 6.8644Z"
          fill="#BFBFBF"
        />
      </svg>
    </div>
  );
};
