import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { IconContainer } from '../icon-container/icon-container';
import { IconArrowRight } from '../icons';
import './promocode-input.css';

export const PromocodeInput = ({ isDisabled, onChange, wasChanged, ...rest }) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (wasChanged) {
      inputElement.current.focus();
    }
  }, [wasChanged]);

  return (
    <div
      className={cn('PromocodeInput', {
        'PromocodeInput--disabled': isDisabled,
      })}>
      <input
        placeholder="Введите промокод"
        className="PromocodeInput-el"
        type="text"
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value.toUpperCase())}
        ref={inputElement}
        {...rest}
      />

      <button className="PromocodeInput-submitButton" disabled={isDisabled} {...rest}>
        <IconContainer icon={<IconArrowRight />} />
      </button>
    </div>
  );
};
