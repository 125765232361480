import React, { useRef } from 'react';
import { Portal } from 'react-portal';
import { useTransition, animated } from '@react-spring/web';
import { useScrollBarWidth } from '../../hooks';
import './right-side-dialog.css';

const springConfig = {
  mass: 1,
  friction: 25,
};

export const RightSideDialog = ({ children, isOpened, onClose }) => {
  const windowElement = useRef(null);
  const scrollBarWidth = useScrollBarWidth();

  const right = isOpened ? '0' : `-${scrollBarWidth}px`;

  const transitions = useTransition([isOpened], {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(100%)' },
    config: springConfig,
  });

  const checkIsOverlay = (target) => {
    return !windowElement.current.contains(target);
  };

  const handleOverlayClick = (e) => {
    if (checkIsOverlay(e.target)) {
      onClose();
    }
  };

  return transitions(
    (props, localIsOpened) =>
      localIsOpened && (
        <Portal>
          <animated.div
            className="RightSideDialog"
            style={{ opacity: props.opacity, right }}
            onClick={handleOverlayClick}>
            <div className="RightSideDialog-container">
              <animated.div className="RightSideDialog-window" style={props} ref={windowElement}>
                {children}
              </animated.div>
            </div>
          </animated.div>
        </Portal>
      ),
  );
};
