import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { bindActionsToDispatch, createAction } from './utils';
import { useDataLayer } from './data-layer';
import { sammyBeautyApi } from '../service';
import { basketNormalize } from './normalizers/normalizers';
import { AddToBasketToast } from '../tsComponents/AddToBasketToast';

const renderAddToBasketToastContent = ({ ...rest }) => {
  return <AddToBasketToast {...rest} />;
};

const addToBasketToast = toast;
addToBasketToast.configure();
const addToBasketToastOption = {
  position: addToBasketToast.POSITION.BOTTOM_RIGHT,
  type: toast.TYPE.INFO,
  autoClose: 3000,
  closeButton: false,
  rtl: true,
};
const createSuccessToastUpdataOptions = (baseProps) => {
  return {
    render: (props) => {
      return <AddToBasketToast {...props} {...baseProps} type="success" />;
    },
    type: toast.TYPE.INFO,
    autoClose: 3000,
  };
};
const createFailedToastUpdataOptions = (baseProps) => {
  return {
    render: (props) => {
      return <AddToBasketToast {...props} {...baseProps} type="failed" />;
    },
    type: toast.TYPE.WARNING,
    autoClose: 3000,
  };
};

const createAddToBasketToastConfig = ({ ...rest }) => {
  return [renderAddToBasketToastContent({ ...rest }), addToBasketToastOption];
};

// Helpers
const getProductsAmount = (productList) => productList.reduce((sum, { amount }) => sum + amount, 0);
const getCakeCount = (productList) => {
  return productList.reduce((sum, { product, amount, gift }) => {
    return sum + (!gift ? product.cake_count * amount : 0);
  }, 0);
};

const findIndexInBasket = (productId, basketProductList) =>
  basketProductList.findIndex(({ product, gift }) => product.id === productId && !gift);

// Types
const ADD_PRODUCT = 'BASKET/ADD_PRODUCT';
const REMOVE_PRODUCT = 'BASKET/REMOVE_PRODUCT';
const CHANGE_PRODUCT_AMOUNT = 'BASKET/CHANGE_PRODUCT_AMOUNT';
const UPDATE_BASKET_FROM_SERVER = 'BASKET/UPDATE_BASKET_FROM_SERVER';

const SET_PROMOCODE = 'BASKET/SET_PROMOCODE';
const RESET_PROMOCODE = 'BASKET/RESET_PROMOCODE';

const RESET_BASKET = 'BASKET/RESET_BASKET';
const SET_BASKET_IS_LOADING = 'BASKET/SET_BASKET_IS_LOADING';

const actions = {
  addProduct: (product, amount = 1) =>
    createAction(ADD_PRODUCT, {
      id: nanoid(),
      product,
      amount,
    }),

  removeProduct: (product) => createAction(REMOVE_PRODUCT, { id: product.id }),

  changeProductAmount: (product, amount) => createAction(CHANGE_PRODUCT_AMOUNT, { id: product.id, amount }),

  updateBasketFromServer: (basket) => createAction(UPDATE_BASKET_FROM_SERVER, { basket }),

  setPromocode: (promocode) => createAction(SET_PROMOCODE, { promocode }),

  resetPromocode: () => createAction(RESET_PROMOCODE),

  resetBasket: () => createAction(RESET_BASKET),

  setBasketIsLoading: (isLoading) => createAction(SET_BASKET_IS_LOADING, { isLoading }),
};

const initialState = {
  productList: [],
  promocode: null,
  isReady: false,
  isLoading: false,
};

function correctPrice(product, newAmount) {
  const defaultPrice = (product.price.default / product.amount) * newAmount;
  return {
    ...product,
    price: {
      ...product.price,
      default: defaultPrice,
    },
  };
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_BASKET_FROM_SERVER: {
      const { basket } = payload;
      const normalizedBasket = basketNormalize(basket);

      const isReady = !normalizedBasket.productList.some((item) => !item.product.is_available);

      return { ...state, isLoading: false, ...normalizedBasket, isReady };
    }

    case ADD_PRODUCT: {
      const { id, product, amount } = payload;
      const { productList } = state;

      const indexInBasket = findIndexInBasket(product.id, productList);

      if (indexInBasket !== -1) {
        const basketProduct = productList[indexInBasket];
        const updatedBasketProduct = {
          ...basketProduct,
          amount: basketProduct.amount + amount,
        };

        return {
          ...state,
          productList: [
            ...productList.slice(0, indexInBasket),
            updatedBasketProduct,
            ...productList.slice(indexInBasket + 1),
          ],
          isLoading: true,
        };
      }

      return {
        ...state,
        productList: [...productList, { id, product, amount }],
        isLoading: true,
      };
    }

    case REMOVE_PRODUCT: {
      const { id } = payload;
      const { productList } = state;
      const removedProductIndex = findIndexInBasket(id, productList);
      const newProductList = [
        ...productList.slice(0, removedProductIndex),
        ...productList.slice(removedProductIndex + 1),
      ];
      return {
        ...state,
        // Временно скроем товары в подарок, при следующем запросе корзины применимые подарки будет получены
        productList: newProductList.filter((p) => !p.gift),
        isLoading: true,
      };
    }

    case CHANGE_PRODUCT_AMOUNT: {
      const { id, amount } = payload;
      const { productList } = state;

      const changedProductIndex = findIndexInBasket(id, productList);
      const changingProduct = productList[changedProductIndex];
      const changingProductWithCorrectedPrice = correctPrice(changingProduct, amount);
      const changedProduct = { ...changingProductWithCorrectedPrice, amount };

      return {
        ...state,
        productList: [
          ...productList.slice(0, changedProductIndex),
          changedProduct,
          ...productList.slice(changedProductIndex + 1),
        ],
        isLoading: true,
      };
    }

    case SET_PROMOCODE: {
      const { promocode } = payload;
      return { ...state, promocode };
    }

    case RESET_PROMOCODE: {
      return { ...state, promocode: null };
    }

    case RESET_BASKET: {
      return { ...initialState, isReady: true };
    }

    case SET_BASKET_IS_LOADING: {
      return { ...state, isLoading: payload.isLoading };
    }

    default:
      return state;
  }
};

function deserializeProductsToBasket(product, amount) {
  return {
    goods_list: [
      {
        quantity: amount,
        product: {
          slug: product.slug,
        },
      },
    ],
  };
}

function getTotalPriceNumber(localProductList) {
  return localProductList.reduce((accumulatedPrice, { price, amount }) => {
    // если нет кол-ва по товару, то цену считаем равной 0.
    const nextValue = !amount ? 0 : price?.default;
    return accumulatedPrice + nextValue;
  }, 0);
}

function getPriceNumberWithoutPromo(localProductList) {
  return localProductList.reduce((acc, { price, amount }) => {
    const nextValue = !amount ? 0 : price?.default + (price?.discount ?? 0);
    return acc + nextValue;
  }, 0);
}

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { productList, promocode, isReady, isLoading } = state;
  const [isBasketInRequest, setIsBasketInRequest] = useState(false);

  const [, { addToBasketDL }] = useDataLayer();

  const {
    addProduct: localAddProduct,
    removeProduct: localRemoveProduct,
    changeProductAmount: localChangeProductAmount,
    updateBasketFromServer,
    setPromocode: localSetPromocode,
    resetPromocode: localResetPromocode,
    resetBasket,
    setBasketIsLoading,
  } = bindActionsToDispatch(actions, dispatch);

  async function getInitialBasket() {
    if (isBasketInRequest) return;

    setIsBasketInRequest(true);
    setBasketIsLoading(true);

    try {
      const data = await sammyBeautyApi().basket.initial();

      updateBasketFromServer(data);
    } catch (error) {
      console.warn('getInitialBasket -> e', error);
    } finally {
      setIsBasketInRequest(false);
    }
  }

  useEffect(() => {
    getInitialBasket();
  }, []);

  function fetchAddProductToBasket(product, amount = 1, toastId) {
    setBasketIsLoading(true);
    setIsBasketInRequest(true);

    sammyBeautyApi()
      .basket.addProducts(deserializeProductsToBasket(product, amount))
      .then((data) => {
        console.log('fetchAddProductToBasket -> data', data);
        addToBasketDL(product, amount);
        localAddProduct(product, amount);
        updateBasketFromServer(data);
        return data;
      })
      .then((basket) => {
        addToBasketToast.update(toastId, createSuccessToastUpdataOptions({ productTitle: product.title }));
        return basket;
      })
      .catch((error) => {
        addToBasketToast.update(toastId, createFailedToastUpdataOptions({ productTitle: product.title }));
        console.warn('fetchAddProductToBasket -> e', error);
      })
      .finally(() => {
        setBasketIsLoading(false);
        setIsBasketInRequest(false);
      });
  }

  function fetchEditProductInBasket(product, amount) {
    setBasketIsLoading(true);
    setIsBasketInRequest(true);

    sammyBeautyApi()
      .basket.editProducts(deserializeProductsToBasket(product, amount))
      .then((data) => {
        localChangeProductAmount(product, amount);
        return updateBasketFromServer(data);
      })
      .catch((error) => {
        console.warn('fetchEditProductInBasket -> e', error);
      })
      .finally(() => {
        setBasketIsLoading(false);
        setIsBasketInRequest(false);
      });
  }

  function fetchDeleteProductFromBasket(product, amount) {
    setIsBasketInRequest(true);

    sammyBeautyApi()
      .basket.deleteProducts(deserializeProductsToBasket(product, amount))
      .then((data) => updateBasketFromServer(data))
      .catch((error) => {
        console.warn('fetchDeleteProductFromBasket -> e', error);
      })
      .finally(() => setIsBasketInRequest(false));
  }

  function addProduct(product, amount = 1) {
    const toastId = addToBasketToast(...createAddToBasketToastConfig({ productTitle: product.title }));
    fetchAddProductToBasket(product, amount, toastId);
  }
  function removeProduct(product) {
    localRemoveProduct(product);
    fetchDeleteProductFromBasket(product);
  }
  function changeProductAmount(...data) {
    fetchEditProductInBasket(...data);
  }
  function setPromocode(...data) {
    localSetPromocode(...data);
  }
  function resetPromocode(...data) {
    localResetPromocode(...data);
  }

  return [
    {
      productList,
      productsAmount: getProductsAmount(productList),
      cakeCount: getCakeCount(productList),
      promocode,
      totalPriceNumber: getTotalPriceNumber(productList),
      totalPriceNumberWithoutPromo: getPriceNumberWithoutPromo(productList),
      isEmpty: productList.length === 0,
      isReady,
      isLoading,
      showGiftsDialog: !!promocode?.find((p) => p.show_gifts_dialog),
    },
    {
      addProduct,
      removeProduct,
      changeProductAmount,
      checkIsInBasket: useCallback((product) => findIndexInBasket(product.id, productList) !== -1, [productList]),
      setPromocode,
      resetPromocode,
      resetBasket,
      updateBasketFromServer,
      updateBasket: getInitialBasket,
    },
  ];
};

const BasketContext = createContext();

export const useBasket = () => useContext(BasketContext);

export const BasketStore = ({ children }) => (
  <BasketContext.Provider value={useHook()}>{children}</BasketContext.Provider>
);
BasketContext.displayName = 'BasketContext';
