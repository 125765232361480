import React, { useState, useEffect, useContext, createContext } from 'react';

const useHook = () => {
  const [headerElement, setHeaderElement] = useState();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!headerElement) {
      return;
    }

    setHeight(headerElement.offsetHeight);
  }, [headerElement]);

  useEffect(() => {
    if (!headerElement) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height: rectHeight } = entry.contentRect;
        const { paddingTop, paddingBottom } = window.getComputedStyle(headerElement);

        const finalHeight =
          Number.parseFloat(rectHeight) + Number.parseFloat(paddingTop) + Number.parseFloat(paddingBottom);

        setHeight(finalHeight);
      }
    });

    resizeObserver.observe(headerElement);
    return () => resizeObserver.disconnect();
  }, [headerElement]);

  return [height, setHeaderElement];
};

const HeaderHeightContext = createContext();

export const useHeaderHeight = () => useContext(HeaderHeightContext);

export const HeaderHeightStore = ({ children }) => (
  <HeaderHeightContext.Provider value={useHook()}>{children}</HeaderHeightContext.Provider>
);

HeaderHeightContext.displayName = 'HeaderHeightContext';
