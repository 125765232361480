import React from 'react';
import { CustomScrollbarWrapper } from '../custom-scrollbar/custom-scrollbar';
import { ShopsTableRow } from './shops-table-row';
import './shops-table.css';

export const ShopsTable = ({ list, ...rest }) => {
  if (!list) {
    return null;
  }

  return (
    <div className="ShopTable">
      <div className="ShopTable-titleGroup">
        <div className="ShopTable-index">Индекс</div>
        <div className="ShopTable-adress">Адрес</div>
      </div>

      <div className="ShopTable-listPositioner">
        <CustomScrollbarWrapper verticalTrackRight="0">
          <div className="ShopTable-list">
            {list.map((item) => (
              <div key={item.adressRow} className="ShopTable-listItem">
                <ShopsTableRow shop={item} {...rest} />
              </div>
            ))}
          </div>
        </CustomScrollbarWrapper>
      </div>
    </div>
  );
};
