import React, { useState, useContext, createContext } from 'react';
import { orderStepsData } from '../../data';

const localStepList = [
  { ...orderStepsData.personal, isReady: false, isActive: true },
  { ...orderStepsData.delivery, isReady: false, isActive: false },
  { ...orderStepsData.payment, isReady: false, isActive: false },
];

const findActive = ({ isActive }) => isActive;

const useHook = () => {
  const [stepList, setStepList] = useState(localStepList);

  const goToNextStep = () => {
    const currentStepIndex = stepList.findIndex((el) => findActive(el));
    const isLastIndex = currentStepIndex === stepList.length - 1;

    if (isLastIndex) {
      return;
    }

    const updatedStepList = stepList.map((step) => ({
      ...step,
      isActive: false,
    }));

    updatedStepList[currentStepIndex].isReady = true;
    updatedStepList[currentStepIndex + 1].isActive = true;

    setStepList(updatedStepList);
  };

  const goToStep = (index) => {
    console.log('goToStep -> index', index);

    const updatedStepList = stepList.map((step) => ({
      ...step,
      isActive: false,
    }));

    updatedStepList[index].isActive = true;

    setStepList(updatedStepList);
  };

  return [
    { stepList, activeStep: stepList.find((el) => findActive(el)) },
    { goToNextStep, goToStep, resetActiveStep: () => setStepList(localStepList) },
  ];
};

const ActiveOrderStepContext = createContext();

export const useActiveOrderStep = () => useContext(ActiveOrderStepContext);
export const ActiveOrderStepStore = ({ children }) => (
  <ActiveOrderStepContext.Provider value={useHook()}>{children}</ActiveOrderStepContext.Provider>
);

ActiveOrderStepContext.displayName = 'ActiveOrderStepContext';
