import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type TContainer = {
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  children?: React.ReactNode;
};

export const Container = React.forwardRef<HTMLDivElement, TContainer>(
  ({ isFullHeight, children, isFullWidth }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(styles.Container, {
          [styles['Container--fullHeight']]: isFullHeight,
          [styles['Container--fullWidth']]: isFullWidth,
        })}>
        {children}
      </div>
    );
  },
);
