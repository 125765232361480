const DADATA_ADDRESS_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

const DADATA_CLEAN_API_URL = 'https://dadata.ru/api/v2/clean/address';

function checkResponse(response) {
  if (response.ok) {
    return response;
  }
  const error = new Error(response);
  error.response = response;
  throw error;
}

function getHeaders(token) {
  return {
    accept: 'application/json',
    authorization: `Token ${token}`,
    'content-type': 'application/json',
  };
}

function Request(method = 'get', url, headers, body) {
  return fetch(url, {
    method,
    headers,
    body,
  })
    .then(checkResponse)
    .then((response) => response.json())
    .catch((error) => console.warn(error));
}

export function Dadata() {
  const token = 'd1d222ccec7088391e5c40f7bc25fc05d24e81fe';
  // token = '872e17f6cd47fd17d96d682f1d9a7e4d12d0efd3';

  return {
    city(query, fiadId, setFiasId, streetTitle, { count = 6, /* withSettlements = false, */ locations = [] } = {}) {
      const data = {
        query,
        count,
        from_bound: { value: 'city' },
        // to_bound: withSettlements ? { value: 'settlement' } : { value: 'city' },
        to_bound: { value: 'settlement' },
        locations,
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    street(query, cityFiasId, setFiasId, streetTitle, { count = 6 }) {
      const location = {};

      if (setFiasId) {
        location.settlement_fias_id = setFiasId;
      } else {
        location.city_fias_id = cityFiasId;
      }

      const data = {
        query,
        count,
        from_bound: { value: 'street' },
        to_bound: { value: 'street' },
        locations: [location],
        restrict_value: true,
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },
    house(query, cityFiasId, setFiasId, streetTitle = '', { count = 6 }) {
      const location = {};

      if (setFiasId) {
        location.settlement_fias_id = setFiasId;
      } else {
        location.city_fias_id = cityFiasId;
      }

      const data = {
        query: `${streetTitle} ${query}`,
        count,
        from_bound: { value: 'street' },
        to_bound: { value: 'house' },
        locations: [location],
        restrict_value: true,
      };

      if (!streetTitle) {
        return Promise.reject();
      }

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    adress(query, count = 6) {
      const data = {
        query,
        count,
      };

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_ADDRESS_API_URL, headers, body);
    },

    clean(query) {
      const data = [query];

      const body = JSON.stringify(data);
      const headers = getHeaders(token);

      return Request('post', DADATA_CLEAN_API_URL, headers, body);
    },
  };
}
