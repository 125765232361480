import { MediaItemType } from 'tsComponents/Media/types';
import image from '../seeds/instagram.jpg';

export const instagramImages = [
  {
    id: 0,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 1,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 2,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 3,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 4,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 5,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 6,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 7,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 8,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 9,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
  {
    id: 10,
    media: {
      title: 'Image seed',
      type: MediaItemType.IMAGE,
      sources: [image],
    },
    link: 'https://www.instagram.com/leks_illyashenko',
    name: 'leksandra_illyashenko',
  },
];
