import React, { useContext, createContext, useCallback, useReducer, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { getLocalStorage, setLocalStorage } from 'utils';
import { sammyBeautyApi } from '../service/index';
import { bindActionsToDispatch, createAction } from './utils';

const UPDATE_ATTEMPT = 'DESIRES_FORM/UPDATE_ATTEMPT';
const DISPLAY_SUCCESS = 'DESIRES_FORM/DISPLAY_SUCCESS';
const HIDE_SUCCESS = 'DESIRES_FORM/HIDE_SUCCESS';

const initialState = {
  isOutOfMaxAttempt: false,
  isSuccessHidden: true,
};
const actions = {
  updateAttempt: (isOutOfMaxAttempt) => createAction(UPDATE_ATTEMPT, { isOutOfMaxAttempt }),
  displaySuccess: () => createAction(DISPLAY_SUCCESS),
  hideSuccess: () => createAction(HIDE_SUCCESS),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_ATTEMPT: {
      return {
        ...state,
        isOutOfMaxAttempt: payload.isOutOfMaxAttempt,
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        isSuccessHidden: false,
      };
    }
    case HIDE_SUCCESS: {
      return {
        ...state,
        isSuccessHidden: true,
      };
    }
    default:
      return state;
  }
};

const clearPhone = (phone) =>
  `+${phone.split('').reduce((acc, cur) => acc + (Number(cur) || cur === '0' ? cur : ''), '')}`;

const formData = ({
  fullName,
  desiresEmail,
  instagramUsername,
  desire,
  desiresPhone,
  birthDate,
  sex,
  familyStatus,
}) => ({
  name: fullName,
  email: desiresEmail,
  phone: clearPhone(desiresPhone),
  instagram: instagramUsername,
  wish: desire,
  flag: true,
  BirthDate: birthDate,
  Sex: sex,
  Familystatus: familyStatus,
});

const REQUIRED_ERROR = 'Это поле является обязательным';
const FULLNAME_FORMAT_ERROR = 'Имя может содержать кириллицу (а-я) и латиницу (a-z)';
// const INSTAGRAM_FORMAT_ERROR = 'Неверный формат имени в Инстаграм';
const EMAIL_FORMAT_ERROR = 'Неверно введеный формат почты';
const PHONE_FORMAT_ERROR = 'Не достаточное количество цифр';
const DESIRE_MIN_LENGTH_ERROR = 'Минимально допустимо 3 символа';
const DESIRE_MAX_LENGTH_ERROR = 'Доступно 500 символов';
const MAX_ATTEMPT_ERROR = 'Попробуйте оставить свое желание позже';
const DATE_FORMAT_ERROR = 'Неверно введеный формат даты';

const fullNameRegExp = /^[\sA-Za-zА-я-]+$/;
const dateRegex = /^\s*(3[01]|[12]\d|0?[1-9])\.(1[0-2]|0?[1-9])\.((?:19|20)\d{2})$/;

const validationSchema = object({
  fullName: string().required(REQUIRED_ERROR).matches(fullNameRegExp, FULLNAME_FORMAT_ERROR),
  desiresEmail: string().required(REQUIRED_ERROR).email(EMAIL_FORMAT_ERROR),
  desire: string().required(REQUIRED_ERROR).min(3, DESIRE_MIN_LENGTH_ERROR).max(499, DESIRE_MAX_LENGTH_ERROR),
  desiresPhone: string().required(REQUIRED_ERROR).min(12, PHONE_FORMAT_ERROR),
  birthDate: string().required(REQUIRED_ERROR).matches(dateRegex, DATE_FORMAT_ERROR),
  sex: string().required(REQUIRED_ERROR),
  familyStatus: string().required(REQUIRED_ERROR),
});

const initialValues = {
  fullName: '',
  desiresEmail: '',
  desiresPhone: '',
  instagramUsername: '',
  desire: '',
  privacy: false,
  birthDate: '',
  sex: '',
  familyStatus: '',
};

const useHook = () => {
  const [isCheckerActive, setIsCheckerActive] = useState(false);
  const [{ isOutOfMaxAttempt, isSuccessHidden }, dispatch] = useReducer(reducer, initialState);

  const { updateAttempt, displaySuccess, hideSuccess } = bindActionsToDispatch(actions, dispatch);

  const { values, errors, touched, handleChange, resetForm, handleBlur, isValid } = useFormik({
    initialValues,
    validationSchema,
  });

  // const maxAttemptError = useCallback(() => resetForm({ errors: { desire: MAX_ATTEMPT_ERROR } }), [resetForm]);
  const reset = useCallback(() => resetForm({ values: initialValues }), [resetForm]);

  const changePrivacy = useCallback(() => handleChange({ target: { name: 'privacy', value: !values.privacy } }), [
    handleChange,
    values.privacy,
  ]);

  const localHandleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (value) {
        if (name === 'fullName' && !/^[\sA-Za-zА-я-]+$/.test(value)) return;

        if (name === 'desiresEmail' && !/^[\s\w.@-]+$/.test(value)) return;

        if (name === 'desire' && value.trim().length > 500) return;
      }

      handleChange(e);
    },
    [values, reset],
  );

  const getError = useCallback(
    (name) => {
      if (name === 'desiresPhone') {
        return (
          (isCheckerActive || (values.desiresPhone.length > 1 && errors.desiresPhone !== REQUIRED_ERROR)) &&
          errors.desiresPhone
        );
      }

      if (name === 'desire') {
        return isOutOfMaxAttempt ? MAX_ATTEMPT_ERROR : touched.desire && errors.desire;
      }

      return touched[name] && errors[name];
    },
    [values, touched, errors, isOutOfMaxAttempt],
  );

  const localHandleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!values.privacy || !isValid) {
        return;
      }

      const MaxAttemptDesires = getLocalStorage('MaxAttemptDesires');
      const setToLocalStorageStatus = (status) => setLocalStorage('MaxAttemptDesires', status);

      sammyBeautyApi()
        .newYearDesire(formData(values))
        .then(() => {
          updateAttempt(false);
          displaySuccess();
          setToLocalStorageStatus(false);
          setIsCheckerActive(false);
          localHandleChange({ target: { name: 'desire', value: '' } });
        })
        .catch((error) => {
          const status = error?.response?.status;

          updateAttempt(true);

          if (status === 429 && !MaxAttemptDesires) {
            setToLocalStorageStatus(true);
            displaySuccess();
          }
        });
    },
    [values, reset, isValid],
  );

  const handleMoreClick = useCallback(hideSuccess, [hideSuccess]);

  return [
    {
      values,
      errors,
      isOutOfMaxAttempt,
      isSuccessHidden,
      isPrivacyChecked: values.privacy,
      hasErrors: !isValid,
    },
    {
      handleChange: localHandleChange,
      handleSubmit: localHandleSubmit,
      handleBlur,
      changePrivacy,
      handleMoreClick,
      setIsCheckerActive,
      getError,
    },
  ];
};

const DesiresDataContext = createContext();

export const useDesiresData = () => useContext(DesiresDataContext);

export const DesiresStore = ({ children }) => (
  <DesiresDataContext.Provider value={useHook()}>{children}</DesiresDataContext.Provider>
);

DesiresDataContext.displayName = 'DesiresDataContext';
