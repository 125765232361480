import React from 'react';
import cn from 'classnames';
import { Price } from '../price/price';
import './basket-price-group.css';

export const BasketPriceGroup = (props) => {
  const { price, productPrice, amount, isColumnDirection, isLoading, ...rest } = props;
  if (!price || !productPrice) {
    return null;
  }

  const basketPrice = amount ? price.default / amount : price.default;
  const currentPrice = productPrice.current;
  const hasSale = currentPrice - basketPrice > 0;

  return (
    <span
      className={cn('PriceGroup', {
        'PriceGroup--columnDirection': isColumnDirection,
      })}>
      {Boolean(hasSale) && (
        <span className="PriceGroup-item">
          <Price number={currentPrice} variant="previous" {...rest} />
        </span>
      )}

      <span className="PriceGroup-item">
        <Price number={basketPrice} variant={hasSale ? 'sale' : 'current'} {...rest} />
      </span>
    </span>
  );
};
