import React, { useRef } from 'react';
import classNames from 'classnames';
import { useTransition } from '@react-spring/web';
import { Portal } from 'react-portal';
import styles from './styles.module.scss';
import { Overlay } from '../common/Overlay';

export const stopPropagation = <THTMLElement,>(e: React.MouseEvent<THTMLElement, MouseEvent>) => {
  e.stopPropagation();
};

export type TModal = {
  visible: boolean;
  children: React.ReactNode;
  overlayClassName?: string;
  modalClassName?: string;
  onClose?: () => void;
};

export const Modal: React.FC<TModal> = ({ children, onClose, overlayClassName, visible, modalClassName }) => {
  const windowElement = useRef<null | HTMLDivElement>(null);

  const transitions = useTransition([visible], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const checkIsOverlay = (target: Node) => {
    return !windowElement.current?.contains(target);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (checkIsOverlay(e.target as Node) && onClose) {
      onClose();
    }
  };

  return transitions((style, isOpened) =>
    isOpened ? (
      <Portal>
        <Overlay onClick={handleOverlayClick} className={overlayClassName} style={{ ...style }}>
          <div ref={windowElement} className={classNames(styles.modal, modalClassName)}>
            <div className={styles.box} onClick={stopPropagation}>
              {children}
            </div>
          </div>
        </Overlay>
      </Portal>
    ) : null,
  );
};
