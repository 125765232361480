import React, { Fragment, useEffect, useState } from 'react';
import { SideModal } from 'tsComponents/@ui-kit/modals';
import { sammyBeautyApi } from 'service';
import { MenuType } from 'api/types';
import styles from './styles.module.scss';
import { useMedia } from '../../hooks';
import { MenuHeader } from './components/MenuHeader';
import { Container } from '../Container';
import { PrimaryNav } from './components/PrimaryNav/PrimaryNav';
import { SecondaryNav } from './components/SecondaryNav/SecondaryNav';

type TSideMenu = {
  isVisible: boolean;
  onClose: () => void;
};

export const SideMenu: React.FC<TSideMenu> = ({ isVisible, onClose }) => {
  const isMobile = useMedia('(max-width: 840px)');
  const Wrapper = isMobile ? Container : Fragment;
  const [primaryNav, setPrimaryNav] = useState<MenuType | null>(null);
  const [secondaryNav, setSecondaryNav] = useState<MenuType | null>(null);

  useEffect(() => {
    sammyBeautyApi()
      .getMenu()
      .then((menus: MenuType[]) => {
        setPrimaryNav(menus.find((m) => m.code === 'top_menu') ?? null);
        setSecondaryNav(menus.find((m) => m.code === 'bottom_menu') ?? null);
      });
  }, []);

  return (
    <SideModal onClose={onClose} visible={isVisible}>
      <div className={styles.sideMenu}>
        <div>
          {isMobile && (
            <>
              <MenuHeader onCloseClick={onClose} />
            </>
          )}
          {primaryNav && (
            <Wrapper>
              <PrimaryNav menu={primaryNav.list} />
            </Wrapper>
          )}
        </div>
        {secondaryNav && (
          <div className={styles.bottomMenu}>
            <Wrapper>
              <SecondaryNav menu={secondaryNav.list} />
            </Wrapper>
          </div>
        )}
      </div>
    </SideModal>
  );
};
