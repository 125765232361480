import React from 'react';
import { Svg } from '../svg/svg';
import './promocode.css';

export const IconPromocode = () => (
  <Svg className="IconPromocode">
    <path d="M13.2 18.8l5.7-5.7" />
    <path d="M13.6 14.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5-.5.2-.5.5c.1.3.3.5.5.5zM18.4 18.8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5-.5.2-.5.5.2.5.5.5z" />
    <path d="M9.4 11.5c0-.6.2-1.1.6-1.5.4-.4.9-.6 1.5-.6h.9c.5 0 1.1-.2 1.5-.6l.7-.7c.2-.2.4-.3.7-.5.3-.1.5-.2.8-.2.3 0 .5.1.8.2.3.1.5.3.7.5l.7.7c.4.4.9.6 1.5.6h.9c.6 0 1.1.2 1.5.6.4.4.6.9.6 1.5v.9c0 .5.2 1.1.6 1.5l.7.7c.2.2.3.4.5.7.1.3.2.5.2.8 0 .3-.1.5-.2.8-.1.3-.3.5-.5.7l-.7.7c-.4.4-.6.9-.6 1.5v.9c0 .6-.2 1.1-.6 1.5-.4.4-.9.6-1.5.6h-.9c-.5 0-1.1.2-1.5.6l-.7.7c-.2.2-.4.3-.7.5-.3.1-.5.2-.8.2-.3 0-.5-.1-.8-.2-.3-.1-.5-.3-.7-.5l-.7-.7c-.4-.4-.9-.6-1.5-.6h-.9c-.6 0-1.1-.2-1.5-.6-.4-.4-.6-.9-.6-1.5v-.9c0-.5-.2-1.1-.6-1.5l-.7-.7c-.2-.2-.3-.4-.5-.7-.1-.3-.2-.5-.2-.8 0-.3.1-.5.2-.8.1-.3.3-.5.5-.7l.7-.7c.4-.4.6-.9.6-1.5v-.9" />
  </Svg>
);
