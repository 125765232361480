import React, { useState, useLayoutEffect, useEffect } from 'react';
import cn from 'classnames';
import { checkIsFunction } from '../../utils';
import './input.css';

export const Input = ({
  placeholder,
  placeholderDefault,
  hint,
  value,
  isExpanded,
  isDisabled,
  isError,
  isHideLabelOnFocus,
  onChange,
  onFocus,
  onBlur,
  inputRef,
  appended,
  prepended,
  dropdown,
  defaultValue,
  maskValue,
  changeMaskedValue,
  getChangeValue = (e) => e.target.value,
  ...rest
}) => {
  const [inputEl, setInputEl] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const { name } = rest;
    if (name) {
      const localValue = localStorage.getItem(name);
      if (localValue) {
        const newValue = getChangeValue({ target: { name, value: localValue } });
        onChange(newValue);
        if (checkIsFunction(changeMaskedValue)) {
          changeMaskedValue(localValue);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (defaultValue) {
      onChange({ target: { name: rest.name, value: defaultValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  useEffect(() => {
    const { name } = rest;
    if (name) {
      localStorage.setItem(name, value);
    }
  }, [value]);

  useLayoutEffect(() => {
    setIsActive(value || maskValue || isFocused);
  }, [value, maskValue, isFocused]);

  const handleFocus = (e) => {
    setIsFocused(true);

    if (checkIsFunction(onFocus)) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (checkIsFunction(onBlur)) {
      onBlur(e);
    }
  };

  const handleChange = (e) => {
    if (checkIsFunction(onChange)) {
      onChange(getChangeValue(e));
    }
  };

  const handleClick = () => {
    if (!inputEl) {
      return;
    }

    inputEl.focus();
  };

  const conditionalProps = {
    ...(Boolean(inputRef) && { ref: inputRef }),
  };

  return (
    <div
      className={cn('Input', {
        'Input--expanded': isExpanded,
        'Input--disabled': isDisabled,
        'Input--active': isActive,
        'Input--error': isError,
        'Input--appended': appended,
        'Input--prepended': prepended,
        'Input--visibleHint': !!hint,
        'Input--hidingLabelOnFocus': isHideLabelOnFocus,
        'Input--isDropdown': dropdown,
      })}>
      {prepended && <span className="Input-prepended">{prepended}</span>}
      <div className="Input-elGroup">
        <button className="Input-placeholder" disabled={isFocused} type="button" tabIndex={-1} onClick={handleClick}>
          {placeholder}
        </button>
        <input
          className="Input-el"
          value={value}
          ref={setInputEl}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={dropdown}
          placeholder={placeholderDefault}
          {...rest}
          disabled={isDisabled}
          {...conditionalProps}
        />
      </div>
      {appended && <span className="Input-appended">{appended}</span>}
      <div className="Input-hint">{hint}</div>
    </div>
  );
};
