const MIN_HEIGHT = 480;

export const useAppHeight = () => {
  const doc = document.documentElement;

  const setAppHeight = () => {
    doc.style.setProperty('--app-height', `${Math.max(MIN_HEIGHT, window.innerHeight)}px`);
  };

  window.addEventListener('resize', setAppHeight);
  setAppHeight();

  return () => {
    doc.style.setProperty('--app-height', null);
    window.removeEventListener('resize', setAppHeight);
  };
};
