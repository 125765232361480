import React, { useReducer, useContext, createContext } from 'react';
import { sammyBeautyApi } from 'service';
import { bindActionsToDispatch, createAction, checkIsLoading, checkIsReady, checkIsError } from '../utils';
import { loadingStatesData } from '../../data';

const START_LOAD = 'ORDER_PAYMENT_METHODS/START_LOAD';
const END_LOAD = 'ORDER_PAYMENT_METHODS/END_LOAD';

const actions = {
  startLoad: () => createAction(START_LOAD),
  endLoad: ({ error, list = [] }) => createAction(END_LOAD, { error, list }),
};

const initialState = {
  list: [],
  loadingState: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case START_LOAD: {
      return {
        ...state,
        loadingState: loadingStatesData.loading,
      };
    }

    case END_LOAD: {
      const { error, list } = payload;
      console.log('reducer -> list', list);

      return {
        ...state,
        list,
        loadingState: error ? loadingStatesData.error : loadingStatesData.ready,
      };
    }

    default:
      return state;
  }
};

function normalizePaymentMethods(list) {
  return list.map(({ name, is_online, id, title, logo, onboarding_value, ...rest }) => ({
    slug: name,
    title,
    default: rest.default,
    onboarding: onboarding_value,
    logo,
    id,
  }));
}

const useHook = () => {
  const [{ list, loadingState }, dispatch] = useReducer(reducer, initialState);

  const { startLoad, endLoad } = bindActionsToDispatch(actions, dispatch);

  const request = (paymentData) => {
    startLoad();

    const promise = sammyBeautyApi()
      .orderPaymentMethods(paymentData)
      .then((paymentMethods) => endLoad({ list: normalizePaymentMethods(paymentMethods) }))
      .catch((error) => endLoad({ error }));
    return promise;
  };

  const requestPromise = (paymentData) => {
    return new Promise((resolve) => {
      startLoad();

      sammyBeautyApi()
        .orderPaymentMethods(paymentData)
        .then((paymentMethods) => endLoad({ list: normalizePaymentMethods(paymentMethods) }))
        .catch((error) => endLoad({ error }))
        .finally(() => resolve());
    });
  };

  return {
    list,
    isLoading: checkIsLoading(loadingState),
    isReady: checkIsReady(loadingState),
    isError: checkIsError(loadingState),
    request,
    requestPromise,
  };
};

const OrderPaymentMethodsContext = createContext();

export const useOrderPaymentMethods = () => useContext(OrderPaymentMethodsContext);

export const OrderPaymentMethodsStore = ({ children }) => (
  <OrderPaymentMethodsContext.Provider value={useHook()}>{children}</OrderPaymentMethodsContext.Provider>
);

OrderPaymentMethodsContext.displayName = 'OrderPaymentMethodsContext';
