import { useMedia } from 'hooks';
import { useInstallmentsModal } from 'stores/installments-modal';
import { ReactComponent as LogoIcon } from 'assets/svg/plait-pay.svg';
import { ReactComponent as BankListIcon } from 'assets/svg/bank-list.svg';
import { ReactComponent as CloseIcon } from './icons/plitka.svg';
import styles from './PaymentInstallmentsModal.module.scss';
import { PartsStatistic } from './PartsStatistic';
import { PlaitBanner } from './PlaitBanner';
import { Accordion } from '../@ui-kit/Accordion';
import { faq, features } from './data';
import { BottomSheetComponent } from '../@ui-kit/BottomSheet';
import { Modal } from '../@ui-kit/modals';

export const PaymentInstallmentsModal = () => {
  const isMobile = useMedia('(max-width: 840px)');
  const [{ isOpen }, { close }] = useInstallmentsModal();

  const onClose = () => {
    close();
  };

  const content = (
    <>
      <div className={styles.header}>
        <LogoIcon className={styles.logo} />
        <button onClick={onClose} className={styles.closeBtn}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.badgeStatus}>
        Для клиентов всех банков <BankListIcon />
      </div>
      <div className={styles.title}>
        {isMobile ? (
          <>
            Разбейте <br /> оплату на части <br /> без переплат
          </>
        ) : (
          <>
            Разбейте оплату <br /> на части без переплат
          </>
        )}
      </div>
      <PartsStatistic />
      <ul className={styles.featuresList}>
        {features.map(({ text, icon }, index) => (
          <li key={index} className={styles.featureListItem}>
            <img alt={'icon'} className={styles.featureIcon} src={icon} />
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          </li>
        ))}
      </ul>
      <div className={styles.accordionWrapper}>
        <Accordion className={styles.accordion} noBorders isClosedAllOnOpenOne>
          <Accordion.Item
            titleClassname={styles.accordionTitle}
            iconClassname={styles.accordionIconContainer}
            iconType={'ARROW'}
            onClick={() => {}}
            title="Подробнее об оплате Плайтом">
            <ul className={styles.accordionDetails}>
              {faq.map(({ question, answer }, index) => (
                <li key={index} className={styles.detailsItem}>
                  <div className={styles.detailsTitle}>{question}</div>
                  <div className={styles.detailsText}>{answer}</div>
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </div>

      <PlaitBanner />
    </>
  );

  return isMobile ? (
    <BottomSheetComponent contentClassname={styles.content} open={isOpen} onDismiss={onClose}>
      {content}
    </BottomSheetComponent>
  ) : (
    <Modal visible={isOpen} overlayClassName={styles.overlay} modalClassName={styles.modal} onClose={onClose}>
      {content}
    </Modal>
  );
};
