import qs from 'qs';
import { checkStatus, getHeaders, markFailedRequest, parseJson, parseJsonList } from './helpers';

const createRequest = (baseUrl, credentials = 'include') => {
  return (path, { method = 'GET', headers, data = {}, query = {} } = {}, dataInList = false) => {
    const queryString = qs.stringify(query);
    // eslint-disable-next-line unicorn/prefer-spread
    const url = baseUrl.concat(path);
    // eslint-disable-next-line unicorn/prefer-spread
    const urlWithQuery = queryString ? [...url, '?'].concat(queryString) : url;

    return (
      fetch(urlWithQuery, {
        method,
        credentials,
        headers: getHeaders(headers),
        ...(method === 'GET' ? null : { body: JSON.stringify(data) }),
      })
        .catch(markFailedRequest)
        // .then(parseJson)
        .then(checkStatus)
        .then(dataInList ? parseJsonList : parseJson)
    );
  };
};

let localSammyBeautyApi;

const initApi = (host, { protocol = 'https', suffix = 'v1' }) => {
  const baseUrl = process.env.NODE_ENV === 'development' ? '/proxy' : `${protocol}://${host}/${suffix}`;
  const request = createRequest(baseUrl);
  // const makeRequest = (...params) => (data) => request(...params, data);
  const createBasketRequest = (method = 'GET') => (data) => {
    return request(`/basket`, { method, ...(Boolean(data) && { data }) });
  };
  const createPromoRequest = (method = 'POST') => (data) => {
    return request(`/basket/promo`, { method, data });
  };

  return {
    newsletterSusbscription: (email) => request('/subscriptions', { method: 'POST', data: { email } }),
    promocode: (data) => request('/promocode', { method: 'POST', data }),
    countries: () => request('/countries'),
    orderDeliveryMethods: ({ city_title, city_fias_id, country_title, country_id }) =>
      request(
        `/delivery-methods?city_title=${city_title}&city_fias_id=${city_fias_id}&country_title=${country_title}${
          country_id ? `&country_id=${country_id}` : ''
        }`,
      ),
    orderPickPoints: ({ fiasId, cityTitle, type, countryTitle }) =>
      request(`/pick-points?city_fias_id=${fiasId}&type=${type}&city_title=${cityTitle}&country_title=${countryTitle}`),
    orderPaymentMethods: ({ delivery_method_id, city_fias_id, city_title, country_title }) =>
      request(`/payment-methods?delivery_method_id=${delivery_method_id}&
                                city_fias_id=${city_fias_id}&
                                city_title=${city_title}&
                                country_title=${country_title}&`),
    orderSubmit: (data) => request('/orders', { method: 'POST', data }),
    getPaymentLink: (id) => request(`/orders/${id}/payment`),
    getAutoPayment: (id) => request(`/orders/${id}/payment_auto`),

    category: (slug) => request(`/categories/${slug}`),
    categoryProducts: (slug, offset = null) =>
      request(`/categories/${slug}/products${offset ? `?skip=${offset}&count=10` : ''}`),
    product: (slug) => request(`/products/${slug}`),
    productDetails: (slug) => request(`/products/${slug}/details`),

    basket: {
      initial: createBasketRequest(),
      addProducts: createBasketRequest('POST'),
      editProducts: createBasketRequest('PUT'),
      deleteProducts: createBasketRequest('DELETE'),
      promo: {
        post: createPromoRequest(),
        edit: createPromoRequest('PUT'),
        delete: createPromoRequest('DELETE'),
      },
    },

    addPromoToBasket: (data) => request(`/basket/promo`, { method: 'POST', data }),
    deletePromoFromBasket: (data) => request(`/basket/promo`, { method: 'DELETE', data }),

    addPromoInBasket: (data) => request(`/basket/promo`, { method: 'POST', data }),
    instagramReviewsResponse: () => request(`/social/instagram-reviews`),
    orderStatus: (number, phone) =>
      request('/order-status', {
        method: 'POST',
        data: { number, phone },
      }),
    specialOffers: (slug) => request(`/offers/${slug}`),
    mainBanner: () => request(`/banners`),
    giftChest: (number) => {
      return request(`/gift-chest?number=${number}`);
    },
    cakeChest: (number) => {
      return request(`/gift-chest?number=${number}`);
    },
    giftChestChoseProduct: (data) => request(`/gift-chest`, { method: 'POST', data }),
    cakeChestChoseProduct: (data) => request(`/gift-chest`, { method: 'POST', data }),
    cakeChestChosePrizeProduct: (data) => request(`/gift-chest/sector-prize`, { method: 'POST', data }),

    exchangeInfo: (number) => request(`/exchange?number=${number}`),
    acceptExchange: (data) => request(`/exchange`, { method: 'POST', data }),

    sendSmsCode: (data) => request(`/gift-send-sms-code`, { method: 'POST', data }),
    validateOrder: (data) => request(`/gift-sms-validate`, { method: 'POST', data }),
    resendSmsCode: (data) => request(`/gift-return-sms-code`, { method: 'POST', data }),

    magnitCities: () => request(`/magnit_remain/city`),
    magnitShops: (data) => request(`/magnit_remain/shop_by_city`, { method: 'POST', data }),
    magnitProducts: (data) => request(`/magnit_remain/products_by_shop`, { method: 'POST', data }),
    globalSettings: () => request(`/config`),
    newYearDesire: (data) => request(`/landing/elka/register`, { method: 'POST', data }),
    page: (slug) => request(`/pages/${slug}`),
    getMenu: () => {
      return request('/menu', { method: 'GET' }, true);
    },
  };
};

const initSammyBeautyApi = (...params) => {
  localSammyBeautyApi = initApi(...params);
};

const sammyBeautyApi = () => localSammyBeautyApi;

export { initSammyBeautyApi, sammyBeautyApi };
