import React from 'react';
import { IconContainer } from '../icon-container/icon-container';
import { IconBasket } from '../icons';
import './basket-header-button.css';

export const BasketHeaderButton = ({ amount = '0', onClick, ...rest }) => (
  <button className="BasketHeaderButton" disabled={!onClick} onClick={onClick} {...rest}>
    <IconContainer>
      <IconBasket />
    </IconContainer>

    <span className="BasketHeaderButton-amount">{amount}</span>
  </button>
);
