import React from 'react';
import cn from 'classnames';
import { useErrorSmoothBehaviour } from './use-error-smooth-behaviour';
import './promocode-field.css';

const TRANSITION_DURATION_MS = 200;

export const PromocodeField = ({ children, error = '' }) => {
  const [{ _error, errorElHeight }, setErrorEl] = useErrorSmoothBehaviour(error, TRANSITION_DURATION_MS);

  return (
    <div
      className={cn('PromocodeField', {
        'PromocodeField--withError': !!error,
      })}
      style={{
        '--error-height': `${errorElHeight}px`,
        transition: `height ${TRANSITION_DURATION_MS}ms ease-in-out`,
      }}>
      <div className="PromocodeField-input">{children}</div>

      {_error && (
        <div className="PromocodeField-error" ref={setErrorEl}>
          {_error}
        </div>
      )}
    </div>
  );
};
