import React from 'react';
import SimpleBar from 'simplebar-react';
import { useMedia } from '../../hooks';
import 'simplebar/dist/simplebar.min.css';
import './custom-scrollbar.css';

export const CustomScrollbar = ({ children, verticalTrackRight = '0', simplebarWidth = '4px' }) => (
  <div
    className="CustomScrollbar"
    style={{ '--vertical-track-right': verticalTrackRight, '--simplebar-width': simplebarWidth }}>
    <SimpleBar>{children}</SimpleBar>
  </div>
);

export const CustomScrollbarWrapper = ({ children, verticalTrackRight = '-20px', simplebarWidth = '4px' }) => {
  const isMobile = useMedia('(max-width: 840px)');

  if (isMobile) {
    return children;
  }

  return (
    <CustomScrollbar simplebarWidth={simplebarWidth} verticalTrackRight={verticalTrackRight}>
      {children}
    </CustomScrollbar>
  );
};
