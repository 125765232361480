import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export type IconContainerSizes = 'xxsmall' | 'xsmall' | 'small' | 'inline' | 'medium' | 'large';

export type TIconContainer = {
  size?: IconContainerSizes;
  className?: string;
  icon?: React.ReactElement;
};

export const IconContainer: React.FC<TIconContainer> = ({ size = 'medium', className = '', children, icon }) => {
  return (
    <div
      className={cn(styles.iconContainer, styles[size], {
        [className]: className,
      })}>
      {icon || children}
    </div>
  );
};
