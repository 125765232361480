import React, { useContext, createContext, useEffect, useReducer, useState } from 'react';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';

// Types
const FETCH = 'SETTINGS/FETCH';
const RESPONSE = 'SETTINGS/RESPONSE';

// actions

const actions = {
  fetch: () => {
    return createAction(FETCH);
  },
  response: ({
    deliveryDetailsInfoVisible,
    cakesIncrease,
    lotteryAvailable,
    textInBox,
    textInModal,
    isInstallmentPaymentActive,
  }) => {
    return createAction(RESPONSE, {
      cakesIncrease,
      deliveryDetailsInfoVisible,
      lotteryAvailable,
      textInBox,
      textInModal,
      isInstallmentPaymentActive,
    });
  },
};

const initialState = {
  deliveryDetailsInfoVisible: false,
  cakesIncrease: null,
  lotteryAvailable: false,
  textInBox: null,
  textInModal: null,
  isInstallmentPaymentActive: null,
};

// reducers

const reducer = (state, { type, payload }) => {
  switch (type) {
    case RESPONSE: {
      return {
        deliveryDetailsInfoVisible: payload.deliveryDetailsInfoVisible,
        cakesIncrease: payload.cakesIncrease,
        lotteryAvailable: payload.lotteryAvailable,
        textInBox: payload.textInBox,
        textInModal: payload.textInModal,
        isInstallmentPaymentActive:
          payload.isInstallmentPaymentActive === null ||
          typeof payload.isInstallmentPaymentActive === 'undefined' ||
          payload.isInstallmentPaymentActive,
      };
    }
    default:
      return state;
  }
};

const useHook = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    deliveryDetailsInfoVisible,
    isInstallmentPaymentActive,
    cakesIncrease,
    lotteryAvailable,
    textInBox,
    textInModal,
  } = state;

  const { response } = bindActionsToDispatch(actions, dispatch);

  useEffect(() => {
    sammyBeautyApi()
      .globalSettings()
      .then((data) => response(data))
      .catch((error) => {
        console.warn('sammyBeautyApi().promotion()', error);
      })
      .finally(() => {
        setIsInitialLoading(false);
      });
  }, []);

  return [
    {
      deliveryDetailsInfoVisible,
      isInstallmentPaymentActive,
      cakesIncrease,
      lotteryAvailable,
      isInitialLoading,
      textInBox,
      textInModal,
    },
    {},
  ];
};

const SettingsContext = createContext();
export const useSettings = () => useContext(SettingsContext);

export const SettingsStore = ({ children }) => (
  <SettingsContext.Provider value={useHook()}>{children}</SettingsContext.Provider>
);

SettingsContext.displayName = 'PromotionContext';
