import { useMemo } from 'react';

export const useBasketToastContentOptions = (type?: 'success' | 'failed') => {
  const [title, info] = useMemo(() => {
    switch (type) {
      case 'success': {
        return [`Добавлено в корзину`, 'Поспешите оформить заказ, товар быстро заканчивается!'];
      }
      case 'failed': {
        return ['Товар не добавлен в корзину', 'Попробуйте позже'];
      }
      default: {
        return [`Добавление в корзину`, 'Поспешите оформить заказ, товар быстро заканчивается!'];
      }
    }
  }, [type]);

  return [title, info];
};
