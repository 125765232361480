const getDeliveryMethodId = (deliveryMethodList, deliveryMethodSlug) =>
  deliveryMethodList.find(({ slug }) => slug === deliveryMethodSlug)?.id;

export const formDeliveryData = (
  {
    deliveryMethodSlug,
    ordererCity,
    ordererStreet,
    ordererHouse,
    ordererApartment,
    ordererPostId,
    ordererRegion,
    pickPointId,
    ordererCountry,
  },
  deliveryMethodList,
) => {
  let data = {
    delivery_method_id: getDeliveryMethodId(deliveryMethodList, deliveryMethodSlug),
  };

  data = {
    ...data,
    orderer: {
      ...data,
      orderer: { ...data.orderer },
      street: ordererStreet,
      ordererHouse,
      ordererCity,
      apartment: ordererApartment,
      ordererPostId,
      ordererCountry,
      ordererRegion,
    },
  };

  data = {
    ...data,
    pick_point_id: pickPointId,
  };

  return data;
};
