import { RefObject } from 'react';

export enum MediaItemType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type TMediaItem = {
  poster?: string;
  sources: string[];
  title: string;
  type: MediaItemType | 'video' | 'image';
};

export type TMedia = {
  media: TMediaItem;
  isAdaptable?: boolean;
  isFullClickable?: boolean;
  ratio?: number;
  imageRef?: RefObject<HTMLImageElement>;
  onLoad?: () => void;
};
