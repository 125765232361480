import React from 'react';
import Highlighter from 'react-highlight-words';
import { Button } from '../button/button';
import { useMedia } from '../../hooks';
import './shops-table.css';

export const ShopsTableRow = ({ shop, searchQuery, onSelect }) => {
  const isMobile = useMedia('(max-width: 960px)');

  const RowActionComponent = isMobile ? 'button' : 'div';

  const renderHightLightText = (text) => (
    <Highlighter
      highlightClassName="PickPointRow-highlight"
      activeClassName="PickPointRow-highlight_active"
      activeIndex={-1}
      searchWords={searchQuery}
      autoEscape={true}
      textToHighlight={text}
    />
  );

  return (
    <RowActionComponent
      className="ShopTable-row"
      onClick={
        isMobile
          ? () => {
              onSelect(shop.codeMh, shop.adressRow);
            }
          : null
      }>
      <div className="ShopTable-rowIndex">{renderHightLightText(`${shop.index}`)}</div>
      <div className="ShopTable-rowAdress">{renderHightLightText(shop.adressRow)}</div>
      {!isMobile && (
        <div className="ShopTable-action">
          <Button
            isPrimary
            title="смотреть наличие"
            onClick={() => {
              onSelect(shop.codeMh, shop.adressRow);
            }}
          />
        </div>
      )}
    </RowActionComponent>
  );
};
