import { useState, useEffect } from 'react';

const checkIsMach = (mediaQuery) => {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.matchMedia(mediaQuery).matches;
};

export const useMedia = (mediaQuery) => {
  const [isMatch, setIsMatch] = useState(checkIsMach(mediaQuery));

  const handleWindowResize = () => {
    setIsMatch(window.matchMedia(mediaQuery).matches);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [mediaQuery]);

  return isMatch;
};
