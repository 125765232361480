import { FC } from 'react';
import { animated, useTransition } from '@react-spring/web';

type OpacityTransitionElementProps = {
  _in: boolean;
  transitionDuration?: number;
};

export const OpacityTransitionElement: FC<OpacityTransitionElementProps> = ({
  children,
  _in,
  transitionDuration = 300,
}) => {
  const transitions = useTransition([_in], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      duration: transitionDuration,
    },
    exitBeforeEnter: true,
  });

  return transitions((styles, isOpened) => isOpened && <animated.div style={styles}>{children}</animated.div>);
};
