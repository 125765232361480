import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { IconQuestion } from '../icons/question/question';
import { plural } from '../../utils';
import cakeAwardsCartImage from '../../data/cake-awards/sammybeauty-in-cart.jpg';

import './cake-awards-card.css';

export const CakeAwardsCard = ({
  cakeCount,
  children,
  withTooltip,
  showImg = true,
  textClassName,
  imageClassName,
  className = '',
  buttonClassName = '',
  inCart,
}) => {
  const [isOpen, setOpen] = useState(false);

  const buttonRef = useRef(null);

  const toggleTooltip = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (withTooltip) {
      const onClick = (e) => buttonRef.current.contains(e.target) || setOpen(false);
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }
  }, []);

  return (
    <div
      className={cn('CakeAwardsCard', {
        [className]: className,
      })}>
      {showImg && (
        <img className={cn('CakeAwardsCard-image', imageClassName)} src={cakeAwardsCartImage} alt={'cake_awards'} />
      )}

      <div
        className={cn(
          'CakeAwardsCard-textContent',
          textClassName,
          inCart ? 'CakeAwardsCard-textContent-inCart' : undefined,
        )}>
        {children}
      </div>

      {withTooltip && (
        <button
          className={cn('CakeAwardsCard-tooltipButton', {
            'CakeAwardsCard-tooltipActive': isOpen,
            [buttonClassName]: buttonClassName,
          })}
          onClick={toggleTooltip}
          ref={buttonRef}>
          <IconQuestion />
        </button>
      )}

      {isOpen && withTooltip && (
        <div className="CakeAwardsCard-tooltip">
          Купив этот товар, Вы получаете {plural(cakeCount, ['попытку', 'попытки', 'попыток'])} в акции «Беспроигрышная
          лотерея». При покупке разных товаров попытки суммируются. Ваши подарки также суммируются!
        </div>
      )}
    </div>
  );
};
