import React, { ReactNode } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './BottomSheet.scss';
import cn from 'classnames';

type BottomSheetComponentProps = {
  children: ReactNode;
  open: boolean;
  onDismiss: () => void;
  contentClassname?: string;
  height?: number;
};

export const BottomSheetComponent: React.FC<BottomSheetComponentProps> = ({
  children,
  open,
  contentClassname = '',
  onDismiss,
  height,
}) => {
  return (
    <BottomSheet
      style={{
        zIndex: 12000,
        position: 'absolute',
      }}
      open={open}
      onDismiss={onDismiss}
      snapPoints={({ maxHeight }) => [maxHeight, height ?? maxHeight]}>
      <div
        className={cn('bottom-sheet__content', {
          [contentClassname]: contentClassname,
        })}>
        {children}
      </div>
    </BottomSheet>
  );
};
