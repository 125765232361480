import React, { useState, useContext, createContext } from 'react';

const useHook = () => {
  const [isInverted, setIsInverted] = useState(false);

  return [
    isInverted,
    {
      invert: () => setIsInverted(true),
      resetInvert: () => setIsInverted(false),
    },
  ];
};

const IsHeaderInvertedContext = createContext();

export const useIsHeaderInverted = () => useContext(IsHeaderInvertedContext);

export const IsHeaderInvertedStore = ({ children }) => (
  <IsHeaderInvertedContext.Provider value={useHook()}>{children}</IsHeaderInvertedContext.Provider>
);
IsHeaderInvertedContext.displayName = 'IsHeaderInvertedContext';
