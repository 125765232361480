import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionsToDispatch, createAction } from '../utils';
import {
  getPersistedValidationOrderNumber,
  getPersistedWonProducts,
  setPersistedPickedForChangeProducts,
  setPersistedWonProducts,
} from '../../utils';
import { exchangeGiftNormalize, exchangeProductsNormalize } from '../normalizers/normalizers';
import { sammyBeautyApi } from '../../service';
import { useCakeChest } from '../cake-chest';

export const CHANGE_GIFTS_STEPS = {
  GIFTS_SELECT: 1,
  PRODUCTS_SELECT: 2,
};

const initialState = {
  currentStep: CHANGE_GIFTS_STEPS.GIFTS_SELECT,
  giftsSelected: {},
  productsSelected: {},
  gifts: [],
  products: [],
  rate: null,
  exchangePossible: false,
  exchangeError: false,
  timerStarted: null,
  timerTime: null,
  orderNumber: null,
  isLoading: false,
  isSuccess: false,
  isOutOfTime: false,
  isExchangeLoading: false,
};

const SET_STEP = 'ORDER_CHANGE_GIFTS/SET_STEP';
const PICK_GIFT = 'ORDER_CHANGE_GIFTS/PICK_GIFT';
const SET_LOADING = 'ORDER_CHANGE_GIFTS/SET_LOADING';
const SET_EXCHANGE_LOADING = 'ORDER_CHANGE_GIFTS/SET_EXCHANGE_LOADING';
const SET_EXCHANGE_ERROR = 'ORDER_CHANGE_GIFTS/SET_EXCHANGE_ERROR';
const CLEAR_EXCHANGE_ERROR = 'ORDER_CHANGE_GIFTS/CLEAR_EXCHANGE_ERROR';
const PICK_PRODUCT = 'ORDER_CHANGE_GIFTS/PICK_PRODUCT';
const SET_PRODUCT_COUNT = 'ORDER_CHANGE_GIFTS/SET_PRODUCT_COUNT';
const SET_DATA = 'ORDER_CHANGE_GIFTS/SET_DATA';
const CLEAR_DATA = 'ORDER_CHANGE_GIFTS/CLEAR_DATA';
const UPDATE_PRODUCTS_WITH_QUANTITIES = 'ORDER_CHANGE_GIFTS/UPDATE_PRODUCTS_WITH_QUANTITIES';
const SET_SUCCESS_STATUS = 'ORDER_CHANGE_GIFTS/SET_SUCCESS_STATUS';
const SET_OUT_OF_TIME = 'ORDER_CHANGE_GIFTS/SET_OUT_OF_TIME';
const RADIO_PICK_PRODUCT = 'ORDER_CHANGE_GIFTS/RADIO_PICK_PRODUCT';

const actions = {
  setStep: ({ step }) => createAction(SET_STEP, { step }),
  pickGift: ({ gift }) => createAction(PICK_GIFT, { gift }),
  pickProduct: ({ product }) => createAction(PICK_PRODUCT, { product }),
  radioPickProduct: ({ product }) => createAction(RADIO_PICK_PRODUCT, { product }),
  setProductCount: ({ count, id }) => createAction(SET_PRODUCT_COUNT, { count, id }),
  setLoading: (status) => createAction(SET_LOADING, { status }),
  setExchangeLoading: (status) => createAction(SET_EXCHANGE_LOADING, { status }),
  setExchangeError: () => createAction(SET_EXCHANGE_ERROR),
  clearExchangeError: () => createAction(CLEAR_EXCHANGE_ERROR),
  setData: ({ gifts, orderNumber, products, exchangePossible, exchangeRate, timerTime, timerStarted }) =>
    createAction(SET_DATA, {
      gifts,
      orderNumber,
      exchangePossible,
      timerStarted,
      timerTime,
      exchangeRate,
      products,
    }),
  setSuccessStatus: () => createAction(SET_SUCCESS_STATUS),
  clearData: () => createAction(CLEAR_DATA),
  updateProductsWithQuantities: (products) =>
    createAction(UPDATE_PRODUCTS_WITH_QUANTITIES, {
      products,
    }),
  setOutOfTime: () => createAction(SET_OUT_OF_TIME),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STEP: {
      return {
        ...state,
        currentStep: payload.step,
      };
    }
    case SET_SUCCESS_STATUS: {
      return {
        ...state,
        isSuccess: true,
      };
    }
    case SET_DATA: {
      return {
        ...state,
        gifts: payload.gifts.map((gift) => exchangeGiftNormalize(gift)) ?? [],
        products: payload.products.map((product) => exchangeProductsNormalize(product)) ?? [],
        rate: payload.exchangeRate,
        exchangePossible: payload.exchangePossible,
        timerTime: payload.timerTime,
        orderNumber: payload.orderNumber,
        timerStarted: payload.timerStarted,
      };
    }
    case SET_OUT_OF_TIME: {
      return {
        ...state,
        isOutOfTime: true,
      };
    }
    case UPDATE_PRODUCTS_WITH_QUANTITIES: {
      const productsSelected = { ...state.productsSelected };
      const newProducts = [...state.products];

      for (const product of payload.products) {
        const productsIndex = state.products.findIndex((el) => el.id === product.id);

        if (product.quantity === 0) {
          delete productsSelected[product.id];
        }

        if (product.maximum_quantity) {
          productsSelected[product.id] = {
            ...productsSelected[product.id],
            count: product.quantity,
          };
        }

        if (productsIndex >= 0) {
          newProducts[productsIndex] = {
            ...newProducts[productsIndex],
            quantity: product.quantity,
            maximumQuantity: product.quantity,
          };
        }
      }

      return {
        ...state,
        productsSelected,
        products: newProducts,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: payload.status,
      };
    }
    case SET_EXCHANGE_LOADING: {
      return {
        ...state,
        isExchangeLoading: payload.status,
      };
    }
    case SET_EXCHANGE_ERROR: {
      return {
        ...state,
        exchangeError: true,
      };
    }
    case CLEAR_EXCHANGE_ERROR: {
      return {
        ...state,
        exchangeError: false,
      };
    }
    case RADIO_PICK_PRODUCT: {
      const { product } = payload;

      const newProductsSelected = {
        [product.id]: {
          count: 1,
          product,
        },
      };

      return {
        ...state,
        productsSelected: newProductsSelected,
      };
    }
    case PICK_PRODUCT: {
      const { product } = payload;
      const newProductsSelected = { ...state.productsSelected };

      if (newProductsSelected[product.id]) {
        delete newProductsSelected[product.id];
      } else {
        newProductsSelected[product.id] = { product, count: 1 };
      }

      return {
        ...state,
        productsSelected: {
          ...newProductsSelected,
        },
      };
    }
    case SET_PRODUCT_COUNT: {
      const { id, count } = payload;

      const newCountSelected = { ...state.productsSelected };

      if (newCountSelected[id]) {
        newCountSelected[id] = {
          ...newCountSelected[id],
          count,
        };

        return {
          ...state,
          productsSelected: {
            ...newCountSelected,
          },
        };
      }

      return state;
    }
    case PICK_GIFT: {
      const { gift } = payload;
      const newSelected = { ...state.giftsSelected };

      if (newSelected[gift.id]) {
        delete newSelected[gift.id];
      } else {
        newSelected[gift.id] = gift;
      }

      const oldAvailableRate = Object.keys(state.giftsSelected).length;
      const newAvailableRate = Object.keys(newSelected).length;

      return {
        ...state,
        productsSelected: oldAvailableRate > newAvailableRate ? {} : state.productsSelected,
        giftsSelected: {
          ...newSelected,
        },
      };
    }
    case CLEAR_DATA: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

const getAwardsPath = (orderNumber) => `/cake-awards?order_number=${orderNumber}&no_empty_reload=true`;
const getThanksPath = (orderNumber) => `/order-thanks?order_number=${orderNumber}&no_empty_reload=true`;

const useHook = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, { setNewWon, setPickedForChangeProducts }] = useCakeChest();
  const history = useHistory();
  const [
    {
      isLoading,
      exchangeError,
      orderNumber,
      currentStep,
      giftsSelected,
      exchangePossible,
      rate,
      productsSelected,
      gifts,
      products,
      isSuccess,
      timerStarted,
      timerTime,
      isOutOfTime,
      isExchangeLoading,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const {
    setStep,
    pickGift,
    pickProduct,
    setProductCount,
    setData,
    setLoading,
    setExchangeLoading,
    clearData,
    updateProductsWithQuantities,
    setExchangeError,
    setSuccessStatus,
    setOutOfTime,
    clearExchangeError,
    radioPickProduct,
  } = bindActionsToDispatch(actions, dispatch);

  const giftsExchangeCount = useMemo(() => gifts.filter((gift) => gift.exchangePossible).length, [gifts]);
  const giftsSelectedCount = useMemo(() => Object.keys(giftsSelected).length, [giftsSelected]);
  const productsSelectedCount = useMemo(() => Object.values(productsSelected).reduce((acc, el) => acc + el.count, 0), [
    productsSelected,
  ]);

  const goProductsSelect = () => {
    setStep({ step: CHANGE_GIFTS_STEPS.PRODUCTS_SELECT });
  };

  const goGiftsSelect = () => {
    setStep({ step: CHANGE_GIFTS_STEPS.GIFTS_SELECT });
  };

  const onPickGift = (product) => {
    if (giftsSelectedCount <= giftsExchangeCount) {
      pickGift({
        gift: product,
      });
    }
  };

  const onPickProduct = (product) => {
    pickProduct({
      product,
    });
  };

  const onSetProductCount = ({ id, count }) => {
    setProductCount({
      id,
      count,
    });
  };

  const requestData = async (number) => {
    setLoading(true);
    try {
      const requestOrderNumber = number || getPersistedValidationOrderNumber();

      const fixedRequestOrderNumber =
        typeof requestOrderNumber === 'string' && number.startsWith('№') ? number.slice(1) : number;

      const response = await sammyBeautyApi().exchangeInfo(fixedRequestOrderNumber);

      if (response) {
        setData({
          orderNumber: fixedRequestOrderNumber,
          gifts: response.gift_list ?? [],
          products: response.goods_list ?? [],
          exchangeRate: response.exchange_settings.exchange_rate,
          exchangePossible: response.exchange_possible,
          timerTime: response.exchange_settings.timer_time,
          timerStarted: response.exchange_settings.timer_started,
        });
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const radioPick = async (product) => {
    const available = giftsSelectedCount / rate;

    if (available !== 1 || productsSelected[product.id]) return;

    radioPickProduct({ product });
  };

  const submitExchange = async () => {
    setExchangeLoading(true);

    try {
      await sammyBeautyApi().acceptExchange({
        number: orderNumber,
        gift_list: Object.values(giftsSelected).map((gift) => ({
          id: gift.id,
          product_id: gift.productId,
          replaceable: true,
        })),
        goods_list: Object.values(productsSelected).map((product) => ({
          id: product.product.id,
          quantity: product.count,
        })),
      });

      const newPersistProducts = getPersistedWonProducts().filter((product) => !giftsSelected[product.id]);
      const selectedProducts = Object.values(productsSelected);

      setSuccessStatus();
      setNewWon(newPersistProducts);
      setPickedForChangeProducts(selectedProducts);

      // update storage data for awards page
      setPersistedWonProducts(newPersistProducts);
      setPersistedPickedForChangeProducts(selectedProducts);

      history.push(getAwardsPath(orderNumber));
    } catch (error) {
      if (error.response.status === 403) {
        setSuccessStatus();
        history.push(getAwardsPath(orderNumber));
        return;
      }

      if (error.response.status === 400) {
        updateProductsWithQuantities(error.response.data.goods_list);
      } else {
        setExchangeError(true);
      }

      throw new Error('exchange error');
    } finally {
      setExchangeLoading(false);
    }
  };

  const onTimerComplete = () => {
    setOutOfTime();
    history.push(getThanksPath(orderNumber));
  };

  return [
    {
      isOutOfTime,
      currentStep,
      giftsSelected,
      productsSelected,
      giftsSelectedCount,
      productsSelectedCount,
      exchangePossible,
      exchangeError,
      gifts,
      orderNumber,
      products,
      giftsExchangeCount,
      timerStarted,
      timerTime,
      rate,
      isLoading,
      isSuccess,
      isExchangeLoading,
    },
    {
      goProductsSelect,
      goGiftsSelect,
      onPickProduct,
      onSetProductCount,
      onPickGift,
      requestData,
      submitExchange,
      clearData,
      setOutOfTime,
      clearExchangeError,
      onTimerComplete,
      radioPick,
    },
  ];
};

const OrderChangeGiftsContext = createContext();

export const useOrderChangeGifts = () => useContext(OrderChangeGiftsContext);

export const OrderChangeGiftsStore = ({ children }) => (
  <OrderChangeGiftsContext.Provider value={useHook()}>{children}</OrderChangeGiftsContext.Provider>
);

OrderChangeGiftsContext.displayName = 'OrderChangeGiftsContext';
