import { useCallback, useLayoutEffect, useState } from 'react';

export function useElSize() {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [size, setSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,

      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useLayoutEffect(() => {
    document.addEventListener('resize', handleSize);

    return () => {
      document.removeEventListener('resize', handleSize);
    };
  }, []);

  useLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}
