import React, { useState } from 'react';
import { Form } from '../form/form';
import { Input } from '../input/input';
import { Button } from '../button/button';
import { useAvailabilityInformInfo } from '../../stores';
import { requestTo } from '../../api/makeRequest';
import { strictEmailRegExp } from '../../utils/email-reg-exp';
import { personalPolicyLink, WhiteSpace } from '../../utils/constants';

export const InformAvailabilitySubscribeView = () => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState('');

  const [{ id }, { setAlreadySubscribedViewType, setSuccessSubscribedViewType }] = useAvailabilityInformInfo();

  const handleChange = (e) => {
    setEmail(e);
    setIsEmailValid(strictEmailRegExp.test(e));
  };

  const handleSubmit = () => {
    requestTo('subscriptions/product')(null, {
      method: 'post',
      data: { email, productSlug: id, cid: null },
    })
      .then(({ data }) => {
        if (data.status === 200) setSuccessSubscribedViewType();
      })
      .catch((error) => {
        const { message } = error.response.data;
        switch (message) {
          case 'Товар уже находится в списке ожидания':
            return setAlreadySubscribedViewType();
          default:
            setIsEmailValid(false);
        }
      });
  };

  return (
    <div className="InformAvalability-wrapper">
      <div className="InformAvalability-container">
        <h2 className="InformAvalability-title">
          Сообщить о<WhiteSpace />
          наличии
        </h2>
        <p className="InformAvalability-description">
          Оставьте свою почту, и<WhiteSpace />
          мы
          <WhiteSpace />
          сообщим как
          <WhiteSpace />
          только товар появится в<WhiteSpace />
          наличии
        </p>
        <Form onSubmit={handleSubmit}>
          <Input
            name="email"
            type="email"
            isExpanded
            hint={email && !isEmailValid ? 'Поле заполнено некорректно' : ''}
            isError={email && !isEmailValid}
            onChange={handleChange}
            placeholderDefault={'E-mail *'}
            value={email}
          />
          <Button
            title="Сообщить"
            type="submit"
            isDisabled={(email && !isEmailValid) || !email}
            isExpanded
            variant="violetDark"
          />
        </Form>
        <p className="InformAvalability-privacyPolicy">
          Нажимая на кнопку “отправить” в<WhiteSpace />
          соглашаетесь с{' '}
          <a className="InformAvalability-privacyPolicyLink" href={personalPolicyLink}>
            обработкой ваших персональных данных
          </a>
        </p>
      </div>
    </div>
  );
};
