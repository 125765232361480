import React, { createContext, useContext, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useOrderDeliveryMethods } from './order-delivery-methods';
import { useOrderPickPoints } from './order-pick-points';
import { useOrderPaymentMethods } from './order-payment-methods';
import { useActiveOrderStep } from './active-order-step';
import {
  checkIsCourierMethod,
  checkIsPostMethod,
  checkIsWorldwideCourierMethod,
  checkIsPVZMethod,
  checkIsPickPointMethod,
} from '../../utils';
import { formDeliveryData, checkIsSubmitButtonDisabled } from './helpers';
import { choose } from '../../utils/address';

const initialValues = {
  deliveryMethodSlug: '',
  ordererCountry: {},
  ordererCity: '',
  ordererStreet: '',
  ordererHouse: '',
  ordererApartment: '',
  ordererPostId: '',
  ordererRegion: '',
  pickPointId: '',
};

const getPriceNumber = (deliveryMethodSlug, deliveryMethodList, values, pickPointId, pickpointsOptions) => {
  const { list: pickpointList, isLoading: pickpointListIsLoading, isError: pickpointIsError } = pickpointsOptions;
  const choicenDeliveryMethod = deliveryMethodList.find(({ slug }) => slug === deliveryMethodSlug);

  const hasPickPoints = choicenDeliveryMethod?.pickPointRequired;
  if (hasPickPoints) {
    const pickPointPrice = pickpointList.find(({ id }) => id === pickPointId)?.price?.default;

    if (pickpointListIsLoading || pickpointIsError) {
      return null;
    }

    return pickPointPrice || null;
  }

  return choicenDeliveryMethod?.price?.current || null;
};

const useHook = () => {
  const [isPickPointsRequested, setIsPickPointRequested] = useState(false);
  const [, { goToNextStep }] = useActiveOrderStep();

  const [
    { list: deliveryMethodList },
    { request: requestDeliveryMethods, reset: resetDeliveryMethods },
  ] = useOrderDeliveryMethods();

  const [pickpointsOptions, { request: requestPickPoints }] = useOrderPickPoints();
  const { requestPromise: requestPaymentMethods } = useOrderPaymentMethods();

  const validationSchema = object({
    ordererCountry: object().required('Выберите страну'),
    ordererCity: string().required('Это поле является обязательным'),
    deliveryMethodSlug: string().required('Выберите способ доставки'),
    ordererHouse: string().required('Укажите номер дома'),
    ordererStreet: string().required('Укажите улицу'),
    ordererRegion: string(),
    ordererPostId: string().required('Укажите почтовый индекс'),
    ordererApartment: string(),
    pickPointId: string().required('Выберите пункт выдачи'),
  });

  const { values, errors, touched, handleChange, handleSubmit, setTouched, setFieldValue, resetForm } = useFormik({
    initialValues: JSON.parse(localStorage.getItem('DeliveryInitialState')) || initialValues,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: handleGoToNextStep,
  });
  useEffect(() => {
    localStorage.setItem('DeliveryInitialState', JSON.stringify(values));
  }, [values]);

  useEffect(() => {
    if (deliveryMethodList.length > 0 && !isPickPointsRequested) {
      const currentMethod = deliveryMethodList.find((method) => method.slug === values.deliveryMethodSlug);
      if (currentMethod?.pickPointType) {
        setIsPickPointRequested(true);
        setFieldValue('pickPointId', '');

        const { fias_id, city, country } = choose(values.ordererCity.data, values.ordererCity?.data?.noSNG_value);

        requestPickPoints({
          fiasId: fias_id,
          cityTitle: city,
          type: currentMethod.pickPointType,
          countryTitle: country,
        });
      }
    }
  }, [deliveryMethodList, isPickPointsRequested]);

  const getCity = () =>
    values?.ordererCity?.data?.city || values?.ordererCity?.data?.settlement || values?.ordererCity?.noSNG_value?.query;

  function handleGoToNextStep() {
    const paymentData = {
      delivery_method_id: values?.deliveryMethodSlug,
      city_fias_id: values?.ordererCity?.data?.fias_id,
      city_title: values?.ordererCity?.data?.city || values?.ordererCity?.noSNG_value?.query,
      country_title: values?.ordererCity?.data?.country || values?.ordererCity?.noSNG_value?.countryOptions.country,
      order_region: values?.ordererRegion,
      order_index: values?.ordererPostId,
    };

    requestPaymentMethods(paymentData).then(() => {
      setTouched({});
      goToNextStep();
    });
  }

  const switchDeliveryMethod = (value) => {
    const currentMethod = deliveryMethodList.find((method) => method.slug === value);
    const notPickPoint =
      checkIsCourierMethod(value) || checkIsPostMethod(value) || checkIsWorldwideCourierMethod(value);

    if (currentMethod.pickPointType) {
      const { fias_id, city, country } = choose(values.ordererCity.data, values.ordererCity?.data?.noSNG_value);

      requestPickPoints({
        fiasId: fias_id,
        cityTitle: city,
        type: currentMethod.pickPointType,
        countryTitle: country,
      });
    }

    setFieldValue('ordererStreet', notPickPoint ? '' : '-');
    setFieldValue('ordererPostId', notPickPoint ? '' : '-');
    setFieldValue('ordererHouse', notPickPoint ? '' : '-');
    setFieldValue('ordererApartment', notPickPoint ? '' : '-');
    setFieldValue('pickPointId', notPickPoint ? '-' : '');
  };

  const localHandleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ordererStreet') {
      setFieldValue('ordererStreet', value);
      setFieldValue('ordererHouse', '');
    }

    if (name === 'ordererPostId') {
      setFieldValue('ordererPostId', value);
    }
    if (name === 'ordererRegion') {
      setFieldValue('ordererRegion', value);
    }

    if (name === 'ordererHouse') {
      setFieldValue('ordererHouse', value);
    }

    if (name === 'deliveryMethodSlug') {
      switchDeliveryMethod(value);
    }

    if (name === 'ordererCountry') {
      setFieldValue('ordererCountry', value);
      setFieldValue('ordererCity', '');
      setFieldValue('ordererHouse', '');
      setFieldValue('ordererApartment', '');
      setFieldValue('ordererPostId', '');
      setFieldValue('pickPointId', '');
      setFieldValue('deliveryMethodSlug', '');
      resetDeliveryMethods();

      window.localStorage.removeItem('ordererApartment');
      window.localStorage.removeItem('ordererPostId');
    }
    if (name === 'ordererCity') {
      setFieldValue('deliveryMethodSlug', '');
      setFieldValue('pickPointId', '');
      setFieldValue('ordererCity', value);
      requestDeliveryMethods(value);
    }

    handleChange(e);
  };

  const resetData = () => resetForm({ values: initialValues, errors: {}, touched: {} });

  const foreignCountriesWithLatin = ['Киргизия'];

  const selectPickPoint = useCallback((id) => setFieldValue('pickPointId', id), []);

  return [
    {
      priceNumber: getPriceNumber(
        values.deliveryMethodSlug,
        deliveryMethodList,
        values,
        values.pickPointId,
        pickpointsOptions,
      ),
      data: formDeliveryData(values, deliveryMethodList),
      values,
      errors,
      touched,
      isButtonDisabled: checkIsSubmitButtonDisabled(errors, touched, values),
      isCourierSelected: checkIsCourierMethod(values.deliveryMethodSlug),
      isWorldwideCourierSelected: checkIsWorldwideCourierMethod(values.deliveryMethodSlug),
      isPostSelected: checkIsPostMethod(values.deliveryMethodSlug),
      isPickPointSelected: checkIsPickPointMethod(values.deliveryMethodSlug),
      isPVZSelected: checkIsPVZMethod(values.deliveryMethodSlug),
      foreignCountriesWithLatin,
    },
    {
      handleChange: localHandleChange,
      handleSubmit,
      resetData,
      getCity,
      selectPickPoint,
    },
  ];
};

const OrderDeliveryDataContext = createContext();

export const useOrderDeliveryData = () => useContext(OrderDeliveryDataContext);

export const OrderDeliveryDataStore = ({ children }) => (
  <OrderDeliveryDataContext.Provider value={useHook()}>{children}</OrderDeliveryDataContext.Provider>
);

OrderDeliveryDataContext.displayName = 'OrderDeliveryDataContext';
