import React from 'react';
import './sad-face-icon.css';

export const IconSadFace = () => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="SadFaceIcon">
    <path
      d="M42.6866 7.3158C32.9336 -2.43821 17.0655 -2.43821 7.31433 7.31344C-2.43874 17.0651 -2.43779 32.9336 7.31527 42.6871C17.0655 52.4383 32.9336 52.4383 42.6857 42.6853C52.4378 32.9336 52.4373 17.066 42.6866 7.3158ZM39.9575 39.958C31.7102 48.2053 18.2903 48.2062 10.0425 39.959C1.79334 31.7102 1.79428 18.2889 10.0425 10.0416C18.2898 1.79481 31.7092 1.79387 39.9584 10.0426C48.2057 18.2899 48.2048 31.7112 39.9575 39.958ZM36.3086 34.8868C36.6188 35.6038 36.2888 36.4359 35.5722 36.7456C34.8551 37.0568 34.023 36.7258 33.7128 36.0093C32.349 32.8558 29.1258 30.8178 25.5 30.8178C21.7907 30.8178 18.5477 32.8539 17.2395 36.0055C17.0136 36.5495 16.4871 36.8781 15.9331 36.8781C15.7521 36.8781 15.5687 36.8432 15.391 36.7697C14.6697 36.4699 14.3279 35.642 14.6272 34.9212C16.3763 30.7103 20.6446 27.9897 25.5 27.9897C30.2539 27.9897 34.4968 30.6967 36.3086 34.8868ZM15.6705 18.231C15.6705 16.6168 16.9797 15.3076 18.5939 15.3076C20.2076 15.3076 21.5168 16.6163 21.5168 18.231C21.5168 19.8461 20.2076 21.1548 18.5939 21.1548C16.9797 21.1548 15.6705 19.8461 15.6705 18.231ZM28.9259 18.231C28.9259 16.6168 30.236 15.3076 31.8502 15.3076C33.4639 15.3076 34.7731 16.6163 34.7731 18.231C34.7731 19.8461 33.4644 21.1548 31.8502 21.1548C30.236 21.1548 28.9259 19.8461 28.9259 18.231Z"
      fill="#CB6075"
    />
  </svg>
);
