import React, { useReducer, useContext, createContext } from 'react';
import { bindActionsToDispatch, createAction, checkIsLoading, checkIsReady, checkIsError } from '../utils';
import { sammyBeautyApi } from '../../service';
import { loadingStatesData } from '../../data';
import { choose } from '../../utils/address';

const pickPointProps = {
  'DELIVERY_METHOD/IML': {
    pickPointType: 'PICK_POINT_TYPE/IML',
    pickPointRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/PICKPOINT': {
    id: 'DELIVERY_METHOD/PICKPOINT',
    pickPointType: 'PICK_POINT_TYPE/PICK_POINT',
    pickPointRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/COURIER': {
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/POSTDATA': {
    fullNameRequired: true,
    addressRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/POSTDATA_ADDRESS': {
    fullNameRequired: true,
    addressRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN': {
    name: 'DELIVERY_METHOD/DELIVERY_TO_KAZAKHSTAN',
    description: 'Доставка в крупнейшие города Казахстана',
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: true,
  },
  'DELIVERY_METHOD/DELIVERY_TO_BELARUS': {
    name: 'DELIVERY_METHOD/DELIVERY_TO_BELARUS',
    fullNameRequired: true,
    addressRequired: true,
    phoneRequired: true,
    isNoDeliveryRussia: true,
  },
  'DELIVERY_METHOD/PPD': {
    description: 'Ты можешь забрать заказ в любом удобном для тебя магазине',
    pickPointType: 'PICK_POINT_TYPE/PPD',
    pickPointRequired: true,
    phoneRequired: true,
    fullNameRequired: true,
    isNoDeliveryRussia: false,
  },
  'DELIVERY_METHOD/PVZ': {
    description: 'Ты можешь забрать заказ в любом удобном для тебя магазине',
    pickPointType: 'PICK_POINT_TYPE/ALL',
    pickPointRequired: true,
    phoneRequired: true,
    fullNameRequired: true,
    isNoDeliveryRussia: false,
  },
};

const deserializeMethod = ({ id, title, name, price }) => {
  const additionalProps = pickPointProps[name];
  return {
    title,
    slug: name,
    price: { current: price.default },
    ...additionalProps,
    id: `${id}`,
  };
};

const deserializeList = (list) => list.map((element) => deserializeMethod(element));

const START_LOAD = 'ORDER_DELIVERY_METHODS/START_LOAD';
const END_LOAD = 'ORDER_DELIVERY_METHODS/END_LOAD';
const RESET = 'ORDER_DELIVERY_METHODS/RESET';

const actions = {
  startLoad: () => createAction(START_LOAD),
  endLoad: ({ error, list = [] }) => createAction(END_LOAD, { error, list }),
  reset: () => createAction(RESET),
};

const initialState = {
  list: [],
  loadingState: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case START_LOAD: {
      return {
        ...state,
        list: [],
        loadingState: loadingStatesData.loading,
      };
    }

    case END_LOAD: {
      const { error, list } = payload;
      return {
        ...state,
        list,
        loadingState: error ? loadingStatesData.error : loadingStatesData.ready,
      };
    }
    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

const useHook = () => {
  const [{ list, loadingState }, dispatch] = useReducer(reducer, initialState);

  const { startLoad, endLoad, reset } = bindActionsToDispatch(actions, dispatch);

  const request = (props) => {
    const { data, noSNG_value } = props;
    if (!data && !noSNG_value?.query) {
      return;
    }

    const { city: cityTitle, fias_id: cityFiasId, country: countryTitle, country_id } = choose(data, noSNG_value);

    if (!cityTitle) {
      return;
    }

    const deliveryMethodsData = {
      city_title: cityTitle,
      city_fias_id: cityFiasId,
      country_title: countryTitle,
      ...(Boolean(country_id) && { country_id }),
    };
    startLoad();

    sammyBeautyApi()
      .orderDeliveryMethods(deliveryMethodsData)
      .then((deliveryMethodsList) => endLoad({ list: deserializeList(deliveryMethodsList) }))
      .catch((error) => endLoad({ error }));
  };

  return [
    {
      list,
      isLoading: checkIsLoading(loadingState),
      isReady: checkIsReady(loadingState),
      isError: checkIsError(loadingState),
      isEmpty: list.length === 0,
    },
    { request, reset },
  ];
};

const OrderDeliveryMethodsContext = createContext();

export const useOrderDeliveryMethods = () => useContext(OrderDeliveryMethodsContext);
export const OrderDeliveryMethodsStore = ({ children }) => (
  <OrderDeliveryMethodsContext.Provider value={useHook()}>{children}</OrderDeliveryMethodsContext.Provider>
);

OrderDeliveryMethodsContext.displayName = 'OrderDeliveryMethodsContext';
