import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useBasket, useModals, usePromocode, useSettings } from 'stores';
import { getOrderLink } from 'routes';
import { PaymentInstallmentsStatus } from 'tsComponents/PaymentInstallmentsStatus/PaymentInstallmentsStatus';
import { BasketHeaderButton } from '../basket-header-button/basket-header-button';
import { RightSideDialog } from '../right-side-dialog/right-side-dialog';
import { BasketProductList } from '../basket-product-list/basket-product-list';
import { Button } from '../button/button';
import { Promocode } from '../promocode/promocode';
import { IconContainer } from '../icon-container/icon-container';
import { Price } from '../price/price';
import { IconCross, IconFlatLoader } from '../icons';
import './basket-right-side-dialog.css';

const Header = ({ productsAmount, onClose, isLoading }) =>
  isLoading ? (
    <div className="BasketRightSideDialog-headerSkeletonWrapper">
      <Skeleton containerClassName="BasketRightSideDialog-skeletonBlock" width={28} height={28} />
      <Skeleton containerClassName="BasketRightSideDialog-skeletonBlock" width={124} height={30} />
      <Skeleton containerClassName="BasketRightSideDialog-skeletonBlock" width={28} height={28} />
    </div>
  ) : (
    <div className="BasketRightSideDialog-header">
      <div className="BasketRightSideDialog-closeButtonHolder">
        <button className="BasketRightSideDialog-closeButton" onClick={onClose}>
          <IconContainer>
            <IconCross />
          </IconContainer>
        </button>
      </div>

      <h2 className="BasketRightSideDialog-title">Корзина</h2>

      <div className="BasketRightSideDialog-amountButton">
        <BasketHeaderButton amount={productsAmount} />
      </div>
    </div>
  );

export const BasketRightSideDialog = ({ onClose, isOpened }) => {
  const history = useHistory();
  const [isToOrderHandle, setIsToOrderHandle] = useState(false);
  const [, { openGifts }] = useModals();

  const [
    { productList, productsAmount, totalPriceNumber, isEmpty, isLoading, cakeCount, isReady, showGiftsDialog },
    { removeProduct, changeProductAmount, updateBasket },
  ] = useBasket();

  const [{ cakesIncrease, isInstallmentPaymentActive }] = useSettings();
  const [
    { selectedGift, inputValue, error, promocode, isDisabled },
    { changeInputValue, submit, cancel },
  ] = usePromocode();

  useEffect(() => {
    if (showGiftsDialog && isOpened) {
      openGifts();
    }
  }, [showGiftsDialog, isOpened]);

  const handleToOrder = async () => {
    try {
      await updateBasket();
      setIsToOrderHandle(true);
    } catch (updateBasketError) {
      console.warn('updateBasketError', updateBasketError.response.message);
    } finally {
      setIsToOrderHandle(false);
    }
  };

  useEffect(() => {
    if (isToOrderHandle && !productList.some((item) => !item.product.is_available)) {
      history.push(getOrderLink());
    }
  }, [productList, isToOrderHandle]);

  useEffect(() => {
    if (isOpened) updateBasket();
  }, [isOpened]);

  if (isEmpty) {
    return (
      <RightSideDialog onClose={onClose} isOpened={isOpened}>
        <div className="BasketRightSideDialog">
          <Header productsAmount={productsAmount} onClose={onClose} />

          <div className="BasketRightSideDialog-emptyMessageHolder">
            <div className="BasketRightSideDialog-emptyMessage">Товаров нет</div>
          </div>
        </div>
      </RightSideDialog>
    );
  }

  return (
    <RightSideDialog onClose={onClose} isOpened={isOpened}>
      <div className="BasketRightSideDialog">
        <Header isLoading={isLoading} productsAmount={productsAmount} onClose={onClose} />

        <div className="BasketRightSideDialog-productListHolder">
          <div className="BasketRightSideDialog-productListPositioner">
            <BasketProductList
              selectedGift={selectedGift}
              cakesIncrease={cakesIncrease}
              productList={productList}
              onRemoveClick={removeProduct}
              onAmountChange={changeProductAmount}
              isLoading={isLoading}
              cakeCount={cakeCount}
            />
          </div>
        </div>

        <div className="BasketRightSideDialog-totalGroup">
          <div className="BasketRightSideDialog-promocode">
            {isLoading ? (
              <Skeleton containerClassName={'BasketRightSideDialog-skeletonBlock'} height={44} />
            ) : (
              <Promocode
                promocode={promocode && promocode[0]}
                inputValue={inputValue}
                onInputValueChange={changeInputValue}
                error={error}
                isDisabled={isDisabled || isLoading}
                onSubmit={submit}
                onCancel={cancel}
              />
            )}
          </div>

          <div className="BasketRightSideDialog-totalRow">
            <div className="BasketRightSideDialog-totalKey">
              Итого:
              {isLoading ? (
                <div className="BasketRightSideDialog-totalKeySkeleton">
                  <Skeleton height={'100%'} />
                </div>
              ) : null}
            </div>

            <div className="BasketRightSideDialog-totalValue">
              <Price number={totalPriceNumber} />
              {isLoading ? (
                <div className="BasketRightSideDialog-totalValueSkeleton">
                  <Skeleton height={'100%'} />
                </div>
              ) : null}
            </div>
          </div>

          {isInstallmentPaymentActive && (
            <div className="BasketRightSideDialog-partsPaymentsRow">
              {isLoading ? (
                <Skeleton containerClassName={'BasketRightSideDialog-skeletonBlock'} width={150} height={16} />
              ) : (
                <PaymentInstallmentsStatus isInfoType price={totalPriceNumber} />
              )}
            </div>
          )}

          <div className="BasketRightSideDialog-orderLink">
            {isLoading ? (
              <Skeleton containerClassName="BasketRightSideDialog-skeletonBlock" height={44} />
            ) : (
              <Button
                title="Оформить заказ"
                onClick={handleToOrder}
                isPrimary
                isExpanded
                isDisabled={isLoading || !isReady}
                appendIcon={isLoading && <IconFlatLoader />}
              />
            )}
          </div>
        </div>
      </div>
    </RightSideDialog>
  );
};
