import React from 'react';
import { Dialog } from '../dialog/dialog';
import { InformAvailabilitySubscribeView } from './inform-availability-subscribe-view';
import { InformAvailabilitySuccessView } from './inform-availability-success-view';
import { useAvailabilityInformInfo } from '../../stores';
import './inform-availability-dialog.css';

export const InformAvailabilityDialog = ({ onClose, ...rest }) => {
  const [{ viewType }] = useAvailabilityInformInfo();

  return (
    <Dialog onClose={onClose} {...rest}>
      {viewType === 'subscribe' ? (
        <InformAvailabilitySubscribeView />
      ) : viewType === 'subscribed' ? (
        <InformAvailabilitySuccessView isNotSubscribed={true} close={onClose} />
      ) : (
        <InformAvailabilitySuccessView isNotSubscribed={false} close={onClose} />
      )}
    </Dialog>
  );
};
