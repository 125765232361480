import cn from 'classnames';
import styles from './PartsStatistic.module.scss';

const parts = [
  {
    date: 'сегодня',
  },
  {
    date: 'через 2 недели',
  },
  {
    date: 'через 4 недели',
  },
  {
    date: 'через 6 недель',
  },
];

export const PartsStatistic = () => {
  return (
    <div className={styles.card}>
      <div className={styles.title}>График платежей</div>

      <div className={styles.parts}>
        {parts.map(({ date }, i) => (
          <div key={date}>
            <div
              className={cn(styles.partStatus, {
                [styles.partStatusActive]: i === 0,
              })}
            />
            <div className={styles.partInfo}>
              <div className={styles.partLabel}>{date}</div>
              <div className={styles.partValue}>25%</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.textParts}>
        <div className={styles.partInfo}>
          <div className={styles.partLabel}>Сегодня</div>
          <div className={styles.partValue}>25%</div>
        </div>
        <div className={styles.partInfo}>
          <div className={styles.partLabel}>Еще 3 платежа раз в 2 недели</div>
          <div className={styles.partValue}>по 25%</div>
        </div>
      </div>
    </div>
  );
};
