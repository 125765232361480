import React, { Component } from 'react';
import { Svg } from '../svg/svg';
import './icon-clock.css';

export class IconClock extends Component {
  render() {
    const size = this.getSize();

    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
      case 'mediumPlus':
      case 'large':
      case 'xlarge':
        return (
          <Svg viewBox="0 0 36 36" className="IconClock">
            <path d="M18 7C11.9 7 7 11.9 7 18s4.9 11 11 11 11-4.9 11-11S24.1 7 18 7zm0 20c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9z" />
            <path d="M18.6 18.2v-5.6c0-.6-.4-1-1-1s-1 .4-1 1V19s0 .1.1.1v.1c0 .1.1.1.1.2l2.8 2.8c.4.4 1 .4 1.4 0s.4-1 0-1.4l-2.4-2.6z" />
          </Svg>
        );
      default:
    }
  }

  getSize() {
    const { size, inline } = this.props;

    if (inline || !size) {
      return 'xsmall';
    }

    return size;
  }
}
