import { Dadata } from '../service/dadata';

function DadataGeocoder() {
  const dadata = new Dadata();

  return {
    geocode: async (address) => {
      const result = await dadata
        .adress(address)
        .then((res) => res)
        .catch((error) => console.log('DadataGeocoder -> error', error));

      const data = result?.suggestions[0]?.data;

      if (!data) return false;

      return [data.geo_lat, data.geo_lon];
    },
  };
}

function NominatimGeocoder() {
  const baseUrl = 'https://nominatim.openstreetmap.org/search';
  const urlParams = { format: 'json', limit: '1' };
  const requestOptions = {};

  return {
    geocode: async (address) => {
      const fetchUrl = `
    ${baseUrl}?q=${encodeURI(address)}&${Object.keys(urlParams)
        .map((param) => `${param}=${urlParams[param]}`)
        .join('&')}`;

      const result = await fetch(fetchUrl, requestOptions)
        .then((res) => res.json())
        .catch((error) => console.log('NominatimGeocoder -> error', error));

      if (!result[0]) return false;

      return [result[0].lat, result[0].lon];
    },
  };
}

function MapboxGeocoder() {
  const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
  const urlParams = {
    access_token: 'pk.eyJ1IjoiendlcnJ1Z2EiLCJhIjoiY2t4N2ZlbzVwMDFndTJ3dDhrOXp4MGZmbCJ9.1gfpN34CtxGvqFURxJxDhA',
    limit: '1',
  };
  const requestOptions = {};

  return {
    geocode: async (address) => {
      const fetchUrl = `
    ${baseUrl}${encodeURI(address)}.json?${Object.keys(urlParams)
        .map((param) => `${param}=${urlParams[param]}`)
        .join('&')}`;

      const result = await fetch(fetchUrl, requestOptions)
        .then((res) => res.json())
        .catch((error) => console.log('MapboxGeocoder -> error', error));

      if (!result?.features[0]) return false;

      return result.features[0].geometry?.coordinates?.reverse();
    },
  };
}

function GeoApifyGeocoder() {
  const baseUrl = 'https://api.geoapify.com/v1/geocode/search';
  const urlParams = {
    format: 'json',
    limit: 1,
    apiKey: '781a513ab9fa4c7f91f6f98e76f1673e',
  };
  const requestOptions = {};

  return {
    geocode: async (address) => {
      const fetchUrl = `
    ${baseUrl}?text=${encodeURI(address)}&${Object.keys(urlParams)
        .map((param) => `${param}=${urlParams[param]}`)
        .join('&')}`;

      const result = await fetch(fetchUrl, requestOptions)
        .then((res) => res.json())
        .catch((error) => console.log('GeoApifyGeocoder -> error', error));

      if (!result?.results[0]) return false;

      return [result.results[0].lat, result.results[0].lon];
    },
  };
}
class Geocoder {
  geocoders = [new DadataGeocoder(), new NominatimGeocoder(), new MapboxGeocoder(), new GeoApifyGeocoder()];

  async geocode(address) {
    const geocoders = this.geocoders.map(({ geocode }) => {
      return new Promise((resolve, reject) => {
        try {
          geocode(address)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
        } catch (error) {
          reject(error);
        }
      });
    });

    if (Promise.any) {
      const result = await Promise.any(geocoders).then((res) => res);
      return result;
    }

    const result = await Promise.allSettled(geocoders).then(
      (res) => res.find(({ status }) => status === 'fulfilled')?.value,
    );

    return result;
  }
}

const memoize = (fn) => {
  const cache = {};
  return async (...rest) => {
    const args = JSON.stringify(rest);

    cache[args] = cache[args] || fn.call(undefined, ...rest);
    return cache[args];
  };
};

const geocoder = new Geocoder();

export const getCoordsByAddress = memoize(async (address) => {
  const result = await geocoder.geocode(address);

  return result;
});
