import React, { useReducer, useContext, createContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';

const CHEST_GIFT_DIALOG_OPEN_RESPONSE = ' CHEST_GIFT/DIALOG_OPEN_RESPONSE';
const CHEST_GIFT_DIALOG_CLOSE = 'CHEST_GIFT/DIALOG_CLOSE';
const CHEST_GIFT_PRODUCT_RESPONSE = 'CHEST_GIFT/PRODUCT_RESPONSE';
const OPEN_PRODUCT_DIALOG = 'CHEST_GIFT/OPEN_PRODUCT_DIALOG';
const CLOSE_PRODUCT_DIALOG = 'CHEST_GIFT/CLOSE_PRODUCT_DIALOG';
const CHEST_GIFT_DIALOG_START_LOADING = 'CHEST_GIFT/START_LOADING';

const actions = {
  chestGiftDialogOpenResponse: (isDialogOpen) => createAction(CHEST_GIFT_DIALOG_OPEN_RESPONSE, { isDialogOpen }),
  closeGiftDialog: () => createAction(CHEST_GIFT_DIALOG_CLOSE),
  chestGiftProductResponse: (giftProducts) => createAction(CHEST_GIFT_PRODUCT_RESPONSE, { giftProducts }),
  openProductDialog: () => createAction(OPEN_PRODUCT_DIALOG),
  closeProductDialog: () => createAction(CLOSE_PRODUCT_DIALOG),
  startLoading: () => createAction(CHEST_GIFT_DIALOG_START_LOADING),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHEST_GIFT_DIALOG_OPEN_RESPONSE: {
      const { isDialogOpen } = payload;

      return {
        ...state,
        isDialogOpen,
      };
    }

    case CHEST_GIFT_DIALOG_CLOSE: {
      return {
        ...state,
        isDialogOpen: false,
      };
    }

    case CHEST_GIFT_PRODUCT_RESPONSE: {
      const { giftProducts } = payload;

      return {
        ...state,
        giftProducts,
        isLoading: false,
      };
    }

    case OPEN_PRODUCT_DIALOG: {
      return {
        ...state,
        isProductDialogOpen: true,
      };
    }

    case CLOSE_PRODUCT_DIALOG: {
      return {
        ...state,
        isProductDialogOpen: false,
      };
    }

    case CHEST_GIFT_DIALOG_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return state;
  }
};

const initialState = {
  isDialogOpen: false,
  giftProducts: null,
  isProductDialogOpen: false,
  isLoading: false,
};

const normaliseData = (data) => {
  if (!data || data.length === 0) {
    return false;
  }

  const prise = data.find((item) => item.isPrise);
  const products = data.filter((item) => !item.isPrise);

  if (!prise || !products) {
    return false;
  }

  return {
    prise,
    products,
  };
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { push } = useHistory();

  const { isDialogOpen, giftProducts, isProductDialogOpen, isLoading } = state;

  const {
    chestGiftDialogOpenResponse,
    closeGiftDialog,
    chestGiftProductResponse,
    openProductDialog,
    closeProductDialog,
    startLoading,
  } = bindActionsToDispatch(actions, dispatch);

  const giftChestRequest = useCallback(
    (number) => {
      sammyBeautyApi()
        .giftChest(number)
        .then((data) => {
          chestGiftDialogOpenResponse(data['chest-gift']);

          if (!data['chest-gift']) {
            push(`/order-thanks?order_number=${number}`);
          }
        })
        .catch(() => {
          closeGiftDialog();
          push(`/order-thanks?order_number=${number}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const giftChestChoseProductRequest = useCallback(
    (productId, orderId) => {
      sammyBeautyApi()
        .giftChestChoseProduct({ 'picked-chest': productId, number: orderId })
        .then((data) => {
          chestGiftProductResponse(normaliseData(data));
        })
        .then(() => {
          setTimeout(() => {
            openProductDialog();
            closeGiftDialog();
          }, 8000);
        })
        .catch(() => {
          closeGiftDialog();
          push(`/order-thanks?order_number=${orderId}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const hardGiftChest = () => {
    chestGiftDialogOpenResponse(true);
  };

  return [
    { isDialogOpen, giftProducts, isProductDialogOpen, isLoading },
    {
      giftChestRequest,
      hardGiftChest,
      closeGiftDialog,
      giftChestChoseProductRequest,
      openProductDialog,
      closeProductDialog,
      startLoading,
    },
  ];
};

const ChestGiftContext = createContext();

export const useChestGift = () => {
  return useContext(ChestGiftContext);
};

export const ChestGiftContextStore = ({ children }) => (
  <ChestGiftContext.Provider value={useHook()}>{children}</ChestGiftContext.Provider>
);

ChestGiftContext.displayName = 'ChestGiftContext';
