import React from 'react';
import { IconContainer } from 'tsComponents/@ui-kit/IconContainer';
import { Logo } from '../../../../components/logo/logo';
import { IconCross } from '../../../../components/icons';
import styles from './styles.module.scss';
import { Container } from '../../../Container';

type TMenuHeader = {
  onCloseClick: () => void;
};

export const MenuHeader: React.FunctionComponent<TMenuHeader> = ({ onCloseClick }) => {
  return (
    <Container>
      <div className={styles.menuHeader}>
        <div className={styles.headerContent}>
          <div className={styles.logo}>
            <Logo />
          </div>

          <div>
            <button className={styles.headerButton} onClick={onCloseClick}>
              <IconContainer icon={<IconCross />} size="inline" />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
