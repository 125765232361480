import BasketIcon from './icons/basket.svg';
import PartsIcon from './icons/parts.svg';
import DeliveryIcon from './icons/delivery.svg';
import MoneyIcon from './icons/money.svg';

export const features = [
  {
    icon: BasketIcon,
    text: 'Добавляйте товары в корзину',
  },
  {
    icon: PartsIcon,
    text: 'Оплачивайте сегодня только<br/><strong>25% картой любого банка</strong>',
  },
  {
    icon: DeliveryIcon,
    text: 'Получайте товар выбранным способом',
  },
  {
    icon: MoneyIcon,
    text: 'Оставшиеся 75% будут списываться с вашей карты по 25% каждые 2 недели',
  },
];

export const faq = [
  {
    question: 'Что такое оплата Плайтом?',
    answer:
      'Это короткая мгновенная беспроцентная рассрочка. Вы оплачиваете сразу только 25% от суммы покупки, а оставшиеся части будут списываться автоматически с привязанной карты раз в 2 недели по графику.',
  },
  {
    question: 'Есть ли переплата?',
    answer: 'Нет. Вы ничего не переплачиваете. Нет никаких скрытых комиссий.',
  },
  {
    question: 'Это кредит?',
    answer: 'Нет, это не кредит. Кредитный договор не оформляется.',
  },
  {
    question: 'Как будут списывать деньги за оставшиеся части?',
    answer:
      'Всё работает также как в сервисах подписки, например, Яндекс.Музыка или ivi. Вы делаете первый платёж, а дальше с вашей карты списываются средства один раз в 2 недели. ',
  },
];
