import { useState } from 'react';

export const useAccordion = (defaultOpened: boolean[], isClosedAllOnOpenOne: boolean) => {
  const [openedIndexList, setOpenedIndexList] = useState<boolean[]>(defaultOpened);

  function open(index: number) {
    setOpenedIndexList((prevOpened) => {
      const updatedIndexList = [...prevOpened];
      updatedIndexList[index] = true;
      return updatedIndexList;
    });
  }

  function close(index: number) {
    setOpenedIndexList((prevOpened) => {
      const updatedIndexList = [...prevOpened];
      updatedIndexList[index] = false;
      return updatedIndexList;
    });
  }

  function closeAll() {
    const updatedIndexList = [...[openedIndexList.length].keys()].map(() => false);
    setOpenedIndexList(updatedIndexList);
  }

  function toggle(index: number) {
    if (openedIndexList[index]) {
      close(index);
      return;
    }

    if (isClosedAllOnOpenOne) {
      closeAll();
    }

    open(index);
  }

  return { openedIndexList, toggle };
};
