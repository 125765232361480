import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { IconContainer } from 'tsComponents/@ui-kit/IconContainer';
import { IconPlay } from '../../../../components/icons';
import styles from './styles.module.scss';
import { TMediaItem } from '../../types';

const ORDER_BY_EXTENSION = ['mp4', 'ovg', 'webm'];

const getExtension = (source: string) => {
  const matched = source.match(/[^\\]*\.(\w+)$/);
  if (matched && matched.length > 0) {
    return matched[1];
  }

  return '';
};

const sortSourcesByExtesion = (sources: string[], orderByExtestion: string[]) => {
  let nSources: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const extension of orderByExtestion) {
    const filtered = sources.filter((source) => getExtension(source) === extension);

    nSources = [...nSources, ...filtered];
  }

  return nSources;
};

export type TMediaVideo = {
  isFullClickable?: boolean;
  media: TMediaItem;
  coverImage?: string;
};

export const MediaVideo: React.FunctionComponent<TMediaVideo> = ({ isFullClickable, media, coverImage }) => {
  const { poster, sources, title } = media;

  const [isPlayed, setIsPlayed] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const sortedSources = sortSourcesByExtesion(sources, ORDER_BY_EXTENSION).map((source) => (
    <source key={source} src={`${source}#t=0.1`} type={`video/${getExtension(source)}`} />
  ));

  return (
    <div className={styles.videoHolder}>
      <button
        className={cn(styles.playButton, {
          [styles.hiddenBtn]: isPlaying,
          [styles.fullClickable]: isFullClickable,
        })}
        onClick={() => {
          if (!isPlayed) {
            setIsPlayed(true);
          }
          videoRef.current?.play();
        }}>
        <span className={styles.playIcon}>
          <IconContainer size="large">
            <IconPlay />
          </IconContainer>
        </span>
      </button>

      {!isPlayed && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${coverImage})`,
            backgroundSize: 'cover',
          }}
        />
      )}

      {title && (
        <div
          className={cn(styles.title, {
            [styles.hiddenTitle]: isPlaying,
          })}>
          {title}
        </div>
      )}

      <video
        className={styles.video}
        ref={videoRef}
        controls={isPlaying}
        poster={poster}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        playsInline
        preload="metadata">
        {sortedSources}
      </video>
    </div>
  );
};
