import React from 'react';
import { IconContainer } from '../icon-container/icon-container';
import { IconPromocode, IconCross } from '../icons';
import './applied-promocode.css';

export const AppliedPromocode = ({ promocodeString, description, onCancel }) => (
  <div className="AppliedPromocode">
    <div className="AppliedPromocode-el">
      <div className="AppliedPromocode-elIcon">
        <IconContainer icon={<IconPromocode />} />
      </div>

      <div className="AppliedPromocode-elText">{promocodeString}</div>
    </div>

    <div className="AppliedPromocode-price">
      {description}
      {/* -&nbsp;
      <Price number={priceNumber} /> */}
    </div>

    <div className="AppliedPromocode-cancelHolder">
      <button className="AppliedPromocode-cancelButton" onClick={onCancel}>
        <IconContainer icon={<IconCross />} size="xxsmall" />
      </button>
    </div>
  </div>
);
