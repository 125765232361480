import React from 'react';
import { Svg } from '../svg/svg';
import './hamburger-menu.css';

export const IconHamburgerMenu = () => (
  <Svg className="IconHamburgerMenu">
    <line className="st0" x1="2" y1="4.8" x2="30" y2="4.8" />
    <line className="st0" x1="2" y1="16" x2="30" y2="16" />
    <line className="st0" x1="2" y1="27.2" x2="30" y2="27.2" />
  </Svg>
);
