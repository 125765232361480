const checkRecipientData = (errors, values) => {
  const localErrors = { ...errors };

  if (values && values.recipientFirstName) {
    delete localErrors.recipientFirstName;
  }

  if (values && values.recipientLastName) {
    delete localErrors.recipientLastName;
  }

  if (values && values.recipientPhone) {
    delete localErrors.recipientPhone;
  }

  return localErrors;
};

export const checkIsSubmitButtonDisabled = (errors, touched, values) => {
  const localErrors = checkRecipientData(errors, values);

  // touched не влияет на выбор метода оплаты
  if (errors.paymentMethod) {
    return true;
  }

  return Object.keys(localErrors).length > 0 && Object.keys(touched).length > 0;
};
