import { AxiosError, AxiosRequestConfig as DefaultAxiosRequestConfig, CancelTokenStatic } from 'axios';
import { EApiAction } from './ApiUrl';
import { TMediaItem } from '../tsComponents/Media';

export type ImageSource = {
  dimensions: {
    width: number;
    height: number;
  };
  fallback: {
    normal: string;
    double: string;
  };
  main: {
    normal: string;
    double: string;
  };
  cover: string;
};

export type Image = {
  id: string;
  type: 'MEDIA_TYPE/IMAGE' | string;
  owner?: number;
  title: string;
  caption: string;
  sources: ImageSource[];
};

export type Partner = {
  id: number;
  title: string;
  image: Partial<Image>;
  image_mobile: Partial<Image>;
  url?: string;
  is_partner: boolean;
};

export type Category = {
  page_title: string;
  banner_desktop: Image | null;
  banner_mobile: Image | null;
};

export interface ApiResponse<TResponse, TError, TResponseHeaders = Record<string, never>> {
  isLoading: boolean;
  response?: TResponse;
  responseHeaders?: TResponseHeaders;
  error?: AxiosError<TError>;
}

export type TOmitDefaultAxiosRequestConfig = Omit<DefaultAxiosRequestConfig, 'transformRequest' | 'transformResponse'>;

export type RequestConfig = TOmitDefaultAxiosRequestConfig & {
  preventAutoRequest?: boolean;
  tokenSource?: CancelTokenStatic;
};

export const ApiRequestMethod: { [k in EApiAction]: 'post' | 'get' } = {
  [EApiAction.BDFileUpload]: 'post',
  [EApiAction.BDFormSend]: 'post',
  [EApiAction.RewardsFetch]: 'get',
  [EApiAction.FaqFetch]: 'get',
  [EApiAction.Partners]: 'get',
};

export type TApiRequestConfigTypes = {
  [EApiAction.BDFileUpload]: FormData;
  [EApiAction.BDFormSend]: {
    last_name: string;
    first_name: string;
    middle_name: string;
    email: string;
    phone: string;
    receipt_number: number;
    receipt_date: string; // дд.мм.гггг
    receipt_file: string; // pathToFile из ответа по запросу BDFileUpload
    agree: boolean;
  };
  [EApiAction.RewardsFetch]: void;
  [EApiAction.FaqFetch]: void;
  [EApiAction.Partners]: void;
};

export type TApiResponseTypes = {
  [EApiAction.BDFileUpload]: {
    data: { url: string };
    status: number;
  };
  [EApiAction.BDFormSend]: {
    data: { id: number }; // сгенерированный номер участника акции;
    status: number;
    message: string; // текст ошибки
  };
  [EApiAction.RewardsFetch]: {
    data: {
      link: string;
      title: string;
    }[];
    status: number;
  };
  [EApiAction.FaqFetch]: {
    data: {
      question: string;
      answer: string;
    }[];
    status: number;
  };
  [EApiAction.Partners]: {
    data: Partner[];
    status: number;
  };
};

export type PageDto = {
  id: number;
  slug: string;
  title: string;
  description: string;
  files: string[];
  page_title: string;
  banner_desktop: Image | null;
  banner_mobile: Image | null;
};

export type ErrorResponse = {
  status: string;
  message: string;
  errors: unknown;
};

export type MenuTypeCode = 'top_menu' | 'bottom_menu';

export type MenuTypeItem = {
  title: string;
  slug: string;
  href: string;
  path: string;
  color: string;
  is_new_window: boolean;
  id: number;
};

export type MenuType = {
  title: string;
  code: MenuTypeCode;
  list: MenuTypeItem[];
};

export type Product = {
  id: number;
  slug: string;
  name: string;
  title: string;
  description: string;
  sku: string;
  media: Array<TMediaItem>;
  isSet: boolean;
};

export type ExchangeGift = {
  id: number;
  name: string;
  picture: string;
  exchange_possible: boolean;
};

export type ExchangeProduct = {
  id: number;
  name: string;
  is_set: boolean;
  picture: string;
  is_available?: boolean;
  quantity?: number;
  maximum_quantity?: boolean;
};

export type ExchangeInfo = {
  exchange_possible: boolean;
  exchange_settings: {
    timer_started: string;
    timer_time: string;
    exchange_rate: number;
    available_for_exchange: boolean;
  };
  gift_list: Array<ExchangeGift>;
  goods_list: Array<ExchangeProduct>;
};
