import React from 'react';
import { Media } from 'tsComponents/Media';
import { Price } from '../price/price';
import { useBasket, useModals } from '../../stores';
import './basket-promo-gift.css';

export const BasketPromoGift = ({ gift, amount }) => {
  const [, { openGifts }] = useModals();
  const [{ promocode }] = useBasket();

  const isChooseGiftAvailable = !!promocode[0]?.options?.find((p) => p.goods.product.id === gift.id);

  return (
    <div className="BasketPromoGift">
      <div className="BasketPromoGift-img">
        <span>Подарок!</span>
        {!!gift.media && gift.media.length > 0 && <Media media={gift.media[0]} />}
      </div>
      <div className="BasketPromoGift-about">
        <div className="BasketPromoGift-title">{gift.title}</div>
        <div className="BasketPromoGift-price">
          <Price number={0} variant={'gift'} />
        </div>
        {isChooseGiftAvailable ? (
          <div className="BasketPromoGift-change" onClick={openGifts}>
            Выбрать другой
          </div>
        ) : null}
        <div className="BasketPromoGift-count">{amount} ШТ.</div>
      </div>
    </div>
  );
};
