import { useEffect } from 'react';
import { useActiveOrderStep } from './active-order-step';

export const useOrderDataResetting = () => {
  const [, { resetActiveStep }] = useActiveOrderStep();

  useEffect(
    () => () => {
      resetActiveStep();
    },
    [],
  );
};
