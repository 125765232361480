import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dadata } from 'service/dadata';
import { replaceInvalidPhoneNumber, setPersistedValidationOrderNumber } from 'utils';
import { sammyBeautyApi } from 'service';
import { getOrderThanksLink } from 'routes';
import { getCID } from 'service/helpers';
import checkIsFunction from 'utils/check-is-function';
import { choose } from 'utils/address';
import { bindActionsToDispatch, createAction, findSubString } from '../utils';
import { useOrderDeliveryData } from './order-delivery-data';
import { useOrderPaymentData } from './order-payment-data';
import { useOrderPaymentMethods } from './order-payment-methods';
import { useOrderPersonalData } from './order-personal-data';
import { useBasket } from '../basket';
import { useDataLayer } from '../data-layer';
import { useSettings } from '../settings';
import { SUBMIT_EVENT_TYPE } from '../constants';

const dadata = new Dadata();

const SET_SUBMITED_ORDER = 'SET_SUBMITED_ORDER';
const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';

const initialState = {
  submitedOrder: {},
  paymentData: {},
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SUBMITED_ORDER: {
      return {
        ...state,
        submitedOrder: payload.order,
      };
    }
    case SET_PAYMENT_DATA: {
      return {
        ...state,
        submitedPayment: payload.payment,
      };
    }
    default:
      return state;
  }
};

const actions = {
  setSubmitedOrder: (data) => createAction(SET_SUBMITED_ORDER, { order: data }),
  setPaymentData: (data) => createAction(SET_PAYMENT_DATA, { payment: data }),
};

const usePersistOrderToLocaleStorage = (
  submitedOrder,
  submitedPayment,
  setSubmitedOrder,
  setPaymentData,
  setOrderNumber,
) => {
  const localStorageKey = '__submitedOrder';

  useEffect(() => {
    const persistedSubmitedOrder = localStorage.getItem(localStorageKey);

    if (!persistedSubmitedOrder) {
      return;
    }

    const parsedSubmitedOrder = JSON.parse(persistedSubmitedOrder);
    setSubmitedOrder(parsedSubmitedOrder.order);
    setPaymentData(parsedSubmitedOrder.payment);
    setOrderNumber(parsedSubmitedOrder.order.number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = JSON.stringify({
      order: submitedOrder,
      payment: submitedPayment,
    });
    localStorage.setItem(localStorageKey, data);
  }, [submitedOrder, submitedPayment]);
};

function checkInvoiceData(invoiceData) {
  if (!invoiceData) {
    return false;
  }

  return (
    invoiceData.publicId &&
    invoiceData.description &&
    invoiceData.amount &&
    invoiceData.currency &&
    invoiceData.email &&
    invoiceData.invoiceId &&
    invoiceData.accountId &&
    invoiceData.skin
  );
}

function getUsedFiasData(deliveryData) {
  return deliveryData.orderer.ordererCity;
}

function createParamsPostForm(action, queryParams) {
  const form = document.createElement('form');
  document.querySelectorAll('body')[0].append(form);
  form.style.display = 'none';
  form.setAttribute('method', 'post');
  form.setAttribute('action', action);

  for (const [k, v] of Object.entries(queryParams)) {
    const inputEl = document.createElement('input');
    inputEl.setAttribute('type', 'hidden');
    inputEl.setAttribute('name', k);
    inputEl.setAttribute('value', v.toString());

    form.append(inputEl);
  }

  return form;
}

const useHook = () => {
  const [{ lotteryAvailable }] = useSettings();
  const [, { updateBasket }] = useBasket();

  const [{ submitedOrder, submitedPayment }, dispatch] = useReducer(reducer, initialState);

  const [, { orderCreateErrorDL, checkout4DL, purchaseDL, paymentSuccessDL, bombPaymentSuccess }] = useDataLayer();

  const { setSubmitedOrder, setPaymentData } = bindActionsToDispatch(actions, dispatch);

  const [orderNumber, setOrderNumber] = useState(null);
  const [isOrderSubmitting, serIsOrderSubmitting] = useState(false);
  const [orderError, setOrderError] = useState({});

  const { push } = useHistory();
  const [, { resetBasket }] = useBasket();
  const [{ values: personal }] = useOrderPersonalData();
  const [{ data: localDelivery }] = useOrderDeliveryData();
  const [{ data: paymentStepData }] = useOrderPaymentData();
  const { list: paymentList } = useOrderPaymentMethods();

  function cloudPaymentsPay(publicId, description, amount, currency, email, invoiceId, accountId, skin) {
    const widget = new window.cp.CloudPayments({ language: 'ru-RU' });

    widget.pay(
      'charge',
      {
        publicId,
        description,
        amount,
        currency,
        email,
        invoiceId,
        accountId,
        skin,
      },
      {
        onSuccess() {
          window.dispatchEvent(new CustomEvent('order_created'));

          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          setPersistedValidationOrderNumber(persistedOrderId);

          if (lotteryAvailable) {
            window.location.href = `/order-result-preloader?order_number=${persistedOrderId}`;
            return;
          }

          window.location.href = `/order-thanks?order_number=${persistedOrderId}`;
        },
        onFail() {
          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          window.location.href = `/payment_wrong?order_number=${persistedOrderId}`;
        },
        onComplete(paymentResult) {
          if (paymentResult.success) {
            purchaseDL(submitedOrder);
            paymentSuccessDL(submitedOrder);
            bombPaymentSuccess();
          }
        },
      },
    );
  }

  function createRequestData() {
    const usedFiasData = getUsedFiasData(localDelivery);
    console.log('createRequestData -> _delivery', localDelivery);
    // eslint-disable-next-line no-underscore-dangle
    const existHouseData = Boolean(localDelivery?.orderer?.ordererHouse?._query);
    // eslint-disable-next-line no-underscore-dangle
    const building = existHouseData ? localDelivery.orderer.ordererHouse._query : '';

    const payment = {
      payment_method_id: +paymentList.find((method) => method.slug === paymentStepData.payment).id,
    };
    const contacts = {
      email: personal.email || '',
      phone: replaceInvalidPhoneNumber(personal.cheburashka) || '',
      first_name: personal.firstName || '',
      last_name: personal.lastName || '',
      cid: getCID('_ga'),
    };

    const { city, fias_id } = choose(usedFiasData.data, localDelivery?.orderer?.ordererCity?.noSNG_value);

    const delivery = {
      delivery_method_id: +localDelivery.delivery_method_id || '',
      geography: {
        id: 0 /* dsdsdsd */,
        country: {
          id: localDelivery?.orderer?.ordererCountry?.id || '',
          title: localDelivery?.orderer?.ordererCountry?.title || '',
        },
        city: {
          id: 0 /* dsdsdsd */,
          title: city,
          fias_id,
          settlementType: usedFiasData?.data?.settlement_type || '',
          settlementTypeFull: usedFiasData?.data?.settlement_type_full || '',
          settlementWithType: usedFiasData?.data?.settlement_with_type || '',
          coordinates: {
            lat: +usedFiasData?.data?.geo_lat || 0,
            lng: +usedFiasData?.data?.geo_lon || 0,
          },
        },
        address: {
          // eslint-disable-next-line no-underscore-dangle
          street_title: usedFiasData?.data?.street || localDelivery?.orderer?.street?._query || '',
          street_type: usedFiasData?.data?.street_type || '',
          street_fias_id: usedFiasData?.data?.street_fias_id || '',
          // building: usedFiasData.data.house || "",
          building,
          apartment: localDelivery?.orderer?.apartment || '',
          postal_code: localDelivery?.orderer?.ordererPostId || '',
          region: localDelivery?.orderer?.ordererRegion || '',
          raw: usedFiasData?.unrestricted_value || '',
        },
        coordinates: {
          lat: +usedFiasData?.data?.geo_lat || 0,
          lng: +usedFiasData?.data?.geo_lon || 0,
        },
      },
      pick_point_id: +localDelivery?.pick_point_id || 0,
      recipient_region: localDelivery?.orderer?.ordererRegion || '',
      recipient_index: localDelivery?.orderer?.ordererPostId || '',
      recipient_full_name: localDelivery?.recipient_full_name || '',
      ...(localDelivery?.recipient_phone && {
        recipient_phone: localDelivery?.recipient_phone ? localDelivery?.recipient_phone : '',
      }),
      data: usedFiasData,
    };

    return {
      status: 'string',
      contacts,
      delivery,
      payment,
      comment: paymentStepData.comment,
      meta: {},
    };
  }

  function fetchPaymentLink(id) {
    localStorage.setItem('__persisted_order_id', id);

    if (process.env.REACT_APP_ENABLED_AUTO_PAYMENT === 'true') {
      serIsOrderSubmitting(true);
      return sammyBeautyApi()
        .getAutoPayment(id)
        .then((data) => {
          setPaymentData(data);
          return data;
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent('order_created'));

          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          setPersistedValidationOrderNumber(persistedOrderId);
          window.location.href = `/order-result-preloader?order_number=${persistedOrderId}`;
        })
        .catch((error) => {
          console.warn('getAutoPayment', error);
          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          window.location.href = `/payment_wrong?order_number=${persistedOrderId}`;
        });
    }

    serIsOrderSubmitting(true);
    const promise = sammyBeautyApi()
      .getPaymentLink(id)
      .then((data) => {
        setPaymentData(data);
        return data;
      })
      .then((data) => {
        console.log(`payment-link data: %c${JSON.stringify(data)}`, 'font-weight: bold;font-size: large; color:green');
        return data;
      })
      .then((data) => {
        if (data.name === 'PAYMENT_METHOD/SOVCOMBANK') {
          window.dispatchEvent(new CustomEvent('order_created'));

          const params = Object.entries(data.redirect.options).reduce((obj, [k, v]) => {
            if (k && v) {
              // eslint-disable-next-line no-param-reassign
              obj[k] = v;
            }

            return obj;
          }, {});

          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          setPersistedValidationOrderNumber(persistedOrderId);
          createParamsPostForm(data.redirect.action, params).submit();
          return;
        }

        if (checkInvoiceData(data.invoice_data)) {
          const { publicId, description, amount, currency, email, invoiceId, accountId, skin } = data.invoice_data;
          cloudPaymentsPay(publicId, description, amount, currency, email, invoiceId, accountId, skin);
          return;
        }

        if (data.invoice_link) {
          window.dispatchEvent(new CustomEvent('order_created'));

          const persistedOrderId = localStorage.getItem('__persisted_order_id');
          setPersistedValidationOrderNumber(persistedOrderId);
          window.location.replace(data.invoice_link);
        }
      })
      .catch((error) => {
        console.warn('fetchPaymentLink', error);
        console.log(`payment-link error: %c${JSON.stringify(error)}`, 'font-weight: bold;font-size: large; color:red');
      });

    return promise;
  }

  function manageRedirect(data) {
    const isOnlinePayment = data.payment.payment_method.is_online;

    if (isOnlinePayment) {
      fetchPaymentLink(data.number);
    } else {
      // replace(getOrderThanksLink())
      push({ pathname: getOrderThanksLink(), state: { canEnter: true } });
      resetBasket();
    }

    return data;
  }

  const submitRequest = (requestData) => {
    sammyBeautyApi()
      .orderSubmit(requestData)
      .then((data) => {
        // purchaseDL(data)
        checkout4DL(data);
        setOrderNumber(data.number);
        setSubmitedOrder(data);
        serIsOrderSubmitting(false);
        return data;
      })
      .then(manageRedirect)
      // .then(persistOrderToLacalStorage)
      .catch((error) => {
        serIsOrderSubmitting(false);
        console.warn(error.response.message);
        updateBasket();
        setOrderError(error.response);
        if (checkIsFunction(orderCreateErrorDL)) orderCreateErrorDL();
      });
  };

  const submit = () => {
    const requestData = createRequestData();
    serIsOrderSubmitting(true);
    if (requestData?.delivery?.data?.data && typeof requestData?.delivery?.data?.data === 'object') {
      const addressQuery = `${requestData.delivery.data.unrestricted_value}, кв ${requestData.delivery.geography.address.apartment}`;
      dadata.adress(addressQuery).then((data) => {
        const formattedStreetTitle = requestData.delivery.geography.address.street_title.replace('ул', '');
        const foundSuggestion = data.suggestions.find((el) => findSubString(el.value, formattedStreetTitle));

        if (foundSuggestion) {
          requestData.delivery.data = foundSuggestion;
        } else {
          const {
            region,
            street_type,
            street_title,
            building: house,
            apartment,
          } = requestData.delivery.geography.address;
          const city = requestData.delivery.geography.city.title;
          const street = `${street_type || 'ул'} ${street_title}`;

          const result = [
            region,
            city ? `г ${city}` : false,
            street || false,
            house ? `д ${house}` : false,
            apartment ? `кв ${apartment}` : false,
          ].filter(Boolean);

          requestData.delivery.data.data = undefined;
          requestData.delivery.data.unrestricted_value = result.join(', ');
        }
        submitRequest(requestData);
      });
      return;
    }

    submitRequest(requestData);
  };

  usePersistOrderToLocaleStorage(submitedOrder, submitedPayment, setSubmitedOrder, setPaymentData, setOrderNumber);

  useEffect(() => {
    window.addEventListener(SUBMIT_EVENT_TYPE, submit);

    return () => window.removeEventListener(SUBMIT_EVENT_TYPE, submit);
  });

  return [{ orderNumber, isOrderSubmitting, submitedOrder, orderError, setOrderError }, submit];
};

const OrderSubmitContext = createContext();

export const useOrderSubmit = () => useContext(OrderSubmitContext);

export const OrderSubmitStore = ({ children }) => (
  <OrderSubmitContext.Provider value={useHook()}>{children}</OrderSubmitContext.Provider>
);

OrderSubmitContext.displayName = 'OrderSubmitContext';
