import React from 'react';
import './promocode-input-suggestion.css';

export const PromocodeInputSuggestion = ({ onClick }) => (
  <div className="PromocodeInputSuggestion">
    <button className="PromocodeInputSuggestion-button" onClick={onClick}>
      Есть промокод?
    </button>
  </div>
);
