/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */


const log = {
  '09/09/2021 13:20 v1.27.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '19/08/2021 15:10 v1.21.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '16/08/2021 17:00 v1.20.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '19/07/2021 17:00 v1.12.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '15/07/2021 14:00 v1.11.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '12/07/2021 12:30 v1.10.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'abramov',
  },
  '08/07/2021 12:00 v1.9.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '05/07/2021 14:00 v1.8.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '28/06/2021 15:00 v1.6.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '21/06/2021 16:10 v1.5.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '17/06/2021 16:10 v1.4.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '10/06/2021 17:20 v1.2.0': {
    TYPE: 'release',
    CHANGE: `сборка релиза`,
    AUTHOR: 'shishmarev',
  },
  '02/06/2021 12:00 v.125.2': {
    TYPE: 'feature',
    CHANGE: `отмена правок по тексту`,
    AUTHOR: 'shishmarev',
  },
  '01/06/2021 11:50 v.125.1': {
    TYPE: 'feature',
    CHANGE: `замена правил акции`,
    AUTHOR: 'shishmarev',
  },
  '01/06/2021 10:07 v.125': {
    TYPE: 'feature',
    CHANGE: `правка сундуков, рефактторинг, сраница FAQ и сертификатов`,
    AUTHOR: 'shishmarev',
  },
  '26/05/2021 12:4 0 v.124.1': {
    TYPE: 'feature',
    CHANGE: `правка стилей баннера на главной`,
    AUTHOR: 'shishmarev',
  },
  '19/05/2021 12:00 v.124': {
    TYPE: 'feature',
    CHANGE: `правка стилей баннера на главной`,
    AUTHOR: 'shishmarev',
  },
  '14/05/2021 15:00 v.122': {
    TYPE: 'feature',
    CHANGE: `остатки товаров магнит`,
    AUTHOR: 'shishmarev',
  },
  '04/05/2021 15:50 v.121': {
    TYPE: 'feature',
    CHANGE: `счетчик mindbox`,
    AUTHOR: 'shishmarev',
  },
  '04/05/2021 15:50 v.120': {
    TYPE: 'feature',
    CHANGE: `Закрытие акции ДР`,
    AUTHOR: 'shishmarev',
  },
  '04/05/2021 15:50 v.119': {
    TYPE: 'feature',
    CHANGE: `Документы на страницу сертификатов, исправление бага с пикпоинт`,
    AUTHOR: 'shishmarev',
  },
  '29/04/2021 11:50 v.118.1': {
    TYPE: 'hotfix',
    CHANGE: `срочные правки по акции др`,
    AUTHOR: 'shishmarev',
  },
  '29/04/2021 11:50 v.118': {
    TYPE: 'feature',
    CHANGE: `Продление акции др, правки текста, отмена заглушки`,
    AUTHOR: 'shishmarev',
  },
  '29/04/2021 21:00 v.117': {
    TYPE: 'feature',
    CHANGE: `Реверт теста по акции ДР + заглуша + ссылка в шапку`,
    AUTHOR: 'shishmarev',
  },
  '26/04/2021 11:25 v.116': {
    TYPE: 'feature',
    CHANGE: `Добавление и замена сертификатов`,
    AUTHOR: 'ivinsky',
  },
  '21/04/2021 11:17 v.115.1': {
    TYPE: 'feature',
    CHANGE: `проверка чека на лендинге ДР, от 8 до 11`,
    AUTHOR: 'shishmarev',
  },
  '20/04/2021 08:17 v.115': {
    TYPE: 'feature',
    CHANGE: `замена документа`,
    AUTHOR: 'shishmarev',
  },
  '19/04/2021 17:50 v.114.1': {
    TYPE: 'feature',
    CHANGE: `правки от заказчика`,
    AUTHOR: 'shishmarev',
  },
  '19/04/2021 10:23 v.114.0': {
    TYPE: 'feature',
    CHANGE: `birthday`,
    AUTHOR: 'shishmarev',
  },
  '15/04/2021 10:47 v.113.0': {
    TYPE: 'bugfix',
    CHANGE: `Fix city input`,
    AUTHOR: 'shishmarev',
  },
  '08/04/2021 10:42 v.112.0': {
    TYPE: 'feature',
    CHANGE: `Fix city input`,
    AUTHOR: 'nikokoshev',
  },
  '02/04/2021 11:23 v.111.0': {
    TYPE: 'feature',
    CHANGE: `Разместил лого SK по задаче в футере`,
    AUTHOR: 'shishmarev',
  },
  "22/03/2021 12:10 v.110.0": {
    TYPE: "fix",
    CHANGE: `Изменения для стр сертификатов`,
    AUTHOR: 'illiashenko',
  },
  '18/03/2021 10:30 v.109.0': {
    TYPE: 'fix',
    CHANGE: `Обновил ссылку для пользовательских соглашений`,
    AUTHOR: 'illiashenko',
  },
  '18/03/2021 10:30 v.108.1': {
    TYPE: 'fix',
    CHANGE: `Добавил сертификаты для продукта`,
    AUTHOR: 'illiashenko',
  },
  '18/03/2021 10:30 v.108.0': {
    TYPE: 'fix',
    CHANGE: `Добавил новые отзивы для продукта`,
    AUTHOR: 'illiashenko',
  },
  '24/02/2021 10:10 v.107.0': {
    TYPE: 'fix',
    CHANGE: `Исправид грамматическую ошибку в футере`,
    AUTHOR: 'illiashenko',
  },
  '24/02/2021 10:00 v.106.1': {
    TYPE: 'fix',
    CHANGE: `Изменения для контактов`,
    AUTHOR: 'illiashenko',
  },
  '24/02/2021 10:00 v.106.0': {
    TYPE: 'fix',
    CHANGE: `Добавил ссылку на стратическую страничку акций для диалога с сундучками`,
    AUTHOR: 'illiashenko',
  },
  '23/02/2021 15:30 v.105.0': {
    TYPE: 'fix',
    CHANGE: `Исправил баг неотображения заглавия для баннера`,
    AUTHOR: 'illiashenko',
  },
  '22/02/2021 17:30 v.104.3': {
    TYPE: 'feat',
    CHANGE: `Добавил стратическую страничку акций для диалога с сундучками`,
    AUTHOR: 'illiashenko',
  },
  '22/02/2021 17:30 v.104.2': {
    TYPE: 'fix',
    CHANGE: `Изменения для странички контактов`,
    AUTHOR: 'illiashenko',
  },
  '22/02/2021 17:30 v.104.1': {
    TYPE: 'feat',
    CHANGE: `Страница прелоадера и новые лоадеры для диалога с сундучками`,
    AUTHOR: 'illiashenko',
  },
  '22/02/2021 17:30 v.104.0': {
    TYPE: 'feat',
    CHANGE: `Новый дизайн для диалога с сундучками`,
    AUTHOR: 'illiashenko',
  },
  '19/02/2021 16:00 v.103.0': {
    TYPE: 'fix',
    CHANGE: `Решил проблему с dadata для пгт`,
    AUTHOR: 'illiashenko',
  },
  '17/02/2021 14:00 v.102.0': {
    TYPE: 'fix',
    CHANGE: `Вернул ecommerse собития для странички`,
    AUTHOR: 'illiashenko',
  },
  '15/02/2021 19:00 v.101.0': {
    TYPE: 'fix',
    CHANGE: `Обновил соглашения для пользователей`,
    AUTHOR: 'illiashenko',
  },
  '21/01/2021 15:00 v.100.1': {
    TYPE: 'fix',
    CHANGE: `Исправил неисправность формы для подписок`,
    AUTHOR: 'illiashenko',
  },
  '21/01/2021 15:00 v.100.0': {
    TYPE: 'feat',
    CHANGE: `Добавил текст о доставке для страничек доставки и оформления заказа`,
    AUTHOR: 'illiashenko',
  },
  '21/01/2021 16:00 v.99.0': {
    TYPE: 'fix',
    CHANGE: `Сменил тип ссылки из относительной на абсолютную для главного баннера`,
    AUTHOR: 'illiashenko',
  },
  '04/04/2021 16:00 v.98.1': {
    TYPE: 'fix',
    CHANGE: `Обновил отзывы для главной и продукта страниц`,
    AUTHOR: 'illiashenko',
  },
  '04/02/2021 16:00 v.98.0': {
    TYPE: 'fix',
    CHANGE: `Добавил поп-ап для бонусов к заказу`,
    AUTHOR: 'illiashenko',
  },
  '21/01/2021 16:00 v.97.0': {
    TYPE: 'fix',
    CHANGE: `Убрал возможность выбора размера на странице продукта`,
    AUTHOR: 'illiashenko',
  },
  '18/01/2021 14:00 v.96.0': {
    TYPE: 'fix',
    CHANGE: `Исправил баг на страничке трекинг заказа`,
    AUTHOR: 'illiashenko',
  },
  '18/01/2021 13:30 v.95.0': {
    TYPE: 'fix',
    CHANGE: `Изменения для сертификатов и страницы контактов`,
    AUTHOR: 'illiashenko',
  },
  '15/01/2021 13:30 v.94.0': {
    TYPE: 'fix',
    CHANGE: `Убрал инфо у длительной доставке`,
    AUTHOR: 'Kondratenko',
  },
  '29/12/2020 13:30 v.93.0': {
    TYPE: 'fix',
    CHANGE: `Добавление возможности выбора размера на странице продукта`,
    AUTHOR: 'illiashenko',
  },
  '18/12/2020 12:00 v.92.0': {
    TYPE: 'fix',
    CHANGE: `Добавление новых сертификатов и баннера для для переключения на детский раздел`,
    AUTHOR: 'Kondratenko',
  },
  '18/12/2020 12:00 v.91.0': {
    TYPE: 'fix',
    CHANGE: `Добавление новых сертификатов`,
    AUTHOR: 'Kondratenko',
  },
  '15/12/2020 12:00 v.89.0': {
    TYPE: 'fix',
    CHANGE: `Контентные правки на странице контактов, футера и комментариев`,
    AUTHOR: 'Kondratenko',
  },
  '15/12/2020 02:30 v.88.0': {
    TYPE: 'fix',
    CHANGE: `Добавлен переключатель между детскими и взросылми разделами`,
    AUTHOR: 'Kondratenko',
  },
  '14/12/2020 15:30 v.87.0': {
    TYPE: 'fix',
    CHANGE: `Исправлена ссылка на детальную страницу товара с детской категории`,
    AUTHOR: 'Kondratenko',
  },
  '14/12/2020 15:30 v.86.0': {
    TYPE: 'fix',
    CHANGE: `Заменена информация в отзывах и удаление переключкения между проектами на мобильном`,
    AUTHOR: 'Kondratenko',
  },
  '14/12/2020 15:30 v.85.0': {
    TYPE: 'fix',
    CHANGE: `Заменена информация о доставке`,
    AUTHOR: 'Kondratenko',
  },
  '14/12/2020 13:30 v.84.0': {
    TYPE: 'add',
    CHANGE: `добавлен детский раздел`,
    AUTHOR: 'Kondratenko',
  },
  '07/12/2020 14:30 v.82.0': {
    TYPE: 'add',
    CHANGE: `убрал повторяющееся запросы перезалив`,
    AUTHOR: 'illiashenko',
  },
  '07/12/2020 13:30 v.81.0': {
    TYPE: 'add',
    CHANGE: `убрал повторяющееся запросы`,
    AUTHOR: 'illiashenko',
  },
  '07/12/2020 10:30 v.80.0': {
    TYPE: 'add',
    CHANGE: `убрал повторяющееся запросы`,
    AUTHOR: 'illiashenko',
  },
  '04/12/2020 09:30 v.79.0': {
    TYPE: 'add',
    CHANGE: `добавил новые баннера для мобильных устройств`,
    AUTHOR: 'illiashenko',
  },
  '27/11/2020 13:30 v.78.0': {
    TYPE: 'add',
    CHANGE: `исправил баги чекаута`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 14:50 v.77.0': {
    TYPE: 'add',
    CHANGE: `добавил ендпоинты для главного баннера и акций`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 16:30 v.76.0': {
    TYPE: 'add',
    CHANGE: `вернул старый главный баннер`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 16:30 v.75.0': {
    TYPE: 'add',
    CHANGE: `добавил подпись для чекаута и страницы трекинга заказа`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 11:30 v.74.0': {
    TYPE: 'add',
    CHANGE: `добавил столбец стоимость для таблицы пунктов самовывоза`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 09:30 v.73.1': {
    TYPE: 'add',
    CHANGE: `убрал галку для получателя в чекауте`,
    AUTHOR: 'illiashenko',
  },
  '20/11/2020 09:30 v.73.0': {
    TYPE: 'add',
    CHANGE: `изменил строение и функциональность главного баннера`,
    AUTHOR: 'illiashenko',
  },
  '19/11/2020 13:00 v.72.0': {
    TYPE: 'add',
    CHANGE: `устранил ошыбку с радио-элементами`,
    AUTHOR: 'illiashenko',
  },
  '19/11/2020 12:00 v.71.3': {
    TYPE: 'add',
    CHANGE: `изменения на страницу сертификатов`,
    AUTHOR: 'illiashenko',
  },
  '19/11/2020 12:00 v.71.2': {
    TYPE: 'add',
    CHANGE: `изменения на страницу оплаты и доставки`,
    AUTHOR: 'illiashenko',
  },
  '19/11/2020 12:00 v.71.1': {
    TYPE: 'add',
    CHANGE: `изменения в чекаут`,
    AUTHOR: 'illiashenko',
  },
  '19/11/2020 12:00 v.71.0': {
    TYPE: 'add',
    CHANGE: `добавил акции`,
    AUTHOR: 'illiashenko',
  },
  '16/11/2020 09:30 v.70.0': {
    TYPE: 'add',
    CHANGE: `добавил пункты самовывоза в доставку`,
    AUTHOR: 'maliuha, illiashenko',
  },
  '16/11/2020 09:30 v.69.0': {
    TYPE: 'add',
    CHANGE: `изменения для страницы сертификатов`,
    AUTHOR: 'illiashenko',
  },
  '13/11/2020 12:10 v.67.2': {
    TYPE: 'add',
    CHANGE: `изменения для страницы "ПОЛЬЗОВАТЕЛЬСКИЕ СОГЛАШЕНИЯ"`,
    AUTHOR: 'maliuha',
  },
  '13/11/2020 12:10 v.67.1': {
    TYPE: 'add',
    CHANGE: `изменения для страницы сертификатов`,
    AUTHOR: 'illiashenko',
  },
  '13/11/2020 12:10 v.67.0': {
    TYPE: 'add',
    CHANGE: `изменения для страницы доставки и оплаты`,
    AUTHOR: 'illiashenko',
  },
  '11/11/2020 11:45 v.66.0': {
    TYPE: 'add',
    CHANGE: `изменил в контактах hello@sammybeauty.ru на privet@sammybeauty.ru`,
    AUTHOR: 'maliuha',
  },
  '11/11/2020 09:30 v.65.0': {
    TYPE: 'add',
    CHANGE: `- добавил проверку на DELIVERY_METHOD/COURIER_WORLDWIDE
             - добавил изменения в dataLayer (step-4)`,
    AUTHOR: 'maliuha',
  },
  '09/11/2020 15:35 v.64.0': {
    TYPE: 'fix',
    CHANGE: `- фиксинг. кейс - выбор города с автосаджеста и изменеие значения в поле - выбор пункта доставки с самовывозом.
              - добавление "от" в почтеРоссии на странице /delivery-and-payment`,
    AUTHOR: 'maliuha',
  },
  '06/11/2020 17:40 v.63.0': {
    TYPE: 'fix',
    CHANGE: `подключил полифил к IntersectionObserver - нужно проверить работоспособность`,
    AUTHOR: 'maliuha',
  },
  '05/11/2020 14:05 v.62.0': {
    TYPE: 'fix',
    CHANGE: `поправил десиарилизатор dadata, обновил информацию на странице доставки`,
    AUTHOR: 'maliuha',
  },
  '05/11/2020 10:40 v.61.0': {
    TYPE: 'add',
    CHANGE: `set REACT_APP_API_HOST_DEV=sammybeauty.ru`,
    AUTHOR: 'maliuha',
  },
  '05/11/2020 09:30 v.60.0': {
    TYPE: 'add',
    CHANGE: `turn off translation`,
    AUTHOR: 'maliuha',
  },
  '03/11/2020 08:00 v.58.0': {
    TYPE: 'add',
    CHANGE: `фиксинг добавления товара с мобильного устройства. Раньше добавлялся со второго клика`,
    AUTHOR: 'maliuha',
  },
  '02/11/2020 14:40 v.57.0': {
    TYPE: 'add',
    CHANGE: `Уведомление о вводе полей чекаута кирилицей для стран СНГ`,
    AUTHOR: 'maliuha',
  },
  '30/10/2020 17:00 v.55.0': {
    TYPE: 'add',
    CHANGE: `Добавил запрос за дадатой если указана квартира`,
    AUTHOR: 'maliuha',
  },
  '30/10/2020 13:13 v.54.0': {
    TYPE: 'add',
    CHANGE: `Добавил тостер на добавление товара в корзину`,
    AUTHOR: 'maliuha',
  },
  '29/10/2020 17:10 v.53.0': {
    TYPE: 'add',
    CHANGE: `Правка вывода данных на странице трекинга заказа`,
    AUTHOR: 'maliuha',
  },
  '29/10/2020 16:36 v.52.0': {
    TYPE: 'add',
    CHANGE: `Пофиксил запрос за методами доставки с setlement и международной на мобильном`,
    AUTHOR: 'maliuha',
  },
  '29/10/2020 14:20 v.51.0': {
    TYPE: 'add',
    CHANGE: `Страница FAQ -исправленно и в боковом(мобильном) меню добавленна ссылка на неё. Добавленна там же ссылка на трекинг страницу. Поправленны иконки и размер шрифтов на мобильном. Добавлены посты с Инстаграма`,
    AUTHOR: 'maliuha',
  },
  '28/10/2020 10:20 v.50.0': {
    TYPE: 'add',
    CHANGE: `Страница FAQ - завершено. Добавленно открытие чата.`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 18:40 v.49.0': {
    TYPE: 'add',
    CHANGE: `Страница FAQ в базовой реализации`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 15:40 v.48.0': {
    TYPE: 'fix',
    CHANGE: `Добавленна информация по международной доставке`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 14:30 v.47.0': {
    TYPE: 'fix',
    CHANGE: `Замена order.id на order.number в запросе за ссылкой на оплату`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 14:10 v.46.0': {
    TYPE: 'fix',
    CHANGE: `Замена order.id на order.number`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 12:55 v.45.0': {
    TYPE: 'fix',
    CHANGE: `Исправил динамическую маску телефона - для не предопределенных стран маска строится другим плагином, без ограничения по длине`,
    AUTHOR: 'maliuha',
  },
  '27/10/2020 10:10 v.44.0': {
    TYPE: 'fix',
    CHANGE: `добавиление события impressions в dataLayer`,
    AUTHOR: 'maliuha',
  },
  '26/10/2020 15:30 v.43.0': {
    TYPE: 'fix',
    CHANGE: `Исправил динамическую маску телефона - если нет предопределенной маски для страны, она отключается`,
    AUTHOR: 'maliuha',
  },
  '26/10/2020 12:40 v.42.0': {
    TYPE: 'fix',
    CHANGE: `обновил секцию инстаграмма`,
    AUTHOR: 'illiashenko',
  },
  '26/10/2020 12:30 v.41': {
    TYPE: 'fix',
    CHANGE: `в международной доставке фильтрация вводиміх значений в инпуте по латиннице`,
    AUTHOR: 'maliuha',
  },
  '26/10/2020 10:50 v.40': {
    TYPE: 'fix',
    CHANGE: `- обернул dataLayer.push() в try-catch 
             - в запросе за городами в дадату добавил опции за пгт`,
    AUTHOR: 'maliuha',
  },
  '23/10/2020 19:30 v.39.1': {
    TYPE: 'fix',
    CHANGE: `претодвратил появление клавиатуры для корзины на мобильных устройствах`,
    AUTHOR: 'illiashenko',
  },
  '23/10/2020 19:30 v.39.0': {
    TYPE: 'fix',
    CHANGE: `переместил скрипт google tag manager для предотвращения неполадок с приложением`,
  },
  '22/10/2020 17:00 v.38.1': {
    TYPE: 'fix',
    CHANGE: `вернул секцию инстаграмма`,
    AUTHOR: 'illiashenko',
  },
  '22/10/2020 17:00 v.38.0': {
    TYPE: 'fix',
    CHANGE: `исправил бак неотображения видео на apple-устройствах`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.37.3': {
    TYPE: 'fix',
    CHANGE: `финальный апдейт акордиона на странице продукта`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.37.2': {
    TYPE: 'fix',
    CHANGE: `добавили новый метод оплаты`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.37.1': {
    TYPE: 'fix',
    CHANGE: `добавили международную доставку для оформления заказа`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.37.0': {
    TYPE: 'fix',
    CHANGE: `добавил новую информацию на страницу оплатыи доставки`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.36.2': {
    TYPE: 'fix',
    CHANGE: `добавил секцию с видео на главную страницу`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.36.1': {
    TYPE: 'fix',
    CHANGE: `добавил секцию с видео на страницу "О нас"`,
    AUTHOR: 'illiashenko',
  },
  '21/10/2020 13:00 v.36.0': {
    TYPE: 'fix',
    CHANGE: `добавил секцию с видео на страницу продукта`,
    AUTHOR: 'illiashenko',
  },
  '19/10/2020 11:40 v.35': {
    TYPE: 'fix',
    CHANGE: `- обновление для таблицы на странице трекинга заказа`,
    AUTHOR: 'illiashenko',
  },
  '17/10/2020 16:23 v.34': {
    TYPE: 'fix',
    CHANGE: `- пофиксил добавление building в запрос заказа`,
    AUTHOR: 'maliuha',
  },
  '17/10/2020 13:20 v.33': {
    TYPE: 'fix',
    CHANGE: `- добавил диалоги результатов для подписки
    - задал глобальное мета-описание
    - изменение на страничке контактов`,
    AUTHOR: 'illiashenko',
  },
  '16/10/2020 19:30 v.32': {
    TYPE: 'fix',
    CHANGE: `- добавил диалоги результатов для подписки
    - задал глобальное мета-описание
    - изменение на страничке контактов`,
    AUTHOR: 'illiashenko',
  },
  '16/10/2020 13:40 v.31': {
    TYPE: 'fix',
    CHANGE: `- обновление страницы контаков
    - обновление страницы трекинга заказов`,
    AUTHOR: 'illiashenko',
  },
  '14/10/2020 10:30 v.30': {
    TYPE: 'fix',
    CHANGE: 'Валидация 2',
    AUTHOR: 'maliuha',
  },
  '13/10/2020 21:40 v.29': {
    TYPE: 'fix',
    CHANGE: 'Валидация',
    AUTHOR: 'maliuha',
  },
  '13/10/2020 16:30 v.28': {
    TYPE: 'REFACTOR',
    CHANGE: `Сменил сетку и текст на странице контактов, добавил номер для страницы доставки и оплаты, 
        сменил стили для ссылок на статических страницах, добавил лейблы полям на страничке заказа, исправил проблему отображения номера на 
        странице заказа, добавил поле трек-номер и ссылку от транспортно компании, пофиксил ошыбку обрезания акордиона на странице продукта, 
        добавил автоматиеское заполнени чекпоинта, если он один, исправил проблему не корректного отображения улицы, пофиксил обрезание контента
        диалога для пикпоинтов`,
    AUTHOR: 'illiashenko',
  },
  '13/10/2020 16:30 v.27': {
    TYPE: 'REFACTOR',
    CHANGE: 'Фокус инпута промокода при активации поля',
    AUTHOR: 'maliuha',
  },
  '13/10/2020 15:30 v.26.2': {
    TYPE: 'ADD',
    CHANGE: 'Модификация автосаджеста (улица, дом...)',
    AUTHOR: 'maliuha',
  },
  '13/10/2020 09:02 v.25': {
    TYPE: 'REFACTOR',
    CHANGE: 'Динамическая маска в поле телефона ',
    AUTHOR: 'maliuha',
  },
  '10/10/2020 17:45 v.22': {
    CHANGE: 'Ресайз(уменьшение) статических картинок. добавлениеинформаци о сроках доставки',
    AUTHOR: 'maliuha',
  },
  '10/10/2020 09:00 v.21': {
    CHANGE: '/api/v4/orders/payment-log',
    AUTHOR: 'maliuha',
  },
  '09/10/2020 15:43 v.20': {
    CHANGE: 'validation hotfix',
    AUTHOR: 'maliuha',
  },
  '09/10/2020 14:40 v.19': {
    CHANGE: "'Распродано' хардкод для сетов - УБРАЛ",
    AUTHOR: 'maliuha',
  },
  '09/10/2020 12:40 v.18': {
    CHANGE: `Валидация по улице и дому + валидация по длине номера телевона (10символов). 
        Пришлось отключить валидацию полей получателя по причине не масштабируемой архитектуры валидации`,
    AUTHOR: 'maliuha',
  },
  '08/10/2020 23:00 v.17': {
    CHANGE: "'Распродано' хардкод для сетов",
    AUTHOR: 'maliuha',
  },
  '08/10/2020 22:12 v.16': {
    CHANGE: "'Распродано'",
    AUTHOR: 'maliuha',
  },
  '08/10/2020 21:05 v.15': {
    CHANGE: 'Изменил ключ в dadata',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 16:36 v.12': {
    CHANGE: 'Добавил фавиконку',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 16:20 v.11': {
    CHANGE: 'Пофиксил вывод списка пикпоинтов',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 14:50 v.10': {
    CHANGE: 'реализовал пуши в dataLayer - purchase, payment-success',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 11:45 v.9': {
    CHANGE: 'пофиксил ошибку с ценой продукта в корзине во время изменения кол-ва',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 11:30 v.8.2': {
    CHANGE: 'добавил в дадату Казахстан и Беларусь',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 11:30 v.8.1': {
    CHANGE: 'правки по стоимости доставки в чекауте',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 11:30 v.8': {
    CHANGE: 'текст ошибки на ответ 404 по методам доставки',
    AUTHOR: 'maliuha',
  },
  '08/10/2020 07:50 v.7': {
    CHANGE: 'реализовал пуши в dataLayer - pageview, detail, productClick, addToCart, checkout(3 шага)',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 12:59 v.5': {
    CHANGE: 'добавил city_title в запрос /pick-points',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 12:59 v.4.2': {
    CHANGE: 'удаление лишнего текста в /agreements',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 12:59 v.4.1': {
    CHANGE: 'добавил чат Usedesk',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 12:59 v.4': {
    CHANGE: 'пофиксил ширину продуктов на мобильном слайдере на главной',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 10:57 v.3.1': {
    CHANGE: 'сквозной тайтл сделал',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 10:57 v.3': {
    CHANGE: 'поменял ссылку на инсту в футере',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 10:15 v.2': {
    CHANGE: 'поменял ссылки на документы в футере',
    AUTHOR: 'maliuha',
  },
  '07/10/2020 9:50 v.1': {
    CHANGE: 'Добавил вывод истори изменений',
    AUTHOR: 'maliuha',
  },
};

function changelogInit() {
  if (!window) {
    return null;
  }

  const showChangelog = window.localStorage.getItem('_SHOW_CHANGELOG');

  if (showChangelog) {
    console.table(log);
  }
}

export default changelogInit;
