import React, { useRef } from 'react';
import cn from 'classnames';
import { Portal } from 'react-portal';
import { useTransition, animated } from '@react-spring/web';
import { useScrollBarWidth } from '../../hooks';
import { IconContainer } from '../icon-container/icon-container';
import { IconCross } from '../icons';
import './dialog.css';

const springConfig = {
  mass: 1,
  friction: 25,
};

/**
 * @returns {JSX.Element}
 */
export const Dialog = ({
  children,
  title = '',
  isOpened,
  noSideInnerIndents = false,
  onClose,
  outsideClose = true,
  style = {},
  noBottomInnerIndents = false,
  scrolable = false,
  noTopIndens = false,
  desktopViewForMobile = false,
  isHightPriority = false,
  isPrize = false,
  isPrizeWin = false,
  optimalTopIndens = false,
  disableBorderRadius = false,
  mobileFullHeight = false,
}) => {
  const windowElement = useRef(null);
  const scrollBarWidth = useScrollBarWidth();

  const right = isOpened ? '0' : `-${scrollBarWidth}px`;

  const transitions = useTransition([isOpened], {
    from: { opacity: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.9)' },
    config: springConfig,
  });

  const checkIsOverlay = (target) => {
    return !windowElement.current.contains(target);
  };

  const handleOverlayClick = (e) => {
    if (checkIsOverlay(e.target) && outsideClose && onClose) {
      onClose();
    }
  };

  return transitions(
    (props, localIsOpened) =>
      localIsOpened && (
        <Portal>
          <animated.div
            className={cn('Dialog', {
              'Dialog--noSideInnerIndents': noSideInnerIndents,
              'Dialog--noBottomInnerIndents': noBottomInnerIndents,
              'Dialog--noTopIndens': noTopIndens,
              'Dialog--scrolable': scrolable,
              'Dialog--desktopViewForMobile': desktopViewForMobile,
              'Dialog--isPrize': isPrize,
              'Dialog--isPrize-win': isPrizeWin,
              'Dialog--isHightPriority': isHightPriority,
              'Dialog--optimalTopIndens': optimalTopIndens,
              'Dialog--disableBorderRadius': disableBorderRadius,
              'Dialog--mobileFullHeight': mobileFullHeight,
            })}
            style={{ opacity: props.opacity, right }}
            onClick={handleOverlayClick}>
            <div className="Dialog-container">
              <animated.div className="Dialog-window" style={(props, { ...style })} ref={windowElement}>
                {title && <h2 className="Dialog-title">{title}</h2>}

                {onClose && (
                  <div className="Dialog-closeButtonHolder">
                    <button className="Dialog-closeButton" onClick={onClose}>
                      <IconContainer>
                        <IconCross />
                      </IconContainer>
                    </button>
                  </div>
                )}

                <div className="Dialog-content">{children}</div>
              </animated.div>
            </div>
          </animated.div>
        </Portal>
      ),
  );
};
