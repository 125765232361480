import React, { useState } from 'react';
import { getCoordsByAddress } from 'utils';
import { PickPointDialog } from './pick-point-dialog';
import { useOrderDeliveryData, useOrderPickPoints } from '../../stores';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

export const PickPointDialogContainer = ({ ...rest }) => {
  const [coords, setCoords] = useState([]);
  const [{ values }, { selectPickPoint, getCity }] = useOrderDeliveryData();
  const [{ list }] = useOrderPickPoints();

  const cityCoordinates = [+values?.ordererCity?.data?.geo_lat || 0, +values?.ordererCity?.data?.geo_lon || 0];
  const isValidCoordinates = cityCoordinates.every((el) => el);

  if (!isValidCoordinates && coords.length === 0) {
    const city = getCity();
    if (city) {
      const address = `${values.ordererCountry.title}, г ${capitalizeFirstLetter(city)}`;
      getCoordsByAddress(address).then((res) => setCoords(res || [0, 0]));
    }
  }

  return (
    <PickPointDialog
      cityTitle={getCity()}
      list={list}
      selectedId={values.pickPointId}
      onSelect={selectPickPoint}
      cityCoordinates={isValidCoordinates ? cityCoordinates : coords}
      isValidCoordinates={isValidCoordinates}
      {...rest}
    />
  );
};
