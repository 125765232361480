import React, { useState, useMemo, useCallback } from 'react';
import { Input } from '../input/input';
import { ShopsTable } from '../shops-table/shops-table';
import { IconSearch } from '../icons/icon-search/icon-search';
import { useMagnitGifts } from '../../stores';
import './magnit-gifts-dialog-.css.css';

const getHandleFilterShops = (localQueries) => (item) => {
  const isIncludeById = localQueries.find((queryItem) =>
    `${item.index}`.trim().toUpperCase().includes(queryItem.trim().toUpperCase()),
  );

  const isIncludeByAddress = localQueries.find((queryItem) =>
    item.adressRow.trim().toUpperCase().includes(queryItem.trim().toUpperCase()),
  );

  return isIncludeById || isIncludeByAddress;
};

export const MagnitGiftsDialogShops = () => {
  const [
    { shops, currentCity, isLoaded, products },
    { requestProducts, magnitSetCurrentShop, magnitSetCurrentDialogState },
  ] = useMagnitGifts();

  const currentShops = !!shops && shops[currentCity];

  const [searchQuery, setSearchQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const queries = useMemo(() => searchQuery.split(' '), [searchQuery]);

  const filteredListByQuery = useMemo(() => {
    if (!currentShops) {
      return null;
    }

    if (!searchQuery || searchQuery.length < 3) {
      const cuttedList = [...currentShops];
      cuttedList.length = 20;
      return cuttedList;
    }

    return currentShops.filter(getHandleFilterShops(queries));
  }, [searchQuery, currentShops, queries]);

  const handleQueryChange = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  const handleSearchFocus = () => {
    setIsFocused(true);
  };

  const handleSearchBlur = () => {
    setIsFocused(false);
  };

  const handleSelect = (code, adressRow) => {
    magnitSetCurrentShop(adressRow);

    if (products && products[adressRow] && products[adressRow].length > 0) {
      magnitSetCurrentDialogState('PRODUCTS');
      return;
    }
    requestProducts(code, adressRow);
  };

  return (
    <div className="MagnitGiftsDialog-shop">
      {isLoaded && <div className="MagnitGiftsDialog-shopLoader" />}

      <div className="MagnitGiftsDialog-filter">
        <Input
          isExpanded
          value={searchQuery}
          onChange={handleQueryChange}
          placeholder={'Введите улицу или индекс'}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          appended={<IconSearch />}
          hint={searchQuery.length >= 0 && searchQuery.length <= 3 && isFocused && 'Для поиска введите от 3 символов'}
        />
      </div>

      <div className="MagnitGiftsDialog-table">
        <ShopsTable
          list={filteredListByQuery}
          onSelect={handleSelect}
          searchQuery={!(searchQuery.length >= 0 && searchQuery.length < 3) ? queries : []}
        />
      </div>
    </div>
  );
};
