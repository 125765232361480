import { useMemo, useEffect } from 'react';

const createDummy = () => {
  const styles = {
    display: 'inline-block',
    position: 'absolute',
    top: '-1000px',
    left: '-1000px',
    zIndex: '-1',
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    width: 'auto',
  };

  const dummy = document.createElement('span');
  for (const [key, value] of Object.entries(styles)) {
    dummy.style[key] = value;
  }
  dummy.setAttribute('aria-hidden', true);
  document.body.append(dummy);

  return dummy;
};

export const useDynamicCalculatedWidth = (value) => {
  const dummy = useMemo(createDummy, [createDummy]);
  useEffect(() => () => dummy.remove(), [dummy]);

  dummy.innerHTML = value;
  const { width } = dummy.getBoundingClientRect();
  return Math.ceil(width + 3);
};
