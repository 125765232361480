import React from 'react';
import { usePromocode } from '../../stores';
import { Dialog } from '../dialog/dialog';
import { GiftsDialogItem } from './gifts-dialog-item';
import './gifts-dialog.css';

export const GiftsDialog = ({ onClose, ...rest }) => {
  const [{ promocode, error }, { cancel }] = usePromocode();
  const giftPromo = promocode?.length > 0 ? promocode[0] && promocode[0].options.map((gift) => gift.goods) : [];

  const handleClose = () => {
    const firstPromo = promocode.find(Boolean);
    if (firstPromo?.is_applied) {
      cancel(firstPromo);
    }
    onClose();
  };

  return (
    <Dialog
      title={!error ? 'Выберите подарок' : error}
      onClose={handleClose}
      optimalTopIndens
      noSideInnerIndents={false}
      {...rest}>
      <div className="GiftsDialog">
        {giftPromo.length > 0 && giftPromo.map((gift) => <GiftsDialogItem gift={gift} />)}
      </div>
    </Dialog>
  );
};
