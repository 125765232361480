import React from 'react';
import { IconContacts, IconDeliveryTruck, IconDocument, IconFAQ, IconStatus } from 'components/icons';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { IconContainer } from 'tsComponents/@ui-kit/IconContainer';
import { MenuTypeItem } from 'api/types';
import styles from './SecondaryNav.module.scss';

const iconComponents = {
  'delivery-and-payment': <IconDeliveryTruck />,
  rewards: <IconDocument />,
  contacts: <IconContacts />,
  faq: <IconFAQ />,
  'track-order': <IconStatus />,
} as const;

type NavLinkProps = {
  item: MenuTypeItem;
};

const NavLink: React.FC<NavLinkProps> = ({ item }) => {
  if (item.href) {
    return (
      <a
        href={item.href}
        className={styles.item}
        target={item.is_new_window ? '_blank' : undefined}
        style={{ color: item.color }}>
        <div
          className={cn(styles.itemIconContent, {
            [styles[item.slug as keyof typeof iconComponents]]: item.slug,
          })}>
          <IconContainer size={'inline'}>{iconComponents[item.slug as keyof typeof iconComponents]}</IconContainer>
        </div>
        <span className={styles.itemText}>{item.title}</span>
      </a>
    );
  }

  return (
    <RouterLink
      to={item.path}
      className={cn(styles.item)}
      target={item.is_new_window ? '_blank' : undefined}
      style={{ color: item.color }}>
      <div
        className={cn(styles.itemIconContent, {
          [styles[item.slug as keyof typeof iconComponents]]: item.slug,
        })}>
        <IconContainer size={'inline'}>{iconComponents[item.slug as keyof typeof iconComponents]}</IconContainer>
      </div>
      <span className={styles.itemText}>{item.title}</span>
    </RouterLink>
  );
};

type MenuSideDialogSecondaryNavProps = {
  className?: string;
  menu: MenuTypeItem[];
};

export const SecondaryNav: React.FC<MenuSideDialogSecondaryNavProps> = ({ menu }) => {
  return (
    <nav>
      {menu.map((item) => (
        <NavLink item={item} />
      ))}
    </nav>
  );
};
