import React from 'react';
import isEqual from 'react-fast-compare';
import { PickPointTable } from '../../pick-point-table/pick-point-table';

export const PickPointDialogTable = React.memo(
  ({ selectPickPoint, queries, handleSelect, style, onClick, ...rest }) => (
    <div className="PickPointDialog-table" style={style} onClick={onClick}>
      <PickPointTable onSelect={selectPickPoint} onChoose={handleSelect} {...rest} />
    </div>
  ),
  isEqual,
);
