import { useEffect, useRef, useState } from 'react';
import { useViewport } from './useViewport';

const calcAnimationDurationPerPixel = (contentHeight: number) => {
  if (contentHeight > 100) {
    return 0.0008;
  }

  return 0.003;
};

const calcDuration = (contentHeight: number) => {
  const durationPerPixel = calcAnimationDurationPerPixel(contentHeight);
  return durationPerPixel * contentHeight;
};

const getMaxHeight = (contentHeight: number, titleHeight?: number, isOpened?: boolean) => {
  if (!titleHeight) {
    return 'auto';
  }

  if (isOpened) {
    return `${contentHeight + titleHeight}px`;
  }

  return `${titleHeight}px`;
};

export const useSmoothItemOpening = (isOpened: boolean) => {
  const itemEl = useRef<HTMLDivElement>(null);
  const titleEl = useRef<HTMLDivElement>(null);
  const contentEl = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const { width } = useViewport();

  useEffect(() => {
    if (!titleEl) {
      return;
    }

    setTitleHeight(titleEl.current?.offsetHeight ?? 0);
  }, [titleEl, width]);

  useEffect(() => {
    if (!contentEl || !itemEl || !titleHeight) {
      return;
    }

    if (itemEl.current) {
      itemEl.current.style.maxHeight = `${titleHeight}px`;
      itemEl.current.style.overflow = 'hidden';
    }
  }, [titleEl, itemEl, titleHeight, width]);

  useEffect(() => {
    if (!titleHeight) {
      return;
    }

    if (contentEl.current) {
      contentEl.current.style.display = 'block';
      setContentHeight(contentEl.current.offsetHeight);
    }
  }, [titleHeight, width]);

  useEffect(() => {
    if (!itemEl) {
      return;
    }

    if (itemEl.current) {
      itemEl.current.style.maxHeight = getMaxHeight(contentHeight, titleHeight, isOpened);

      const duration = calcDuration(contentHeight);
      itemEl.current.style.transition = `max-height ${duration}s linear`;
    }
  }, [itemEl, titleHeight, contentHeight, isOpened, width]);

  return { itemEl, titleEl, contentEl };
};
