export const deliveryMethods = {
  courier: {
    slug: 'DELIVERY_METHOD/COURIER',
    title: 'Курьерская доставка',
  },
  courierWorldwide: {
    slug: 'DELIVERY_METHOD/COURIER_WORLDWIDE',
    title: 'Международная доставка',
  },

  post: {
    slug: 'DELIVERY_METHOD/POSTDATA',
    title: 'Почта России',
  },

  pickPoint: {
    slug: 'DELIVERY_METHOD/PICKPOINT',
    title: 'В пункт самовывоза',
  },

  pvz: {
    slug: 'DELIVERY_METHOD/PVZ',
    title: 'В пункт самовывоза',
  },
};

export const deliveryMethodsTitle = {
  'DELIVERY_METHOD/COURIER': 'Курьерская доставка',
  'DELIVERY_METHOD/COURIER_WORLDWIDE': 'Международная доставка',
  'DELIVERY_METHOD/POSTDATA': 'Почта России',
  'DELIVERY_METHOD/PICKPOINT': 'В пункт самовывоза',
  'DELIVERY_METHOD/PVZ': 'Пункты самовывоза',
};
