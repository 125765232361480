import React from 'react';
import { InformAvailabilityDialog } from 'components/inform-availability-dialog/inform-availability-dialog';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { SideMenu } from 'tsComponents/SideMenu';
import { PaymentInstallmentsModal } from 'tsComponents/PaymentInstallmentsModal/PaymentInstallmentsModal';
import PagesScene from './pages/pages-scene';
import { BasketRightSideDialog } from './components/basket-right-side-dialog/basket-right-side-dialog';
import { PickPointDialogContainer } from './components/pick-point-dialog/pick-point-dialog-container';
import { MagnitGiftsDialog } from './components/magnit-gifts-dialog/magnit-gifts-dialog';
import { GiftsDialog } from './components/gifts-dialog/gifts-dialog';
import { useModals } from './stores';
import { useAppHeight } from './hooks/use-app-height';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './overrideStyles/toastify.css';

export const App = () => {
  const [
    { isMenuOpened, isBasketOpened, isPickPointOpened, isMagnitGiftsOpened, isInformAvailabilityOpened, isGiftsOpened },
    { close },
  ] = useModals();

  useAppHeight();

  return (
    <>
      <Helmet>
        <title>«Sammy Beauty - косметика от Оксаны Самойловой»</title>
        <meta
          name="description"
          content="Купить косметику от Оксаны Самойловой Sammy Beauty с доставкой по всей России и миру. Программа для ежедневого ухода за кожей, пенки, тоники, кремы для тела."
        />
      </Helmet>
      <PagesScene />
      <ToastContainer />

      <PaymentInstallmentsModal />
      <SideMenu isVisible={isMenuOpened} onClose={close} />
      <BasketRightSideDialog isOpened={isBasketOpened} onClose={close} />
      <PickPointDialogContainer isOpened={isPickPointOpened} onClose={close} />
      <MagnitGiftsDialog isOpened={isMagnitGiftsOpened} onClose={close} />
      <InformAvailabilityDialog isOpened={isInformAvailabilityOpened} onClose={close} />
      <GiftsDialog isOpened={isGiftsOpened} onClose={close} />
    </>
  );
};
