import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { string } from 'yup';
import { useLocation } from 'react-router-dom';
import { useBasket } from './basket';
import { useModals } from './modals';
import { sammyBeautyApi } from '../service';

const schema = string().required('Поле не должно быть пустым');

function getPromoPostBody({ code, id = 0, option_id = 0, quantity = 0 }) {
  return { id, code, option_id, quantity };
}

const useHook = () => {
  const [, { openGifts, openBasket, close }] = useModals();
  const { pathname } = useLocation();

  const [{ promocode, productList }, { updateBasketFromServer }] = useBasket();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [selectedGift, setSelectedGift] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInitialLoading = useRef(true);

  const validate = (localValue) => {
    schema
      .validate(localValue)
      .then(() => setError(''))
      .catch((localError) => setError(localError.message));
  };

  const requestPromocode = (localProductList, promocodeString) => {
    setIsLoading(true);
    setIsErrorShown(false);

    const promoRequestBody = getPromoPostBody({ code: promocodeString });

    return sammyBeautyApi()
      .basket.promo.post(promoRequestBody)
      .then((basket) => {
        updateBasketFromServer({ ...basket });
        setValue('');

        if (basket.promos[0]?.options?.length) openGifts();
      })
      .catch((localError) => {
        setError(localError.response ? localError.response.message : localError.message);
        setIsErrorShown(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    validate(value);
  }, [value]);

  useEffect(() => {
    if (error || isInitialLoading) {
      return;
    }

    requestPromocode(productList, value);
  }, [error, productList]);

  useEffect(() => {
    isInitialLoading.current = true;
  }, []);

  const setPromoCodeGift = async (gift) => {
    setIsLoading(true);
    setIsErrorShown(false);

    try {
      const basket = await sammyBeautyApi().basket.promo.edit({
        id: promocode[0].id,
        code: promocode[0].code,
        option_id: gift.variant_id,
        quantity: 1,
      });

      updateBasketFromServer({ ...basket });
      setSelectedGift(gift);
      setValue('');

      if (pathname !== '/order') {
        openBasket();
      } else {
        close();
      }
    } catch (localError) {
      setError(localError.response.message);
      setIsErrorShown(true);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = (callback, callbackData) => {
    if (error) {
      setIsErrorShown(true);
      return;
    }

    requestPromocode(productList, value).then(() => {
      callback?.(callbackData);
    });
  };

  const cancel = (...data) => {
    console.log('cancel -> data', data);
    setIsLoading(true);
    setIsErrorShown(false);

    sammyBeautyApi()
      .basket.promo.delete(data[0])
      .then((basket) => {
        console.log('cancel -> basket', basket);
        updateBasketFromServer(basket);
        setSelectedGift(false);
      })
      .catch((localError) => {
        console.log('cancel -> error', localError);
        setError(localError.message);
        setIsErrorShown(true);
      })
      .finally(() => setIsLoading(false));
  };

  return [
    {
      inputValue: value,
      error: isErrorShown ? error : '',
      promocode,
      selectedGift,
      isDisabled: isLoading,
    },
    { changeInputValue: setValue, submit, cancel, setSelectedGift, setPromoCodeGift },
  ];
};

const PromocodeContext = createContext();

export const usePromocode = () => useContext(PromocodeContext);

export const PromocodeStore = ({ children }) => (
  <PromocodeContext.Provider value={useHook()}>{children}</PromocodeContext.Provider>
);

PromocodeStore.displayName = 'PromocodeContext';
