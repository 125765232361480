import React from 'react';
import { Svg } from '../svg/svg';
import './mineral-icon.css';

export const IconMineral = () => (
  <Svg className="IconMineral">
    <path d="M10.9059 30.1124L10.7271 30.0418L5.30354 23.5218L5.24707 23.3289L6.68 15.943L6.77884 15.783L6.95295 15.7289L11.8118 15.7477L11.8447 15.6348C12.5435 13.1289 14.2118 10.0983 16.7977 6.63242C18.7082 4.07242 20.4659 2.17125 20.6612 1.96184C20.7106 1.91007 20.7812 1.88184 20.8612 1.88184C20.9388 1.88184 21.0094 1.91007 21.0541 1.95948C21.0777 1.98301 23.3741 4.43478 25.6377 7.61595C28.6965 11.9124 30.2471 15.4324 30.2471 18.0748C30.2471 23.1854 26.0377 27.343 20.8588 27.343C20.3788 27.343 19.9012 27.3054 19.4282 27.2371L19.28 27.2136L18.7577 29.9007L18.6659 30.0559L18.4988 30.1148H10.9059V30.1124ZM11.3788 29.5854H17.9341L16.2376 27.5524L12.6706 27.4842L11.3788 29.5854ZM5.95295 23.4724L10.8729 29.3948L12.1388 27.3383L8.32001 22.5995L5.95295 23.4724ZM17.3506 23.7171L16.6188 27.0936L18.1365 28.9971L18.3459 29.2512L19.5788 22.896L17.3506 23.7171ZM8.7059 22.143L12.6047 26.903L16.1035 26.9712L16.7412 23.6959L12.84 18.9359L9.34353 18.8677L8.7059 22.143ZM20.7435 2.6536C19.1765 4.39242 13.9482 10.4583 12.4165 15.5524L12.36 15.7501L14.5341 15.7571L14.7177 15.8277L20.1318 22.3171L20.1859 22.5242L19.3788 26.6936L19.5388 26.7171C19.9718 26.783 20.4165 26.8136 20.8565 26.8136C25.7412 26.8136 29.7129 22.8912 29.7129 18.0701C29.7129 12.6159 22.433 4.27242 20.9718 2.6536L20.8565 2.52654L20.7435 2.6536ZM13.3012 18.503L17.0494 23.1571L19.2824 22.4442L19.4871 22.3689L14.5671 16.4701L13.3012 18.503ZM5.86354 22.9454L8.09177 22.1218L8.82354 18.7454L7.30589 16.8418L7.09648 16.5901L5.86354 22.9454ZM9.20236 18.2865L12.6847 18.3548L13.9177 16.5124L14.0612 16.2795L7.5106 16.2559L9.20236 18.2865Z" />
    <path d="M27.1737 22.2084C27.1266 22.2084 27.0772 22.1943 27.0325 22.1684C26.9713 22.1331 26.929 22.0743 26.9125 22.0061C26.8984 21.9378 26.9078 21.8672 26.9478 21.8061C27.656 20.6861 28.0278 19.3943 28.0278 18.0696C28.0278 16.4272 27.1901 14.2061 25.5407 11.4743C25.4654 11.3496 25.5078 11.1872 25.6348 11.1143C25.6866 11.1049 25.7313 11.1025 25.7713 11.1025C25.9031 11.1025 25.9643 11.1449 25.9995 11.2061C27.6984 14.0202 28.5619 16.3308 28.5619 18.072C28.5619 19.4955 28.1595 20.8861 27.3995 22.0861C27.3501 22.1614 27.2678 22.2084 27.1737 22.2084Z" />
    <path d="M20.8587 25.6774C20.7104 25.6774 20.5928 25.5597 20.5928 25.4162C20.5928 25.2703 20.7128 25.1527 20.8587 25.1527C22.6022 25.1527 24.2845 24.5244 25.5951 23.3833C25.6445 23.3409 25.7057 23.3174 25.7693 23.3174C25.8445 23.3174 25.9198 23.3503 25.9693 23.4068C26.0657 23.515 26.0563 23.6821 25.9434 23.7786C24.5387 25.0044 22.7316 25.6774 20.8587 25.6774Z" />
    <path d="M4.01881 14.1756C3.87058 14.1756 3.75293 14.058 3.75293 13.9121V12.5262C3.75293 12.3803 3.87293 12.2627 4.01881 12.2627C4.1647 12.2627 4.28234 12.3803 4.28234 12.5262V13.9121C4.28469 14.058 4.1647 14.1756 4.01881 14.1756Z" />
    <path d="M4.01881 16.9458C3.87058 16.9458 3.75293 16.8281 3.75293 16.6846V15.2963C3.75293 15.1528 3.87293 15.0352 4.01881 15.0352C4.1647 15.0352 4.28234 15.1528 4.28234 15.2963V16.6846C4.28469 16.8305 4.1647 16.9458 4.01881 16.9458Z" />
    <path d="M1.91286 14.8698C1.76697 14.8698 1.64697 14.7522 1.64697 14.6063C1.64697 14.4604 1.76697 14.3428 1.91286 14.3428H3.3152C3.46343 14.3428 3.58108 14.4604 3.58108 14.6063C3.58108 14.7522 3.46108 14.8698 3.3152 14.8698H1.91286Z" />
    <path d="M4.71998 14.8698C4.57175 14.8698 4.4541 14.7522 4.4541 14.6063C4.4541 14.4604 4.5741 14.3428 4.71998 14.3428H6.12233C6.27056 14.3428 6.38821 14.4604 6.38821 14.6063C6.38821 14.7522 6.26821 14.8698 6.12233 14.8698H4.71998Z" />
    <path d="M9.63023 12.0961C9.48434 12.0961 9.3667 11.9785 9.3667 11.8326V10.4467C9.3667 10.3032 9.4867 10.1855 9.63023 10.1855C9.77846 10.1855 9.89611 10.3032 9.89611 10.4467V11.8326C9.89846 11.9808 9.77846 12.0961 9.63023 12.0961Z" />
    <path d="M9.63023 14.87C9.48434 14.87 9.3667 14.7523 9.3667 14.6064V13.2206C9.3667 13.0747 9.4867 12.957 9.63023 12.957C9.77846 12.957 9.89611 13.0747 9.89611 13.2206V14.6064C9.89846 14.7523 9.77846 14.87 9.63023 14.87Z" />
    <path d="M7.52711 12.7898C7.38123 12.7898 7.26123 12.6721 7.26123 12.5262C7.26123 12.3803 7.38123 12.2627 7.52711 12.2627H8.93181C9.08005 12.2627 9.1977 12.3803 9.1977 12.5262C9.1977 12.6721 9.0777 12.7898 8.93181 12.7898H7.52711Z" />
    <path d="M10.3342 12.7898C10.186 12.7898 10.0684 12.6721 10.0684 12.5262C10.0684 12.3803 10.1884 12.2627 10.3342 12.2627H11.7366C11.8848 12.2627 12.0025 12.3803 12.0025 12.5262C12.0025 12.6721 11.8825 12.7898 11.7366 12.7898H10.3342Z" />
    <path d="M6.12241 7.94029C5.97653 7.94029 5.85889 7.82264 5.85889 7.67676V6.29087C5.85889 6.14499 5.97889 6.02734 6.12241 6.02734C6.27065 6.02734 6.3883 6.14499 6.3883 6.29087V7.67676C6.3883 7.82264 6.27065 7.94029 6.12241 7.94029Z" />
    <path d="M6.12241 10.7127C5.97653 10.7127 5.85889 10.5951 5.85889 10.4492V9.06333C5.85889 8.91745 5.97889 8.7998 6.12241 8.7998C6.27065 8.7998 6.3883 8.91745 6.3883 9.06333V10.4492C6.3883 10.5951 6.27065 10.7127 6.12241 10.7127Z" />
    <path d="M4.01881 8.63213C3.87058 8.63213 3.75293 8.51448 3.75293 8.36859C3.75293 8.22507 3.87293 8.10742 4.01881 8.10742H5.42117C5.56941 8.10742 5.68706 8.22507 5.68706 8.36859C5.68706 8.51448 5.56705 8.63213 5.42117 8.63213H4.01881Z" />
    <path d="M6.82593 8.63213C6.67769 8.63213 6.56006 8.51448 6.56006 8.36859C6.56006 8.22507 6.68005 8.10742 6.82593 8.10742H8.22829C8.37652 8.10742 8.49417 8.22507 8.49417 8.36859C8.49417 8.51448 8.37417 8.63213 8.22829 8.63213H6.82593Z" />
  </Svg>
);
