import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import changelogInit from './CHANGELOG';

import { initSammyBeautyApi } from './service';

import {
  CombineStores,
  IsHeaderInvertedStore,
  IsHeaderSmallStore,
  HeaderHeightStore,
  ModalsStore,
  ScrollLockStore,
  BasketStore,
  PromocodeStore,
  OrderStore,
  Product,
  TrackOrderFormStore,
  DataLayerStore,
  CountriesStore,
  SpecialOfferStore,
  MainBannerStore,
  ChestGiftContextStore,
  MagnitGiftsStore,
  MagnitGiftsDialogInfoContextStore,
  SettingsStore,
  CakeChestStore,
  AvailabilityInformInfoContextStore,
  DesiresStore,
  useSettings,
  PageStore,
} from './stores';

import { App } from './App';

import 'loaders.css/src/animations/line-spin-fade-loader.scss';
import './index.scss';
import './chat-bot-styles.css';
import { InstallmentsModalContextStore } from './stores/installments-modal';
import { OrderChangeGiftsStore } from './stores/order/order-change-gifts';

const API_HOST = process.env.REACT_APP_API_HOST;
const API_SUFFIX = process.env.REACT_APP_API_SUFFIX_4;

const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;

// const API_HOST_DEV = process.env.REACT_APP_API_HOST_DEV;
// const API_SUFFIX_3 = process.env.REACT_APP_API_SUFFIX_3;

function getApiParams() {
  const HOST_LS_KEY = '_API_HOST';
  const VERSION_LS_KEY = '_API_VERSION';
  if (!window) {
    return [API_HOST, { suffix: API_SUFFIX }];
  }

  const host = window.localStorage.getItem(HOST_LS_KEY) || API_HOST;
  const version = window.localStorage.getItem(VERSION_LS_KEY);
  return [host, { suffix: version ? `api/${version}` : API_SUFFIX }];
}

// initSammyBeautyApi(API_HOST, { suffix: API_SUFFIX });
initSammyBeautyApi(...getApiParams());

const Application = () => {
  const [{ isInitialLoading }] = useSettings();

  return (
    !isInitialLoading && (
      <Router>
        <CombineStores
          stores={[
            CountriesStore,
            DataLayerStore,
            ScrollLockStore,
            ModalsStore,
            InstallmentsModalContextStore,
            HeaderHeightStore,
            IsHeaderInvertedStore,
            IsHeaderSmallStore,
            BasketStore,
            PromocodeStore,
            OrderStore,
            Product,
            TrackOrderFormStore,
            SpecialOfferStore,
            MainBannerStore,
            ChestGiftContextStore,
            MagnitGiftsStore,
            MagnitGiftsDialogInfoContextStore,
            CakeChestStore,
            OrderChangeGiftsStore,
            AvailabilityInformInfoContextStore,
            DesiresStore,
            PageStore,
          ]}>
          <App />
        </CombineStores>
      </Router>
    )
  );
};

Sentry.init({
  environment: SENTRY_ENV,
  dsn: SENTRY_DNS,
  beforeSend: (event) => {
    return {
      ...event,
      extra: {
        ...event.extra,
        cookies: document.cookie,
      },
    };
  },
  integrations: [new BrowserTracing()],
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <SettingsStore>
        <Application />
      </SettingsStore>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.querySelector('#root'),
);

const siteVersion = '1.49.0';
console.log(`version: %c${siteVersion}`, 'font-weight: bold;font-size: large; color:red');

changelogInit();
