import { useState, useEffect, useCallback } from 'react';
import { debounce } from '../utils';

export const useHeaderControllerOffsets = (headerHeight) => {
  const [offsets, setOffsets] = useState({ top: 0, bottom: 0 });

  const setOffsetDebounced = useCallback(
    debounce((localOffsets) => {
      setOffsets(localOffsets);
    }, 200),
    [],
  );

  useEffect(() => {
    if (!headerHeight) {
      return;
    }

    setOffsetDebounced({
      top: `${headerHeight / 2}px`,
      bottom: `${document.documentElement.clientHeight - headerHeight / 2 - 1}px`,
    });
  }, [headerHeight, setOffsetDebounced]);

  return offsets;
};
