import React from 'react';

import { Svg } from '../svg/svg';

import './icon-basket.css';

export const IconBasket = () => (
  <Svg className="IconBasket">
    <rect x="5.25" y="9.75" width="21.5" height="17.5" />
    <path d="M21.7447 9.75H10.2553C10.3862 6.69035 12.9081 4.25 16 4.25C19.0919 4.25 21.6138 6.69035 21.7447 9.75Z" />
  </Svg>
);
