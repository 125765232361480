import React, { useState, useRef, forwardRef } from 'react';
import cn from 'classnames';
import { IconContainer } from '../icon-container/icon-container';
import { IconPlus, IconMinus } from '../icons';
import { useDynamicCalculatedWidth } from './use-dynamic-calculated-width';
import './counter.css';

const KEY_ARROW_UP = 38;
const KEY_ARROW_DOWN = 40;
const KEY_ENTER = 13;

export const Counter = forwardRef(({ value = 1, min = 1, max = 100, isFlat, onChange, isLoading }, ref) => {
  const [isInputFocused, setInputFocused] = useState(false);
  const counterRef = useRef(null);
  const width = useDynamicCalculatedWidth(value);

  const handleCounterKeyDown = (e) => {
    const { keyCode } = e;

    if (keyCode === KEY_ENTER) {
      counterRef.current.focus();
    }

    if (keyCode === KEY_ARROW_UP) {
      e.preventDefault();
      if (value >= max) {
        return;
      }
      onChange(value + 1);
    }

    if (keyCode === KEY_ARROW_DOWN) {
      e.preventDefault();
      if (value <= min) {
        return;
      }
      onChange(value - 1);
    }
  };
  const handleInputFocus = () => setInputFocused(true);
  const handleInputBlur = () => setInputFocused(false);
  const handleInputChange = (e) => {
    const { value: localValue } = e.target;
    const num = Number.parseInt(localValue, 10);

    if (Number.isNaN(num)) {
      return;
    }

    if (num > max) {
      onChange(max);
      return;
    }

    if (num < min) {
      onChange(max);
      return;
    }

    onChange(num);
  };

  const handleDecrease = () => {
    if (value <= min) {
      return;
    }
    onChange(value - 1);
  };
  const handleIncrease = () => {
    if (value >= max) {
      return;
    }
    onChange(value + 1);
  };

  return (
    <div
      className={cn('Counter', {
        'Counter--withFocusedInput': isInputFocused,
        'Counter--flat': isFlat,
      })}
      ref={counterRef}
      tabIndex={0}
      onKeyDown={handleCounterKeyDown}>
      <button
        className={cn('Counter-button', {
          'Counter-button--disabled': value === min,
        })}
        tabIndex={-1}
        disabled={isLoading}
        onClick={handleDecrease}>
        <IconContainer size="xxsmall">
          <IconMinus />
        </IconContainer>
      </button>

      <input
        className="Counter-input"
        value={value}
        tabIndex={-1}
        ref={ref}
        style={{ width }}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />

      <button
        className={cn('Counter-button', {
          'Counter-button--disabled': value === max,
        })}
        tabIndex={-1}
        disabled={isLoading}
        onClick={handleIncrease}>
        <IconContainer size="xsmall">
          <IconPlus />
        </IconContainer>
      </button>
    </div>
  );
});
