import React, { useState, useContext, createContext } from 'react';

const useHook = () => {
  const [isSmall, setIsSmall] = useState(true);

  return [
    isSmall,
    {
      reduce: () => setIsSmall(true),
      enlarge: () => setIsSmall(false),
    },
  ];
};

const IsHeaderSmallContext = createContext();

export const useIsHeaderSmall = () => useContext(IsHeaderSmallContext);

export const IsHeaderSmallStore = ({ children }) => (
  <IsHeaderSmallContext.Provider value={useHook()}>{children}</IsHeaderSmallContext.Provider>
);

IsHeaderSmallContext.dicplayName = 'IsHeaderSmallContext';
