import React, { useContext, useReducer, createContext, useCallback } from 'react';
import { sammyBeautyApi } from '../service';
import { bindActionsToDispatch, createAction } from './utils';
import { useModals } from './modals';

const RESPONSE_CITIES = 'MAGNIT_GIFTS/RESPONSE_CITIES';
const REQUEST = 'MAGNIT_GIFTS/REQUEST ';
const RESPONSE_SHOPS = 'MAGNIT_GIFTS/RESPONSE_SHOPS';
const SET_CURRENT_CITY = 'MAGNIT_GIFTS/SET_CURRENT_CITY';
const SET_CURRENT_SHOP = 'MAGNIT_GIFTS/SET_CURRENT_SHOP';
const SET_DIALOG_STATE = 'MAGNIT_GIFTS/SET_DIALOG_STATE';
const PRODUCTS_RESPONSE = 'MAGNIT_GIFTS/PRODUCTS_REQUEST';

const initialState = {
  cityList: null,
  shops: null,
  isLoaded: false,
  currentCity: null,
  currentShop: null,
  magnitCurrentDialogState: 'SHOPS',
  products: null,
};

const actions = {
  magnitRequest: () => createAction(REQUEST),
  magnitCitiesResponse: (cityList) => createAction(RESPONSE_CITIES, { cityList }),
  magnitShopsResponse: (shopsList, city) => createAction(RESPONSE_SHOPS, { shopsList, city }),
  magnitSetCurrentCity: (cityTitle) => createAction(SET_CURRENT_CITY, { cityTitle }),
  magnitSetCurrentShop: (shopTitle) => createAction(SET_CURRENT_SHOP, { shopTitle }),
  magnitSetCurrentDialogState: (dialogState) => createAction(SET_DIALOG_STATE, { dialogState }),
  magnitProductResponse: (productList, shopRow) => createAction(PRODUCTS_RESPONSE, { productList, shopRow }),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case REQUEST: {
      return {
        ...state,
        isLoaded: true,
      };
    }

    case RESPONSE_CITIES: {
      const { cityList } = payload;

      return {
        ...state,
        cityList,
        isLoaded: false,
      };
    }

    case RESPONSE_SHOPS: {
      const { shopsList, city } = payload;

      return {
        ...state,
        shops: {
          ...state.shops,
          [city]: shopsList,
        },
        isLoaded: false,
      };
    }

    case SET_CURRENT_CITY: {
      const { cityTitle } = payload;
      return {
        ...state,
        currentCity: cityTitle,
      };
    }

    case SET_CURRENT_SHOP: {
      const { shopTitle } = payload;

      return {
        ...state,
        currentShop: shopTitle,
      };
    }

    case SET_DIALOG_STATE: {
      const { dialogState } = payload;

      return {
        ...state,
        magnitCurrentDialogState: dialogState,
      };
    }

    case PRODUCTS_RESPONSE: {
      const { productList, shopRow } = payload;

      return {
        ...state,
        products: {
          ...state.products,
          [shopRow]: productList,
        },
        isLoaded: false,
      };
    }

    default:
      return state;
  }
};

const normalizeShops = (shops) => {
  if (!shops || shops.length === 0) {
    return null;
  }

  return shops.map((item) => {
    if (!item.address || !item.codeMh) {
      return null;
    }

    const sepChar = item.address.indexOf(',');

    return {
      codeMh: item.codeMh,
      adressRow: item.address.slice(sepChar + 1).trim(),
      index: item.address.slice(0, sepChar).trim(),
    };
  });
};

const normalizeProduct = (products) => {
  const clearProducts = [...products];

  if (!clearProducts || !clearProducts[0]) {
    console.log('null!');
    return null;
  }

  return clearProducts[0].products
    .filter((item) => item.image)
    .map((item) => ({
      ...item,
      image: {
        ...item.image,
        sources: [item.image.sources[0].main.normal],
      },
    }));
};

const useHook = () => {
  const [, { openMagnitGifts }] = useModals();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { cityList, isLoaded, shops, currentCity, currentShop, magnitCurrentDialogState, products } = state;

  const {
    magnitRequest,
    magnitCitiesResponse,
    magnitShopsResponse,
    magnitSetCurrentCity,
    magnitSetCurrentShop,
    magnitSetCurrentDialogState,
    magnitProductResponse,
  } = bindActionsToDispatch(actions, dispatch);

  const requestMagnitCity = useCallback(() => {
    magnitRequest();

    sammyBeautyApi()
      .magnitCities()
      .then((data) => {
        magnitCitiesResponse(data);
      })
      .catch((error) => {
        console.warn('requestMagnitCity -> e', error);
      });
  }, []);

  const requestShops = useCallback((city) => {
    magnitRequest();

    sammyBeautyApi()
      .magnitShops({ city })
      .then((data) => {
        magnitShopsResponse(normalizeShops(data), city);
        openMagnitGifts();
      })
      .catch((error) => {
        console.warn('requestMagnitCity -> e', error);
      });
  }, []);

  const requestProducts = useCallback((code, adressRow) => {
    magnitRequest();

    sammyBeautyApi()
      .magnitProducts({ code })
      .then((data) => {
        magnitProductResponse(normalizeProduct(data), adressRow);
        magnitSetCurrentDialogState('PRODUCTS');
      })
      .catch((error) => {
        console.warn('requestMagnitCity -> e', error);
      });
  }, []);

  return [
    { cityList, isLoaded, shops, currentCity, currentShop, magnitCurrentDialogState, products },
    {
      requestMagnitCity,
      requestShops,
      magnitSetCurrentCity,
      magnitSetCurrentShop,
      magnitSetCurrentDialogState,
      requestProducts,
    },
  ];
};

const MagnitGiftsContext = createContext();

export const useMagnitGifts = () => {
  return useContext(MagnitGiftsContext);
};

export const MagnitGiftsStore = ({ children }) => (
  <MagnitGiftsContext.Provider value={useHook()}>{children}</MagnitGiftsContext.Provider>
);

MagnitGiftsContext.displayName = 'MagnitGiftsContext';
