import { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useMedia } from 'hooks';
import { useModals } from './modals';

const useHook = () => {
  const isMobile = useMedia('(max-width: 1280px)');
  const [{ modal }] = useModals();
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    if (isOpen && !isMobile) {
      document.body.style.overflow = 'hidden';
      return;
    }

    if (!modal) {
      document.body.style.overflow = '';
    }
  }, [isOpen, isMobile]);

  return [
    {
      isOpen,
    },
    {
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
    },
  ];
};

const InstallmentsContext = createContext();

export const useInstallmentsModal = () => {
  return useContext(InstallmentsContext);
};

export const InstallmentsModalContextStore = ({ children }) => {
  return <InstallmentsContext.Provider value={useHook()}>{children}</InstallmentsContext.Provider>;
};
