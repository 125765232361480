import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { CellMeasurer, CellMeasurerCache, AutoSizer, List } from 'react-virtualized';
import { PickPointRow } from '../pick-point-row/pick-point-row';
import { useDataLayer } from '../../stores';
import './pick-point-table.css';

const getListItemByIdProperty = (list, searchedID) => list.findIndex(({ id }) => id === searchedID);

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 67,
  minWidth: '100%',
});

const clearCache = () => cache.clearAll();

const PickPointTableItem = ({ style, index, parent, list, selectedId, handleRowClick, chosenId, handleChose }) => {
  const pickPoint = list[index];

  return (
    <CellMeasurer cache={cache} key={pickPoint.id} parent={parent} columnIndex={0} rowIndex={index}>
      <div className="PickPointTable-item" style={style}>
        <PickPointRow
          pickPoint={pickPoint}
          isSelected={selectedId === pickPoint.id}
          onClick={handleRowClick}
          isChosen={chosenId === pickPoint.id}
          onChoose={handleChose}
        />
      </div>
    </CellMeasurer>
  );
};

const PickPointTable = memo(({ list, selectedId, onSelect, chosenId, onChoose, isListSelected }) => {
  const [isChosen, setIsChosen] = useState(false);
  const [, { ecommerceBlurField }] = useDataLayer();

  const listRef = useRef(null);

  const handleRowClick = useCallback(
    (pickPoint) => {
      clearCache();
      ecommerceBlurField('choose-pick-point', pickPoint.title);
      onSelect(pickPoint);
    },
    [ecommerceBlurField, onSelect],
  );
  const handleChose = useCallback(
    (...rest) => {
      setIsChosen(true);
      onChoose(...rest);
    },
    [onChoose],
  );
  useEffect(() => {
    window.addEventListener('resize', clearCache);
    return () => window.removeEventListener('resize', clearCache);
  }, []);

  useEffect(() => {
    clearCache();
  }, [selectedId, chosenId, isListSelected]);

  useEffect(() => {
    if (chosenId) {
      const rowIndex = getListItemByIdProperty(list, chosenId);
      setTimeout(() => listRef?.current?.scrollToRow(rowIndex));
    }
  }, [chosenId]);

  return (
    <div className="PickPointTable">
      <div className="PickPointTable-titleGroup">
        <div className="PickPointTable-title">Пункт выдачи</div>
      </div>

      <div className="PickPointTable-listPositioner">
        <div className="PickPointTable-list">
          {!isChosen && (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={listRef}
                  height={height}
                  width={width}
                  rowCount={list.length}
                  scrollToAlignment="start"
                  rowRenderer={({ index, parent, style }) => (
                    <PickPointTableItem
                      style={style}
                      index={index}
                      parent={parent}
                      list={list}
                      selectedId={selectedId}
                      handleRowClick={handleRowClick}
                      chosenId={chosenId}
                      handleChose={handleChose}
                    />
                  )}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    </div>
  );
}, isEqual);

export const PickPointTableMemoized = memo(PickPointTable, isEqual);

export { PickPointTableMemoized as PickPointTable };
