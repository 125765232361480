import React from 'react';
import isEqual from 'react-fast-compare';
import cn from 'classnames';

export const PickPointDialogSelectionTabs = React.memo(({ tabsRef, isListSelected, setIsListSelected, onClick }) => {
  const handleListClick = () => setIsListSelected(true);
  const handleMapClick = () => setIsListSelected(false);

  return (
    <div
      ref={tabsRef}
      className={cn('PickPointDialog-SelectionTabs', {
        'SelectionTabs--list': isListSelected,
        'SelectionTabs--map': !isListSelected,
      })}
      onClick={onClick}>
      <button className="PickPointDialog-tab PickPointDialog-tabList" onClick={handleListClick}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="1.9" x2="12" y2="1.9" stroke="black" strokeWidth="0.7" />
          <line y1="4.9" x2="9.75" y2="4.9" stroke="black" strokeWidth="0.7" />
          <line y1="7.9" x2="12" y2="7.9" stroke="black" strokeWidth="0.7" />
          <line y1="10.9" x2="9.75" y2="10.9" stroke="black" strokeWidth="0.7" />
        </svg>
        <span>Списком</span>
      </button>
      <button className="PickPointDialog-tab PickPointDialog-tabMap" onClick={handleMapClick}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.23966 4.43093C9.23066 2.39782 7.61257 0.75 5.60506 0.75C3.59755 0.75 1.97046 2.39782 1.97046 4.43093C1.97046 7.06202 5.599 11.25 5.599 11.25C5.599 11.25 9.2516 7.06202 9.23966 4.43093Z"
            stroke="black"
            strokeWidth="0.7"
            strokeMiterlimit="10"
          />
          <path
            d="M5.60509 5.59617C6.27421 5.59617 6.81663 5.05375 6.81663 4.38463C6.81663 3.71552 6.27421 3.1731 5.60509 3.1731C4.93598 3.1731 4.39355 3.71552 4.39355 4.38463C4.39355 5.05375 4.93598 5.59617 5.60509 5.59617Z"
            stroke="black"
            strokeWidth="0.7"
            strokeMiterlimit="10"
          />
        </svg>
        <span>на карте</span>
      </button>
    </div>
  );
}, isEqual);
