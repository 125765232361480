import { useEffect, useState } from 'react';
import { throttle } from '../utils';

const DEFAULT_WIDTH = 1368;
const DEFAULT_HEIGHT = 769;
function getWindowHeight(): number {
  return window ? window.innerHeight : DEFAULT_HEIGHT;
}

function getWindowWidth(): number {
  return window ? window.innerWidth : DEFAULT_WIDTH;
}

export function useViewport() {
  const [height, setHeight] = useState(() => getWindowHeight());
  const [width, setWidth] = useState(() => getWindowWidth());

  useEffect(() => {
    // eslint-disable-next-line func-names
    const onResize = throttle(function () {
      setHeight(getWindowHeight());
      setWidth(getWindowWidth());
    }, 300);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return { width, height };
}
