import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { Dialog } from '../dialog/dialog';
import {
  PickPointDialogLoader,
  PickPointDialogFilter,
  PickPointDialogTable,
  PickPointDialogSelectionTabs,
  PickPointDialogMapWrapper,
} from './components';
import './pick-point-dialog.css';

const MAP_ZOOMS = {
  SHOP: 15,
  CITY: 9,
  COUNTRY: 3,
};

export const PickPointDialog = React.memo(
  ({ cityTitle, list, onSelect, onClose, cityCoordinates, isValidCoordinates, isOpened, selectedId, ...rest }) => {
    const [isMapLoading, setIsMapLoading] = useState(true);
    const [selectedPickPoint, setSelectedPickPoint] = useState(null);
    const [mapState, setMapState] = useState({
      zoom: MAP_ZOOMS.CITY,
      center: cityCoordinates,
    });
    const [chosenId, setChosenId] = useState(null);
    const [isListSelected, setIsListSelected] = useState(false);
    const [ymaps, setYmaps] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [prevClickedPickPointID, setPrevClickedPickPointID] = useState(null);

    let mapRef = useRef(null);
    const tabsRef = useRef(null);

    useEffect(() => {
      setMapState((state) => ({
        ...state,
        center: cityCoordinates,
        zoom: isValidCoordinates ? MAP_ZOOMS.CITY : MAP_ZOOMS.COUNTRY,
      }));
      setSelectedPickPoint(null);
      setIsMapLoading(true);
    }, [cityTitle]);

    const handleSelect = useCallback(
      (pickPoint) => {
        onClose();
        onSelect(pickPoint);
        setTimeout(() => setIsMapLoading(true), 500);
      },
      [onClose, onSelect],
    );

    const handleDialogClose = () => {
      onClose();
      setTimeout(() => setIsMapLoading(true), 500);
    };

    const closePickPointInfo = useCallback(() => {
      setSelectedPickPoint(null);
    }, []);

    const setMapElementRef = useCallback(
      (element) => {
        if (!element) return;
        mapRef = element;
      },
      [mapRef],
    );

    const selectPickPoint = useCallback((pickPoint) => {
      const { _raw: raw, id } = pickPoint;
      const center = Object.values(raw.geography.coordinates).map((el) => +el);

      setSelectedPickPoint(pickPoint);
      setChosenId(id);
      setMapState((state) => {
        return {
          ...state,
          center,
          zoom: MAP_ZOOMS.SHOP,
        };
      });
    }, []);

    const selectPickPointOnMap = useCallback(
      (e) => {
        const objectId = e.get('objectId');
        const data = list[objectId];
        setSelectedPickPoint(data);
        setChosenId(data?.id);
      },
      [list],
    );

    const getRef = useCallback(
      (ref) => {
        if (!ref) return;
        ref.objects.events.add('click', (e) => {
          const objectId = e.get('objectId');

          const curObject = ref.objects.getById(objectId);
          const prevObject = ref.objects.getById(prevClickedPickPointID);

          const logoSelected = curObject?.data?.deliveryCompany?.logoSelected;
          ref.objects.setObjectOptions(objectId, {
            ...(Boolean(logoSelected) && {
              iconImageHref: logoSelected,
            }),
          });

          const prevLogo = prevObject?.data?.deliveryCompany?.logoS;
          ref.objects.setObjectOptions(prevClickedPickPointID, {
            ...(Boolean(prevLogo) && {
              iconImageHref: prevLogo,
            }),
          });

          setPrevClickedPickPointID(objectId);
          selectPickPointOnMap(e);
        });
      },
      [selectPickPointOnMap, prevClickedPickPointID, setPrevClickedPickPointID],
    );

    const placeMarks = useMemo(() => {
      return list.map((pickPoint, index) => {
        const { _raw: raw, deliveryCompany, id } = pickPoint;
        const { logoSelected, logo } = deliveryCompany;

        const coordinates = Object.values(raw.geography.coordinates);
        const iconImageHref = logoSelected && (selectedId === id || chosenId === id) ? logoSelected : logo;

        return {
          type: 'Feature',
          id: index,
          geometry: {
            type: 'Point',
            coordinates,
          },
          data: pickPoint,
          options: {
            iconColor: '#916EB8',
            ...(Boolean(logo) && {
              iconLayout: 'default#image',
              iconImageSize: [40, 40],
              iconImageHref,
            }),
          },
        };
      });
    }, [list, selectedId, chosenId]);

    const closeDropdown = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen]);

    const tabsOffset = tabsRef?.current?.offsetTop + tabsRef?.current?.offsetHeight;
    const style = tabsOffset
      ? {
          height: `calc(100vh - ${tabsOffset}px)`,
          // расстояние до нижней границы строки поиска
          '--search-bottom-offset': `${tabsOffset - 87}px`,
        }
      : {};

    return (
      <Dialog
        title={
          <div className="PickPointDialog-title" onClick={closeDropdown}>
            Пункты самовывоза в городе <b>{cityTitle}</b>
          </div>
        }
        onClose={handleDialogClose}
        noSideInnerIndents
        style={{ paddingTop: 25, paddingBottom: 0 }}
        isOpened={isOpened}
        {...rest}>
        <div className="PickPointDialog">
          <PickPointDialogLoader isMapLoading={(isMapLoading && isOpened) || isMapLoading} />
          <PickPointDialogFilter
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            points={list}
            selectPickPoint={selectPickPoint}
          />
          <PickPointDialogSelectionTabs
            tabsRef={tabsRef}
            isListSelected={isListSelected}
            setIsListSelected={setIsListSelected}
            onClick={closeDropdown}
          />
          {!isMapLoading && (
            <>
              <PickPointDialogTable
                selectedId={selectedId}
                selectPickPoint={selectPickPoint}
                handleSelect={handleSelect}
                style={style}
                chosenId={chosenId}
                list={list}
                isListSelected={isListSelected}
                onClick={closeDropdown}
              />
            </>
          )}
          <PickPointDialogMapWrapper
            setMapElementRef={setMapElementRef}
            mapState={mapState}
            setIsMapLoading={setIsMapLoading}
            placeMarks={placeMarks}
            getRef={getRef}
            selectedPickPoint={selectedPickPoint}
            closePickPointInfo={closePickPointInfo}
            handleSelect={handleSelect}
            style={style}
            selectedId={selectedId}
            chosenId={chosenId}
            setYmaps={setYmaps}
            onClick={closeDropdown}
            isListSelected={isListSelected}
          />
        </div>
      </Dialog>
    );
  },
  isEqual,
);
