import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Loader from 'react-loaders';
import { useSettings } from 'stores';
import { isValidationChestActive } from 'stores/cake-chest';

const IndexPage = React.lazy(() => import('./index-page'));
const ProductPageLazy = React.lazy(() =>
  import('./product-page').then(({ ProductPage }) => ({ default: ProductPage })),
);
const ProductPageForKids = React.lazy(() => import('./product-for-kids-page'));

const BasketPage = React.lazy(() => import('./basket-page'));
const ContactsPage = React.lazy(() => import('./contacts-page'));
const OrderPage = React.lazy(() => import('./order-page'));
const OrderThanksPage = React.lazy(() => import('./order-thanks-page'));
const CatalogPage = React.lazy(() => import('./catalog-page'));
const AgreementsPage = React.lazy(() => import('./agreements-page'));
const CategoryPageLazy = React.lazy(() =>
  import('./category-page').then(({ CategoryPage }) => ({ default: CategoryPage })),
);
const ErrorPage = React.lazy(() => import('./error-page'));
const ReviewsLazy = React.lazy(() => import('./reviews-page').then(({ ReviewsPage }) => ({ default: ReviewsPage })));
const ReviewLazy = React.lazy(() => import('./review-page').then(({ Review }) => ({ default: Review })));
const DeliveryAndPayment = React.lazy(() => import('./delivery-and-payment-page'));
const AboutUs = React.lazy(() => import('./about-us-page'));
const TackOrder = React.lazy(() => import('./track-order-page'));
const PaymentWrong = React.lazy(() => import('./payment-wrong-page'));
const SpecialOfferPage = React.lazy(() => import('./special-offer-page'));
const SpecialOffersPage = React.lazy(() => import('./special-offers-page'));
// const IndexPageForChildren = React.lazy(() => import('./IndexPageForChildren'));
// const ContactsPageForKids = React.lazy(() => import('./AboutUsForKidsPage'));
const PreloaderOrderResultPage = React.lazy(() => import('./preloader-order-result-page'));
const MagnitGifts = React.lazy(() => import('./magnit-gifts-page'));
const CakeAwardPage = React.lazy(() => import('./cake-awards-page'));
const CakesExchangeLazy = React.lazy(() =>
  import('../tsPages/ExchangeGifts').then(({ ExchangeGifts }) => ({
    default: ExchangeGifts,
  })),
);
const CakeChestPage = React.lazy(() => import('./cake-chest-page'));
const ValidationPage = React.lazy(() => import('./validation-page'));
const NewYearDesiresPage = React.lazy(() => import('./new-year-desires-page'));

const SiteFAQLazy = React.lazy(() => import('../tsPages/SiteFAQ').then(({ SiteFAQ }) => ({ default: SiteFAQ })));
const RewardsLazy = React.lazy(() => import('../tsPages/Rewards').then(({ Rewards }) => ({ default: Rewards })));
const BuyLazy = React.lazy(() => import('../tsPages/Buy').then(({ Buy }) => ({ default: Buy })));
const AlfaLazy = React.lazy(() => import('../tsPages/Alfa').then(({ Alfa }) => ({ default: Alfa })));

function getLocationId({ pathname, search, hash }) {
  return pathname + (search ? `?${search}` : '') + (hash ? `#${hash}` : '');
}

const PagesScene = () => {
  const history = useHistory();
  const [{ lotteryAvailable }] = useSettings();
  useEffect(() => {
    history.block(
      (location, action) => action !== 'PUSH' || getLocationId(location) !== getLocationId(history.location),
    );
  }, []);

  return (
    <Suspense
      fallback={<Loader type="line-spin-fade-loader" active color="#916EB8" style={{ transform: 'scale(3)' }} />}>
      <Switch>
        <Route path="/" component={IndexPage} exact />
        <Route path="/detskie" component={() => <Redirect to="/category/vzroslye" />} exact />
        <Route path="/detskie/about-us" component={() => <Redirect to="/category/vzroslye" />} exact />
        <Route path="/product/:slug" component={ProductPageLazy} exact />
        <Route path="/product-for-kids/:slug" component={ProductPageForKids} exact />
        <Route path="/basket" component={BasketPage} exact />
        <Route path="/contacts" component={ContactsPage} exact />
        <Route path="/category/detskie" component={() => <Redirect to="/category/vzroslye" />} exact />
        <Route path="/category/:slug" component={CategoryPageLazy} exact />
        <Route path="/order" component={OrderPage} exact />
        <Route path="/order-thanks" component={OrderThanksPage} exact />
        <Route path="/catalog" component={CatalogPage} exact />
        <Route path="/agreements" component={AgreementsPage} exact />
        <Route path="/reviews" component={ReviewsLazy} exact />
        <Route path="/delivery-and-payment" component={DeliveryAndPayment} exact />
        <Route path="/rewards" exact component={RewardsLazy} />
        <Route path="/review/:id" component={ReviewLazy} exact />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/track-order" exact component={TackOrder} />
        <Route path="/payment_wrong" exact component={PaymentWrong} />
        <Route path="/faq" exact component={SiteFAQLazy} />
        <Route path="/error-404" exact component={ErrorPage} />
        <Route path="/special-offers" component={SpecialOffersPage} />
        <Route path="/special-offer/:id" component={SpecialOfferPage} />
        {(lotteryAvailable || isValidationChestActive) && (
          <Route path="/order-result-preloader" component={PreloaderOrderResultPage} />
        )}
        <Route path="/magnitcosmetic-shops" component={MagnitGifts} />
        <Route path="/cake-chest" component={CakeChestPage} exact />
        <Route path="/cake-awards" component={CakeAwardPage} exact />
        <Route path="/cake-awards/exchange" component={CakesExchangeLazy} exact />
        {isValidationChestActive && <Route path="/validation" component={ValidationPage} exact />}
        <Route path="/new-year-wish" component={NewYearDesiresPage} exact />
        <Route path="/buy" component={BuyLazy} exact />
        <Route path="/alfa" component={AlfaLazy} exact />
        <Route component={() => <Redirect to="/error-404" />} />
      </Switch>
    </Suspense>
  );
};

export default PagesScene;
