import React, { useState, useLayoutEffect, useContext, createContext } from 'react';
import { useScrollBarWidth } from '../hooks/use-scroll-bar-width';

const useHook = () => {
  const [isLocked, setIsLocked] = useState();
  const scrollBarWidth = useScrollBarWidth();

  useLayoutEffect(() => {
    if (!isLocked) return;

    const originalOverflow = window.getComputedStyle(document.body).overflow;
    const originalPaddingRight = window.getComputedStyle(document.body).paddingRight;

    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollBarWidth}px`;

    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.paddingRight = originalPaddingRight;
    };
  }, [scrollBarWidth, isLocked]);

  return [isLocked, { setLock: () => setIsLocked(true), resetLock: () => setIsLocked(false) }];
};

const ScrollLockContext = createContext();
const useScrollLock = () => useContext(ScrollLockContext);

const ScrollLockStore = ({ children }) => (
  <ScrollLockContext.Provider value={useHook()}>{children}</ScrollLockContext.Provider>
);

ScrollLockStore.displayName = 'ScrollLockContext';

export { ScrollLockStore, useScrollLock };
