import React from 'react';
import { Svg } from '../svg/svg';
import './delivery-truck-icon.css';

export const IconDeliveryTruck = () => (
  <Svg className="IconDeliveryTruck">
    <path d="M8.7 26.9c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1zM24.3 26.9c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1z" />
    <path d="M5.6 23.8H2.5V6.7c0-.4.2-.8.5-1.1.3-.3.7-.5 1.1-.5h14v18.7h-6.2 9.3m6.2 0h3.1v-9.3m0-.1H18.1m12.4 0l-4.7-7.8H18" />
  </Svg>
);
