import React from 'react';
import cn from 'classnames';
import { useBasketToastContentOptions } from 'tsHooks/useBasketToastContentOptions';
import { Link, useLocation } from 'react-router-dom';
import { IconCross } from '../../components/icons';
import styles from './styles.module.scss';
import { IconContainer } from '../@ui-kit/IconContainer';

export type TAddToBasketToast = {
  onClose: () => void;
  type?: 'success' | 'failed';
  productTitle: string;
};

export const AddToBasketToast: React.FunctionComponent<TAddToBasketToast> = ({ onClose, type }) => {
  const [title, info] = useBasketToastContentOptions(type);
  const location = useLocation();

  return (
    <Link to={`${location.pathname}?dialog=basket`} className={cn(styles.toast)}>
      <div className={styles.main}>
        <div className={styles.title}>{title}</div>
        <div className={styles.productTitle}>{title}</div>
        <div className={styles.info}>{info}</div>
      </div>
      <div className={styles.close}>
        <button className={styles.closeButton} onClick={onClose}>
          <IconContainer>
            <IconCross />
          </IconContainer>
        </button>
      </div>
    </Link>
  );
};
