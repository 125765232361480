import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'tsComponents/Media';
import Skeleton from 'react-loading-skeleton';
import { BasketPriceGroup } from '../basket-price-group/basket-price-group';
import { Counter } from '../counter/counter';
import { IconContainer } from '../icon-container/icon-container';
import { IconTrash } from '../icons';
import { getProductLink } from '../../routes';
import { useMedia } from '../../hooks';
import { useSettings } from '../../stores';
import { CakeAwardsFeature } from '../cake-awards-feature/cake-awards-feature';
import { BasketPromoGift } from '../basket-promo-gift/basket-promo-gift';
import { useElSize } from '../../tsHooks/useElSize';
import './basket-product.css';

const BasketProductCounter = ({ isLoading, amount, maxQuantity, onAmountChange }) => {
  const [ref, { width }] = useElSize();
  const isMobile = useMedia('(max-width: 420px)');

  const skeletonIconSize = isMobile ? 14 : 18;

  return (
    <div className="BasketProduct-counter">
      <Counter value={amount} max={maxQuantity} ref={ref} onChange={onAmountChange} isFlat isLoading={isLoading} />

      {isLoading ? (
        <div className="BasketProduct-counterSkeleton">
          <Skeleton width={skeletonIconSize} />
          <Skeleton width={width} />
          <Skeleton width={skeletonIconSize} />
        </div>
      ) : null}
    </div>
  );
};

const BasketProductPrice = ({ price, productPrice, amount, isLoading }) => {
  return (
    <div className="BasketProduct-price">
      <BasketPriceGroup price={price} productPrice={productPrice} amount={amount} isLoading={isLoading} />
      {isLoading ? (
        <div className="BasketProduct-priceSkeleton">
          <Skeleton height={'100%'} width={'100%'} />
        </div>
      ) : null}
    </div>
  );
};

const BasketProductTitle = ({ isLoading, isGift, slug, title }) => {
  const [ref, { height }] = useElSize();

  const elHeight = height + 2;

  const LinkComponent = isGift ? 'div' : Link;

  return (
    <div className="BasketProduct-titleWrapper">
      <h3 className={'BasketProduct-title'}>
        <LinkComponent to={!isGift && getProductLink(slug)} className="BasketProduct-link">
          <span style={{ width: '100%' }} ref={ref}>
            {title}
          </span>
        </LinkComponent>
      </h3>
      {isLoading ? (
        <div className="BasketProduct-titleSkeleton">
          <Skeleton height={elHeight} containerClassName={'BasketProduct-skeletonBlock'} />
        </div>
      ) : null}
    </div>
  );
};

const BasketProductMedia = ({ media, isSet, isLoading }) => {
  const paddingTopSkeleton = isSet ? 'calc(74.84% + 5px)' : 'calc(133.77% +  5px)';

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <div className="BasketProduct-skeletonWrapper">
          <div
            style={{
              'padding-top': paddingTopSkeleton,
            }}
            className="BasketProduct-skeletonItem">
            <div className="BasketProduct-skeletonHolder">
              <Skeleton height={'100%'} />
            </div>
          </div>
        </div>
      ) : null}
      {!!media && media.length > 0 && <Media media={media[0]} ratio={isSet ? 0.7484 : 1.3377} />}
    </div>
  );
};

const BasketProductTrashHolder = ({ isLoading, onRemoveClick }) => {
  const isMobile = useMedia('(max-width: 420px)');
  const trashSkeletonSize = isMobile ? 30 : 40;
  const trashBtnSize = isMobile ? 'xsmall' : 'small';

  return (
    <div className="BasketProduct-trashHolder">
      {isLoading ? (
        <Skeleton
          circle
          width={trashSkeletonSize}
          height={trashSkeletonSize}
          containerClassName={'BasketProduct-skeletonBlock'}
        />
      ) : (
        <button className="BasketProduct-trashButton" onClick={onRemoveClick} disabled={isLoading}>
          <IconContainer icon={<IconTrash />} size={trashBtnSize} />
        </button>
      )}
    </div>
  );
};

export const BasketProduct = (props) => {
  const { product, amount, onAmountChange, onRemoveClick, isLoading, price, isGift, row } = props;
  const [{ cakesIncrease, lotteryAvailable }] = useSettings();
  const [maxQuantity, setMaxQuantity] = useState(100);

  const cakeCount = cakesIncrease ? product.cake_count * cakesIncrease : product.cake_count;

  const isProductAvailable = product.is_available;
  const isMaxQuantity = row.maximum_quantity;

  const onRemoveClickCallback = useCallback(() => {
    onRemoveClick(product);
  }, [product, onRemoveClick]);

  const onAmountChangeCallback = useCallback(
    (newAmount) => {
      onAmountChange(product, newAmount);
    },
    [onAmountChange],
  );

  useEffect(() => {
    if (isMaxQuantity) setMaxQuantity(amount);
  }, [isMaxQuantity]);

  if (isGift) {
    return <BasketPromoGift amount={amount} gift={product} />;
  }

  return (
    <div className={`BasketProduct ${!isProductAvailable ? 'not-available' : ''}`}>
      <div className="BasketProduct-mediaCol">
        <BasketProductMedia isLoading={isLoading} isSet={product.isSet} media={product.media} />

        {Boolean(product.cake_count) && lotteryAvailable && !isLoading && (
          <div className="BasketProduct-cakeAwardsFeature">
            <CakeAwardsFeature cakesIncrease={cakesIncrease} cakeCount={cakeCount} />
          </div>
        )}
      </div>

      <div className="BasketProduct-contentCol">
        <div className="BasketProduct-topGroup">
          <BasketProductTitle isGift={isGift} isLoading={isLoading} title={product.title} slug={product.slug} />

          <BasketProductPrice price={price} productPrice={product.price} amount={amount} isLoading={isLoading} />
        </div>

        <div className="BasketProduct-bottomGroup">
          <BasketProductCounter
            isLoading={isLoading}
            amount={amount}
            onAmountChange={onAmountChangeCallback}
            maxQuantity={maxQuantity}
            product={product}
          />

          <BasketProductTrashHolder onRemoveClick={onRemoveClickCallback} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};
