import { capitalizeFirstLetter } from './capitalize-first-letter';

export function choose(suggestion, noSNG) {
  // Инпут -> Дадата -> АПИ
  // Сейчас используем только city_fias_id
  // 1. Московская обл, г Балашиха -> city=Балашиха, settlement=null, settlement_type=null -> Балашиха, city_fias_id
  // 2. Московская обл, г Балашиха, мкр Железнодорожный -> city=Балашиха, settlement=Железнодорожный, settlement_type=мкр -> Балашиха, settlement_fias_id
  // 3. Московская область, город Истра, село Павловская Слобода -> city=Истра, settlement=Павловская Слобода, settlement_type=с -> Павловская Слобода, settlement_fias_id
  // 4. Респ Алтай, село Усть-Кан -> city=null, settlement=Усть-Кан, settlement_type=с -> Усть-Кан, settlement_fias_id
  // 5. г Москва, г Зеленоград, поселок Крюково -> city=Зеленоград, settlement=Крюково, settlement_type=п -> Крюково, settlement_fias_id
  // 6. Ростовская обл, Мясниковский р-н, тер автодорога Ростов-на-Дону - Новошахтинск -> city=null, settlement=автодорога Ростов-на-Дону - Новошахтинск, settlement_type=тер -> автодорога Ростов-на-Дону - Новошахтинск, settlement_fias_id
  // 7. Респ Карелия, г Петрозаводск, р-н Древлянка, ул Университетская -> city=Петрозаводск, settlement=Древлянка, settlement_type=р-н -> Петрозаводск, city_fias_id
  // 8. Московская Область, Видное Город, Сапроново Деревня -> city=Видное, settlement=Сапроново, settlement_type=д -> Сапроново, settlement_fias_id
  // 9. Московская обл, г Подольск, мкр Климовск -> city=Подольск, settlement=Климовск, settlement_type=мкр -> Подольск, settlement_fias_id
  // https://dadata.ru/api/suggest/address/

  const country = suggestion?.country || noSNG?.countryOptions?.country || '';
  const countryId = noSNG?.countryOptions?.id || '';

  if (suggestion?.settlement && suggestion.settlement_type !== 'мкр' && suggestion.settlement_type !== 'р-н') {
    return {
      city: suggestion.settlement,
      fias_id: suggestion.settlement_fias_id,
      country,
      country_id: countryId,
    };
  }

  return {
    city: suggestion?.city || capitalizeFirstLetter(noSNG?.query ?? '') || '',
    fias_id: suggestion?.city_fias_id ?? '',
    country,
    country_id: countryId,
  };
}
