import React, { createContext, useContext } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useActiveOrderStep } from './active-order-step';
import { checkIsSubmitButtonDisabled as checkIsDisabled } from './helpers';
import { requiredEmail } from '../../utils/email';

const formData = ({ firstName, lastName, cheburashka, email }) => ({
  email,
  cheburashka,
  first_name: firstName.trim(),
  last_name: lastName.trim(),
});

const specialCharactersRegExp = new RegExp(`^[^0-9±!@£$%^&*_+§¡€#¢§¶•ªº«»"\`\\/<>?:;|=.,{}()[\\]]+$`);

function checkNameByCharacters(name) {
  if (!specialCharactersRegExp.test(name)) {
    return false;
  }
  return true;
}

const FIRST_NAME_SHORT_ERROR = 'Имя должно содержать не менее 2-х символов';
const LAST_NAME_SHORT_ERROR = 'Фамилия должна содержать не менее 2-х символов';
const NAME_FORMAT_ERROR = 'Имя может содержать кириллицу (а-я) и латиницу (a-z)';
const LAST_NAME_FORMAT_ERROR = 'Фамилия может содержать кириллицу (а-я) и латиницу (a-z)';

const validationSchema = object({
  firstName: string()
    .required('Имя является обязательным полем')
    .trim()
    .min(2, FIRST_NAME_SHORT_ERROR)
    .test('first-name-format', NAME_FORMAT_ERROR, checkNameByCharacters),
  lastName: string()
    .required('Фамилия является обязательным полем')
    .trim()
    .min(2, LAST_NAME_SHORT_ERROR)
    .test('last-name-format', LAST_NAME_FORMAT_ERROR, checkNameByCharacters),
  cheburashka: string().required('Укажите номер телефона').min(12, 'не достаточное кол-во цифр'),
  // .test("phone-test", "не достаточное кол-во цифр", (value)=>{
  //   if(value.length < 12){
  //     return false
  //   }
  //   return true
  // })
  // .test("cheburashka-test", "не достаточное кол-во цифр", (value)=>{
  // console.log("cheburashka-test", value)
  //   if(value.length < 12){
  //     return false
  //   }
  //   return true
  // })
  // .test("recipient-phone", "Укажите номер полностью", (phone ="")=>{
  // console.log(
  //   `_________phone: %c${phone}`,
  //   "font-weight: bold;font-size: large; color:red"
  // );
  //   const cleanedValue = phone.replace(/\D/g, "");
  //   if (cleanedValue.length < 10) {
  //     return false;
  //   }

  //   return true;
  // })
  email: requiredEmail(),
});

const initialValues = { firstName: '', lastName: '', cheburashka: '', email: '' };

const useHook = () => {
  const [, { goToNextStep }] = useActiveOrderStep();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      setFieldValue('lastName', values.lastName.trim());
      setFieldValue('firstName', values.firstName.trim());

      goToNextStep();
    },
  });

  function localHandleChange(e) {
    const name = e?.target?.name;
    const value = e?.target?.value;
    // const hasError = e?.target?.hasError;

    if (name === 'cheburashka') {
      setFieldValue('cheburashka', value);
    }

    handleChange(e);
  }

  return [
    {
      data: formData(values),
      values,
      errors,
      touched,
      isButtonDisabled: checkIsDisabled(errors, touched),
    },
    {
      handleChange: localHandleChange,
      handleSubmit,
      resetData: () => resetForm({ values: initialValues, errors: {}, touched: {} }),
    },
  ];
};

const OrderPersonalDataContext = createContext();

export const useOrderPersonalData = () => useContext(OrderPersonalDataContext);

export const OrderPersonalDataStore = ({ children }) => (
  <OrderPersonalDataContext.Provider value={useHook()}>{children}</OrderPersonalDataContext.Provider>
);

OrderPersonalDataContext.displayName = 'OrderPersonalDataContext';
