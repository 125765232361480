import React, { useReducer, useContext, createContext } from 'react';
import { bindActionsToDispatch, createAction } from './utils';

const SET_SUBSCRIBE_ID = 'INFORM_AVAILABILITY/SET_SUBSCRIBE_ID';
const SET_SUBSCRIBE_VIEW_TYPE = 'INFORM_AVAILABILITY/SET_SUBSCRIBE_VIEW_TYPE';
const SET_ALREADY_SUBSCRIBED_VIEW_TYPE = 'INFORM_AVAILABILITY/SET_ALREADY_SUBSCRIBED_VIEW_TYPE';
const SET_SUCCESS_SUBSCRIBED_VIEW_TYPE = 'INFORM_AVAILABILITY/SET_SUCCESS_SUBSCRIBED_VIEW_TYPE';

const actions = {
  setSubscribeId: (id) => createAction(SET_SUBSCRIBE_ID, id),
  setSubscribeViewType: () => createAction(SET_SUBSCRIBE_VIEW_TYPE),
  setAlreadySubscribedViewType: () => createAction(SET_ALREADY_SUBSCRIBED_VIEW_TYPE),
  setSuccessSubscribedViewType: () => createAction(SET_SUCCESS_SUBSCRIBED_VIEW_TYPE),
};

const initialState = {
  id: '',
  viewType: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SUBSCRIBE_ID: {
      return {
        ...state,
        id: payload,
      };
    }

    case SET_SUBSCRIBE_VIEW_TYPE: {
      return {
        ...state,
        viewType: 'subscribe',
      };
    }

    case SET_ALREADY_SUBSCRIBED_VIEW_TYPE: {
      return {
        ...state,
        viewType: 'already_subscribed',
      };
    }

    case SET_SUCCESS_SUBSCRIBED_VIEW_TYPE: {
      return {
        ...state,
        viewType: 'subscribed',
      };
    }

    default:
      return state;
  }
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { id, viewType } = state;

  const {
    setSubscribeId,
    setSubscribeViewType,
    setAlreadySubscribedViewType,
    setSuccessSubscribedViewType,
  } = bindActionsToDispatch(actions, dispatch);

  return [
    { id, viewType },
    { setSubscribeId, setSubscribeViewType, setAlreadySubscribedViewType, setSuccessSubscribedViewType },
  ];
};

const AvailabilityInformContext = createContext();

export const useAvailabilityInformInfo = () => {
  return useContext(AvailabilityInformContext);
};

export const AvailabilityInformInfoContextStore = ({ children }) => (
  <AvailabilityInformContext.Provider value={useHook()}>{children}</AvailabilityInformContext.Provider>
);

AvailabilityInformContext.displayName = 'AvailabilityInformContext';
