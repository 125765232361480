import React from 'react';
import { Svg } from '../svg/svg';
import './with-love-icon.css';

export const IconWithLove = () => (
  <Svg className="IconWidthLove">
    <path d="M13.5009 30.0877C12.6985 30.0877 11.9432 29.7748 11.3762 29.2101L6.52439 24.3583C6.20439 24.0406 6.03027 23.6171 6.03027 23.1677C6.03027 22.7183 6.20439 22.2948 6.52439 21.9771C6.84204 21.6595 7.26322 21.4853 7.71498 21.4853C8.16439 21.4853 8.58792 21.6618 8.90557 21.9771L11.2162 24.2006L11.2844 24.0453C11.5385 23.4806 12.1009 23.1183 12.7173 23.1183H15.9926C17.1173 22.283 18.4256 21.8383 19.7503 21.8383C21.0397 21.8383 22.4091 22.4147 23.5103 23.4242L23.5691 23.4783L28.1056 21.3183C28.1456 21.2994 28.1879 21.29 28.2303 21.29C28.3432 21.29 28.4467 21.3559 28.4938 21.4571C28.5644 21.603 28.5009 21.7771 28.3573 21.8453L23.635 24.0924C23.595 24.1112 23.5526 24.1206 23.5103 24.1206C23.4326 24.1206 23.3597 24.0901 23.3032 24.0359C22.275 23.0101 20.9785 22.4218 19.7503 22.4218C18.5032 22.4218 17.282 22.8524 16.315 23.6336L16.2232 23.6806L16.122 23.6994L12.715 23.6971C12.1715 23.6971 11.7267 24.1418 11.7267 24.6853C11.7267 25.2312 12.1691 25.6736 12.715 25.6736H17.8303C17.9926 25.6736 18.122 25.803 18.122 25.9653C18.122 26.1277 17.9903 26.2571 17.8303 26.2571H12.715C11.9715 26.2571 11.3244 25.7277 11.1762 25.0006L8.49616 22.3936C8.28675 22.1842 8.00674 22.0689 7.71027 22.0689C7.41615 22.0689 7.14086 22.1842 6.9338 22.3912C6.50557 22.8194 6.50557 23.5183 6.9338 23.9465L11.7856 28.7983C12.242 29.2548 12.8514 29.5089 13.4985 29.5089C16.9032 29.5089 23.3409 27.6547 28.1409 26.0995C28.1573 26.0995 28.1738 26.0995 28.1879 26.0995C28.3738 26.0995 28.4726 26.1771 28.5079 26.2877C28.5573 26.4406 28.475 26.6053 28.3197 26.6547C23.4797 28.2195 16.9762 30.0877 13.5009 30.0877Z" />
    <path d="M3.58583 10.9182C3.47289 10.9182 3.36936 10.8523 3.3223 10.7511C3.25406 10.6052 3.31524 10.4311 3.46112 10.3629L8.18112 8.11346C8.22112 8.09463 8.26348 8.08522 8.30818 8.08522C8.38583 8.08522 8.45877 8.11581 8.51289 8.16992C9.54347 9.19816 10.8376 9.78639 12.0682 9.78639C13.3152 9.78639 14.5364 9.35581 15.5035 8.57464L15.5952 8.52758L15.6964 8.50875L19.1035 8.5111C19.647 8.5111 20.0917 8.06875 20.0917 7.52286C20.0917 6.97933 19.6494 6.53464 19.1035 6.53464H13.9882C13.8282 6.53464 13.6964 6.40522 13.6964 6.24286C13.6964 6.08051 13.8282 5.9511 13.9882 5.9511H19.1035C19.847 5.9511 20.4941 6.48052 20.6423 7.20993L23.3247 9.81698C23.5341 10.0264 23.8117 10.1417 24.1082 10.1417C24.4023 10.1417 24.6776 10.0287 24.8847 9.81934C25.3129 9.3911 25.3129 8.69228 24.8847 8.26405L20.0329 3.41228C19.5741 2.95581 18.967 2.70169 18.3199 2.70169C14.9199 2.70169 8.47995 4.5558 3.67995 6.1111C3.66348 6.1111 3.64701 6.1111 3.63289 6.1111C3.44701 6.1111 3.34819 6.03346 3.31289 5.92287C3.26348 5.76993 3.34818 5.60287 3.50112 5.55581C8.34348 3.98875 14.847 2.11816 18.3223 2.11816C19.1247 2.11816 19.88 2.4311 20.447 2.99816L25.2988 7.85228C25.6164 8.16993 25.7929 8.59346 25.7929 9.04287C25.7929 9.49228 25.6164 9.91581 25.2988 10.2335C24.9811 10.5511 24.5576 10.7276 24.1082 10.7276C23.6588 10.7276 23.2352 10.5535 22.9176 10.2335L20.607 8.01228L20.5388 8.16758C20.2846 8.73229 19.7223 9.09698 19.1058 9.09698H15.8305C14.7058 9.92992 13.3976 10.3746 12.0729 10.3746C10.7835 10.3746 9.41406 9.79581 8.31524 8.78875L8.25642 8.73464L3.71995 10.8946C3.67053 10.9088 3.63053 10.9182 3.58583 10.9182Z" />
    <path d="M15.7667 21.8366C13.3855 20.5025 11.8679 19.1684 10.6914 17.3731C9.5126 15.3848 9.59966 13.526 10.9244 12.2601C11.6279 11.5872 12.5597 11.2178 13.5479 11.2178C14.3832 11.2178 15.1949 11.4931 15.8349 11.9895L15.9079 12.046L15.9808 11.9895C16.6208 11.4931 17.4326 11.2178 18.2655 11.2178C19.2561 11.2178 20.1879 11.5872 20.8914 12.2601C22.2161 13.526 22.3032 15.3848 21.1314 17.3613C19.9479 19.1684 18.4302 20.5025 16.0491 21.8366L15.9079 21.9166L15.7667 21.8366ZM13.5503 11.799C12.7126 11.799 11.9244 12.1119 11.3291 12.6813C9.81142 14.1307 10.5855 16.0437 11.1832 17.0554C12.2773 18.7237 13.6749 19.966 15.8514 21.2131L15.9102 21.2484L15.9691 21.2131C18.1455 19.966 19.5408 18.7237 20.6326 17.0601C21.2326 16.0437 22.0067 14.1331 20.4891 12.6813C19.8938 12.1119 19.1055 11.799 18.2679 11.799C17.4491 11.799 16.6844 12.1001 16.1103 12.6484L15.9079 12.8413L15.7055 12.6484C15.1338 12.1001 14.3691 11.799 13.5503 11.799Z" />
    <path d="M19.4868 16.6334C19.428 16.6334 19.3692 16.6146 19.3198 16.5816C19.1951 16.4946 19.1621 16.3181 19.2445 16.1769C19.7551 15.2993 19.8633 14.6052 19.5645 14.1204C19.4986 14.0122 19.5151 13.871 19.6068 13.7769C19.6633 13.7204 19.7362 13.6875 19.8139 13.6875C19.9174 13.6875 20.0139 13.7416 20.0657 13.8287C20.4774 14.5181 20.3668 15.4122 19.7362 16.4899C19.6868 16.5793 19.5904 16.6334 19.4868 16.6334Z" />
    <path d="M17.1319 19.1316C17.0401 19.1316 16.9554 19.0893 16.899 19.0164C16.8025 18.8869 16.826 18.7058 16.9554 18.6069C17.5084 18.1858 17.9837 17.7505 18.4048 17.2775C18.459 17.2164 18.539 17.1787 18.6213 17.1787C18.6919 17.1787 18.7625 17.2046 18.8166 17.254C18.8754 17.3034 18.9084 17.3764 18.9131 17.454C18.9178 17.5316 18.8919 17.6069 18.8401 17.6634C18.3931 18.1646 17.8919 18.6258 17.3084 19.0705C17.2566 19.1128 17.1954 19.1316 17.1319 19.1316Z" />
  </Svg>
);
