import React, { useReducer, useContext, createContext, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';
import { get404Link } from '../routes';
import { productNormalize, productDetailsNormalize } from './normalizers/normalizers';

// Helpers

const getItemBySlug = (categories, slug) => {
  return categories[slug] || null;
};

export function calculateDiscount(price) {
  const { discount } = price;

  if (!discount) {
    return 0;
  }

  if (discount.numerical) {
    return discount.numerical;
  }

  if (discount.percentage) {
    return price.default * discount.percentage;
  }

  return 0;
}

// Selectors

// Types
const CATEGORY_RESPONSE = 'PRODUCT/CATEGORY_RESPONSE';

const CATEGORY_PRODUCTS_REQUEST = 'PRODUCT/CATEGORY_PRODUCTS_REQUEST';
const CATEGORY_PRODUCTS_RESPONSE = 'PRODUCT/CATEGORY_PRODUCTS_RESPONSE';

const PRODUCTS_REQUEST = 'PRODUCT/PRODUCTS_REQUEST';
const PRODUCTS_RESPONSE = 'PRODUCT/PRODUCTS_RESPONSE';

const PRODUCTS_DETAILS_REQUEST = 'PRODUCT/PRODUCTS_DETAILS_REQUEST';
const PRODUCTS_DETAILS_RESPONSE = 'PRODUCT/PRODUCTS_DETAILS_RESPONSE';

const UPDATE_CATEGORY_SLICE = 'PRODUCT/UPDATE_CATEGORY_SLICE';
const RELOAD_CATEGORY_SLICE = 'PRODUCT/RELOAD_CATEGORY_SLICE';

const actions = {
  categoryResponse: (category) => createAction(CATEGORY_RESPONSE, { category }),

  categoryProductsRequest: (slug, offset) => createAction(CATEGORY_PRODUCTS_REQUEST, { slug, offset }),
  categoryProductsResponse: (productsData, slug) => createAction(CATEGORY_PRODUCTS_RESPONSE, { productsData, slug }),

  productRequest: (slug) => createAction(PRODUCTS_REQUEST, { slug }),
  productResponse: (product, slug) => createAction(PRODUCTS_RESPONSE, { product, slug }),

  productDetaildRequest: (slug) => createAction(PRODUCTS_DETAILS_REQUEST, { slug }),
  productDetailsResponse: (productDetails, slug) => createAction(PRODUCTS_DETAILS_RESPONSE, { productDetails, slug }),

  updateSlice: () => createAction(UPDATE_CATEGORY_SLICE),
  reloadSlice: () => createAction(RELOAD_CATEGORY_SLICE),
};

function getProductsMapFromList(list) {
  return list.reduce(
    (map, product) => ({
      ...map,
      [product.slug]: product,
    }),
    {},
  );
}

const initialState = {
  categories: {},
  categoryProducts: {},
  products: {},
  isProductsLoading: false,
  productsDetails: {},
  categorySliceLength: 16,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PRODUCTS_RESPONSE: {
      const { product, slug } = payload;
      const normalizedProduct = productNormalize(product);
      return {
        ...state,
        products: {
          ...state.products,
          [slug]: normalizedProduct,
        },
      };
    }
    case PRODUCTS_DETAILS_RESPONSE: {
      const { productDetails, slug } = payload;
      // const normalizedProduct = productNormalize(product)
      return {
        ...state,
        productsDetails: {
          ...state.productsDetails,
          [slug]: productDetailsNormalize(productDetails),
          // [slug]:  productDetails
        },
      };
    }

    case CATEGORY_RESPONSE: {
      const { category } = payload;

      return {
        ...state,
        categories: {
          ...state.categories,
          [category.slug]: category,
        },
      };
    }

    case CATEGORY_PRODUCTS_REQUEST: {
      return {
        ...state,
        isProductsLoading: true,
      };
    }

    case CATEGORY_PRODUCTS_RESPONSE: {
      const { productsData, slug } = payload;
      const { products, amount, total_amount } = productsData;
      const normalizedProducts = products.map((element) => productNormalize(element));

      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts,
          [slug]: {
            list: [
              ...(state.categoryProducts[slug]?.list ? state.categoryProducts[slug].list : []),
              ...products.map((product) => product.slug),
              // ...new Set(products.map((product) => product.slug))
            ],
            // list: products.map((product) => product.slug),
            amount,
            totalAmount: total_amount,
          },
        },
        products: {
          ...state.products,
          ...getProductsMapFromList(normalizedProducts),
        },
        isProductsLoading: false,
      };
    }

    case UPDATE_CATEGORY_SLICE: {
      return {
        ...state,
        categorySliceLength: state.categorySliceLength + 16,
      };
    }

    case RELOAD_CATEGORY_SLICE: {
      return {
        ...state,
        categorySliceLength: 16,
      };
    }

    default:
      return state;
  }
};

const manageCategoryResponse = (categoryResponse, data) => {
  categoryResponse({
    ...data,
    banner_desktop: data.banner_desktop?.id ? data.banner_desktop : null,
    banner_mobile: data.banner_mobile?.id ? data.banner_mobile : null,
  });
};

// const requestCategoryProducts = (categoryProductsResponse, slug) => {
//   sammyBeautyApi()
//     .categoryProducts(slug)
//     .then((products) => categoryProductsResponse(products, slug))
//     .catch(() => {});
// };

function getFirstSetFromCategory(category) {
  if (!category || !category.list || category.list === 0) {
    return null;
  }
  return category.list.find((product) => product.isSet);
}

const returnTrimProductListWithoutSet = (products) => {
  if (!products) {
    return null;
  }

  const productsWithoutSet = products.filter((item) => !item.isSet);

  if (productsWithoutSet.length < 7) {
    return productsWithoutSet;
  }

  productsWithoutSet.length = 6;

  return productsWithoutSet;
};

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const { categories, products, productsDetails, categoryProducts, isProductsLoading, categorySliceLength } = state;
  const location = useLocation();

  let slug = useMemo(
    () => location.pathname.split('/')[2] || location.pathname.replace('/' || 'category/', '') || 'vzroslye',
    [location.pathname],
  );

  if (slug === 'sammy-beauty-lottery') {
    slug = 'detskie';
  }

  const category = useMemo(() => getItemBySlug(categories, slug), [categories, slug]);
  const product = useMemo(() => getItemBySlug(products, slug), [products, slug]);
  const productDetails = useMemo(() => getItemBySlug(productsDetails, slug), [productsDetails, slug]);

  const categoryProductsBySlug = useMemo(() => getItemBySlug(categoryProducts, slug), [categoryProducts, slug]);

  const categoryFullProducts = useMemo(
    () => ({
      ...categoryProductsBySlug,
      list: categoryProductsBySlug?.list.map((p) => products[p]),
    }),
    [categoryProductsBySlug, products],
  );

  const categoryProductsSlice = useMemo(
    () => ({
      ...categoryFullProducts,
      list: categoryFullProducts?.list?.slice(0, categorySliceLength),
    }),
    [categoryFullProducts, categorySliceLength],
  );

  const firstSetFromCategory = useMemo(() => getFirstSetFromCategory(categoryFullProducts), [categoryFullProducts]);
  // const isProductsLoading = useMemo(()=>)

  const {
    categoryResponse,

    categoryProductsResponse,
    categoryProductsRequest,

    productRequest,
    productResponse,

    productDetaildRequest,
    productDetailsResponse,

    updateSlice,
    reloadSlice,
  } = bindActionsToDispatch(actions, dispatch);

  useEffect(() => {
    reloadSlice();
  }, [slug]);

  const loadMore = useCallback(
    ({ slug: localSlug, offset }) => {
      categoryProductsRequest(localSlug, offset);
      sammyBeautyApi()
        .categoryProducts(localSlug, offset)
        .then((localProducts) => categoryProductsResponse(localProducts, localSlug))
        .catch((error) => console.warn(error));
      // .then((products) => console.log(products))
    },
    [categoryProductsRequest, categoryProductsResponse],
  );

  const requestCategory = useCallback(
    (localSlug) => {
      sammyBeautyApi()
        .category(localSlug)
        .then((data) => manageCategoryResponse(categoryResponse, data))
        .then(() => loadMore({ slug: localSlug }))
        // .then(requestCategoryProducts(categoryProductsResponse, slug))
        .catch((error) => {
          console.warn('requestCategory -> e', error);
          history.push(get404Link());
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  const requestProduct = useCallback((localSlug) => {
    productRequest();
    sammyBeautyApi()
      .product(localSlug)
      // .then((data)=> manageCategoryResponse(categoryResponse, data))
      .then((data) => productResponse(data, localSlug))
      .catch((error) => {
        console.warn('requestCategory -> e', error);
        history.push(get404Link());
      });
    // .then(()=>loadMore({slug}))
    // // .then(requestCategoryProducts(categoryProductsResponse, slug))
    // .catch((e)=>{
    //   console.log("requestCategory -> e", e)
    //   history.push(get404Link())
    // })
    // }, [categoryResponse, history, loadMore]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const requestProductDetails = useCallback((localSlug) => {
    productDetaildRequest();
    sammyBeautyApi()
      .productDetails(localSlug)
      // .then((data)=> manageCategoryResponse(categoryResponse, data))
      .then((data) => productDetailsResponse(data, localSlug))
      .catch((error) => {
        console.warn('requestCategory -> e', error);
        // history.push(get404Link())
      });
    // .then((data)=> console.log(data, slug))
    // .then(()=>loadMore({slug}))
    // // .then(requestCategoryProducts(categoryProductsResponse, slug))
    // .catch((e)=>{
    //   console.log("requestCategory -> e", e)
    //   history.push(get404Link())
    // })
    // }, [categoryResponse, history, loadMore]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    {
      slug,
      category,
      categoryProducts: categoryProductsSlice,
      isProductsLoading,
      product,
      products,
      productDetails,
      firstSetFromCategory,
      categorySliceLength,
    },
    {
      requestCategory,
      loadMore,
      updateSlice,
      requestProduct,
      requestProductDetails,
      returnTrimProductListWithoutSet,
    },
  ];
};

const ProductContext = createContext();

export const useProduct = () => {
  return useContext(ProductContext);
};

export const Product = ({ children }) => (
  <ProductContext.Provider value={useHook()}>{children}</ProductContext.Provider>
);
ProductContext.displayName = 'ProductContext';
