const markFailedRequest = (e) => {
  const error = new Error(e);
  error.isFailed = true;

  throw error;
};

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  let errorData = await response.text();
  try {
    errorData = JSON.parse(errorData);
  } catch (error) {
    console.log(error);
  }

  const error = new Error(`Status code is ${response.status}`);
  error.response = errorData;
  throw error;
};

const parseJson = (response) =>
  response.json().then(({ data, ...rest }) => {
    if (!data) {
      return null;
    }
    const localData = data;
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    data.__row = rest;
    return localData;
  });

const parseJsonList = (response) => {
  return response.json().then(({ list, ...rest }) => {
    if (!list) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
    const _data = list;
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    list.__row = rest;
    return _data;
  });
};

const getHeaders = (headers = {}) => ({
  ...headers,
  accept: 'application/json',
  'content-type': 'application/json',
  authorization: 'Basic bWl4aXRuZXc6eUIlXismVkY=',
});

const getCID = (name) => {
  if (typeof window.ga === 'function' && typeof window.ga.getAll === 'function')
    return window.ga.getAll().map((tracker) => tracker.get('clientId'))[0];

  const matches = document.cookie.match(
    // eslint-disable-next-line unicorn/better-regex
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]).slice(6) : undefined;
};

export { markFailedRequest, checkStatus, parseJson, parseJsonList, getHeaders, getCID };
