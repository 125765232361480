function calculateDiscount(price) {
  const { discount } = price;

  if (!discount) {
    return 0;
  }

  if (discount.numerical) {
    return discount.numerical;
  }

  if (discount.percentage) {
    return price.default * discount.percentage;
  }

  return 0;
}

export function mediaNormalize(media) {
  return {
    ...media,
    sources: media.sources.map((source) => source.main.normal),
    _sources: [...media.sources],
  };
}

function normalizePrice(price) {
  const discount = calculateDiscount(price);
  const currentPrice = price.default - discount;
  return {
    ...price,
    discount,
    current: currentPrice,
    default: price.default,
    previous: currentPrice + discount,
    percentageDiscount: price.discount.percentage,
  };
}

export const productNormalize = (product) => {
  const { features, variant_options, is_available, is_running_out, is_set, price, ...rest } = product;

  const media = product.media.map((m) => mediaNormalize(m));

  const labels = [
    ...features.filter((feature) => feature.type === 'FEATURE/NEW' || feature.type === 'FEATURE/CONSUMERS_CHOICE'),
  ];

  const discount = calculateDiscount(price);
  const currentPrice = price.default - discount;
  const relativeDiscount = 1 - currentPrice / price.default;
  const hasDiscount = discount > 0;
  const sizeVariant = variant_options && variant_options.length > 0 ? variant_options : null;

  const discountString = `${Math.round(relativeDiscount * 100)}%`;

  if (hasDiscount) {
    labels.push({
      type: 'FEATURE/SALE',
      title: `−${discountString}`,
      description: `Данный товар предоставляется со скидкой ${discountString}`,
    });
  }

  return {
    ...rest,
    variant_options,
    is_available,
    is_running_out,
    labels,
    isAvailable: is_available,
    isRunningOut: is_running_out,
    ...(media && { media }),
    is_set,
    isSet: is_set,
    price: normalizePrice(price),
    sizeVariant,
  };
};

const mapIncludeChunk = (products, details) => {
  if (!products || !details) {
    return null;
  }

  return products.map((item) => {
    const itemDetails = details.find((detailsItem) => detailsItem.id === item.id);

    return itemDetails ? { ...item, ...itemDetails } : { ...item };
  });
};

const includeProductNormalize = (product) => {
  const media = product?.media ? product.media.map((m) => mediaNormalize(m)) : null;
  const title = product?.title ? product.title : null;
  const id = product?.id ? product.id : null;

  return { id, media, title };
};

const includeProductDetailsNormalize = (details) => {
  const id = details?.id ? details.id : null;
  const description = details?.about ? details.about : null;
  const usage = details?.usage?.description ? details.usage.description : null;
  const consistency = details?.consistency?.ingredients_raw ? details.consistency.ingredients_raw : null;

  return { id, description, usage, consistency };
};

export function instagramPostNormalize(post) {
  return {
    id: post.instagram_media_id,
    link: post.link,
    media: mediaNormalize(post.media),
    _row: post,
  };
}

export function productDetailsNormalize(details) {
  return {
    options: {
      usage: {
        slug: 'usage',
        title: details?.usage?.title,
        content: details?.usage?.description,
      },
      consistency: {
        slug: 'consistency',
        title: 'Состав',
        content: details?.consistency?.ingredients_raw,
      },
    },
    instagramReviews: details?.instagram_reviews?.map(instagramPostNormalize),
    inculdedProduct: mapIncludeChunk(
      details?.included_products?.map(includeProductNormalize),
      details?.included_products_details?.map(includeProductDetailsNormalize),
    ),
    _raw: details,
  };
}

export function basketNormalize(rowBasket) {
  const { goods_list, promos } = rowBasket;
  const productList = goods_list.map((item) => {
    return {
      ...item,
      amount: item.quantity,
      // price: normalizePrice(item.price),
      price: normalizePrice(item.price),

      product: productNormalize(item.product),
    };
  });
  return {
    productList,
    promocode: promos,
    _rowBasket: rowBasket,
  };
}

export function exchangeGiftNormalize(gift) {
  const { name, id, exchange_possible, product_id, picture } = gift;

  return {
    id,
    productId: product_id,
    name,
    exchangePossible: exchange_possible,
    picture,
    media: picture
      ? [
          {
            title: '',
            type: 'IMAGE',
            sources: [`${picture}`],
          },
        ]
      : [],
  };
}

export function exchangeProductsNormalize(product) {
  const { name, id, quantity, maximum_quantity, is_available, is_set, picture } = product;

  return {
    id,
    name,
    quantity,
    maximumQuantity: maximum_quantity,
    isAvailable: is_available,
    isSet: is_set,
    media: picture
      ? [
          {
            title: '',
            type: 'IMAGE',
            sources: [`${picture}`],
          },
        ]
      : [],
  };
}
