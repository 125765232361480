import Axios from 'axios';

export const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_SUFFIX = process.env.REACT_APP_API_SUFFIX_4;

export enum EApiAction {
  BDFileUpload = 'landing/magnit/receipt',
  BDFormSend = 'landing/magnit/register',
  RewardsFetch = 'certificate',
  FaqFetch = 'faq',
  Partners = 'partners',
}

export const getApiUrl = (action: EApiAction) => `/${API_SUFFIX}/${action}`;
export const axiosDefaultInstance = Axios.create({ baseURL: `${API_PROTOCOL}://${API_HOST}` });
