import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { bindActionsToDispatch, createAction } from './utils';
import { sammyBeautyApi } from '../service';
import { productNormalize } from './normalizers/normalizers';

const SPECIAL_OFFER_RESPONSE = 'SPECIAL_OFFER/RESPONSE';
const SPECIAL_OFFER_ERROR = 'SPECIAL_OFFER/ERROR';

const normalizeOrder = (order) => {
  if (!order) {
    return null;
  }

  const bannerDesktop =
    order?.bannerDesktop?.sources && order?.bannerDesktop?.sources[0]?.main?.double
      ? order.bannerDesktop.sources[0].main.double
      : null;
  const bannerMobile =
    order?.bannerMobile?.sources && order?.bannerMobile?.sources[0]?.main?.double
      ? order.bannerMobile.sources[0].main.double
      : null;
  const products = { list: order?.products?.map(productNormalize), amount: order?.products?.length };
  const shortDescription = order?.short_description ? order?.short_description : null;

  return { ...order, shortDescription, bannerDesktop, bannerMobile, products };
};

const initialState = {
  specialOffer: null,
  isLoaded: false,
};

const actions = {
  specialOfferResponse: (data) => {
    return createAction(SPECIAL_OFFER_RESPONSE, { specialOfferResponse: data });
  },

  specialOfferError: () => createAction(SPECIAL_OFFER_ERROR),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SPECIAL_OFFER_RESPONSE: {
      const { specialOfferResponse } = payload;

      return {
        specialOffer: payload && normalizeOrder(specialOfferResponse),
        isLoaded: true,
      };
    }

    case SPECIAL_OFFER_ERROR: {
      return {
        isLoaded: false,
      };
    }

    default:
      return state;
  }
};

function getNameFromUri(uri) {
  return uri.split('/').reverse()[0];
}

const useHook = () => {
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { specialOffer, isLoaded } = state;

  const { specialOfferResponse, specialOfferError } = bindActionsToDispatch(actions, dispatch);

  const requestSpecialOffer = useCallback(() => {
    sammyBeautyApi()
      .specialOffers(getNameFromUri(pathname))
      .then((data) => {
        return specialOfferResponse(data);
      })
      .catch((error) => {
        console.warn('requestCategory -> e', error);
        specialOfferError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [{ specialOffer, isLoaded }, { requestSpecialOffer }];
};

const SpecialOfferContext = createContext();

export const useSpecialOffer = () => {
  return useContext(SpecialOfferContext);
};

export const SpecialOfferStore = ({ children }) => (
  <SpecialOfferContext.Provider value={useHook()}>{children}</SpecialOfferContext.Provider>
);

SpecialOfferContext.displayName = 'InstagramReviewsContext';
