import React from 'react';
import cn from 'classnames';
import ReactMedia from 'react-media';
import { IconContainer } from '../icon-container/icon-container';
import './button.scss';

export const Button = ({
  title,
  component: ButtonComponent = 'button',
  type = 'button',
  appendIcon = null,
  isExpanded = null,
  isDisabled = null,
  isPrimary = null,
  size = '',
  variant = '',
  isPrimaryActive = null,
  className = '',
  onClick = null,
  ...rest
}) => (
  <ButtonComponent
    className={cn(
      'Button',
      `Button--size-${size}`,
      `Button--variant-${variant}`,
      {
        'Button--expanded': isExpanded,
        'Button--disabled': isDisabled,
        'Button--primary': isPrimary,
        'Button--primaryActive': isPrimaryActive,
      },
      className,
    )}
    disabled={isDisabled}
    type={type}
    onClick={onClick}
    {...rest}>
    <span className="Button-text">{title}</span>

    {appendIcon && (
      <span className="Button-icon">
        <ReactMedia query="(max-width: 640px)">
          {(isMobile) => <IconContainer size={isMobile ? 'xsmall' : 'small'}>{appendIcon}</IconContainer>}
        </ReactMedia>
      </span>
    )}
  </ButtonComponent>
);
