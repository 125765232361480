import React from 'react';
import cn from 'classnames';

import { WhiteSpace } from 'utils/constants';
import { useMedia } from 'hooks';

import { Button } from '../button/button';
import { Logo } from '../logo/logo';

import image1280 from '../../seeds/inform_availability_success1280.jpg';
import image320 from '../../seeds/inform_availability_success320.jpg';

export const InformAvailabilitySuccessView = ({ isNotSubscribed, close }) => {
  const isMobile = useMedia('(max-width: 840px)');

  return (
    <div className="InformAvalability-wrapperSuccess">
      <div className="InformAvalability-containerSuccess">
        <div className="InformAvalability-contentSection">
          <Logo />
          <h2 className="InformAvalability-title">Спасибо за&nbsp;заявку!</h2>
          <p
            className={cn('InformAvalability-description', {
              'InformAvalability-description-repeat': !isNotSubscribed,
            })}>
            {isNotSubscribed ? (
              <>
                Мы сообщим как
                <WhiteSpace />
                только товар появится в<WhiteSpace />
                наличии
              </>
            ) : (
              <>
                Мы
                <WhiteSpace />
                уже
                <WhiteSpace />
                получили вашу заявку и<WhiteSpace />
                обязательно свяжемся с<WhiteSpace />
                вами при
                <WhiteSpace />
                поступлении товара в<WhiteSpace />
                магазин
              </>
            )}
          </p>
          <div className="InformAvalability-imageSection">
            <img className="InformAvalability-imageDesktop" src={image1280} />
            <img className="InformAvalability-imageMobile" src={image320} />
          </div>
          <Button title="Хорошо" variant={'hoverViolet'} isExpanded={!isMobile} onClick={close} />
        </div>
      </div>
    </div>
  );
};
