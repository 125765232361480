import React, { useEffect, useContext, createContext, useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { useMedia, useModalScrollLock } from '../hooks';
import { getBasketLink, getIndexLink } from '../routes';

const parseQuery = (search) => {
  const queryString = search.slice(1); // remove "?";
  return qs.parse(queryString);
};

const MODAL_VARIANTS = {
  LEFT_SIDE_DIALOG: 'MODAL_VARIANTS/LEFT_SIDE_DIALOG',
  RIGHT_SIDE_DILAOG: 'MODAL_VARIANTS/RIGHT_SIDE_DILAOG',
  DILAOG: 'MODAL_VARIANTS/DILAOG',
};

const MENU = {
  name: 'menu',
  variant: MODAL_VARIANTS.LEFT_SIDE_DIALOG,
};

const BASKET = {
  name: 'basket',
  variant: MODAL_VARIANTS.RIGHT_SIDE_DILAOG,
};

const PICK_POINT = {
  name: 'pick-point',
  variant: MODAL_VARIANTS.DILAOG,
};

const MAGNIT_GIFTS = {
  name: 'magnit-gifts',
  variant: MODAL_VARIANTS.DILAOG,
};

const INFORM_AVAILABILITY = {
  name: 'inform-availability',
  variant: MODAL_VARIANTS.DILAOG,
};

const SECTOR_PRIZE = {
  name: 'sector-prize',
  variant: MODAL_VARIANTS.DILAOG,
};

const GIFTS = {
  name: 'gifts',
  variant: MODAL_VARIANTS.DILAOG,
};

const useHook = () => {
  const { pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const isMobile = useMedia('(max-width: 840px)');
  const [wasGiftOpened, setWasGiftOpened] = useState(false);

  const parsedQuery = useMemo(() => parseQuery(search));

  const modal = Array.isArray(parsedQuery.dialog) ? parsedQuery.dialog[0] : parsedQuery.dialog;

  useModalScrollLock(modal);

  const open = ({ name }, localPathname) => {
    const updatedQueryString = qs.stringify({ ...parsedQuery, dialog: name });
    const url = `${localPathname || pathname}?${updatedQueryString}`;

    if (name === GIFTS.name) {
      replace(url);
      setWasGiftOpened(true);
    } else if (wasGiftOpened) {
      replace(url);
      setWasGiftOpened(false);
    } else {
      push(url);
    }
  };

  const close = useCallback(() => {
    if (!modal) {
      return;
    }

    delete parsedQuery.dialog;
    const updatedQueryString = qs.stringify(parsedQuery);

    const url = `${pathname}?${updatedQueryString}`;
    if (modal === GIFTS.name || wasGiftOpened) {
      replace(url);
      setWasGiftOpened(false);
    } else {
      push(url);
    }
  }, [parsedQuery, pathname]);

  const checkIsOpened = ({ name: modalName }) => modal === modalName;

  const replaceToCurrentPageWithoutDialogQuery = () => {
    const parsedQueryWithoutDialog = { ...parsedQuery };
    delete parsedQueryWithoutDialog.dialog;
    replace(`${pathname}?${qs.stringify(parsedQueryWithoutDialog)}`);
  };

  useEffect(() => {
    if (isMobile && checkIsOpened(BASKET)) {
      replaceToCurrentPageWithoutDialogQuery();
      push(getBasketLink());
      return;
    }

    if (!isMobile && pathname === getBasketLink()) {
      open(BASKET, getIndexLink());
    }
  }, [pathname, search, isMobile]);

  return [
    {
      modal,
      isMenuOpened: checkIsOpened(MENU),
      isBasketOpened: checkIsOpened(BASKET),
      isPickPointOpened: checkIsOpened(PICK_POINT),
      isMagnitGiftsOpened: checkIsOpened(MAGNIT_GIFTS),
      isInformAvailabilityOpened: checkIsOpened(INFORM_AVAILABILITY),
      isSectorPrizeOpened: checkIsOpened(SECTOR_PRIZE),
      isGiftsOpened: checkIsOpened(GIFTS),
    },
    {
      close,
      openMenu: () => open(MENU),
      openBasket: () => open(BASKET),
      openPickPoint: () => open(PICK_POINT),
      openMagnitGifts: () => open(MAGNIT_GIFTS),
      openInformAvailability: () => open(INFORM_AVAILABILITY),
      openSectorPrize: () => open(SECTOR_PRIZE),
      openGifts: () => open(GIFTS),
    },
  ];
};

const ModalsContext = createContext();

export const useModals = () => useContext(ModalsContext);

export const ModalsStore = ({ children }) => (
  <ModalsContext.Provider value={useHook()}>{children}</ModalsContext.Provider>
);

ModalsContext.displayName = 'ModalsContext';
