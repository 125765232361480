import { AxiosRequestConfig } from 'axios';
import { axiosDefaultInstance, EApiAction, getApiUrl } from './ApiUrl';

import { ApiRequestMethod, TApiRequestConfigTypes, TApiResponseTypes } from './types';

const keyByMethodName = (name: 'post' | 'get') => {
  switch (name) {
    case 'post':
      return 'data';

    default:
      return 'params';
  }
};

export const requestTo = <T extends EApiAction>(action: T) => {
  return (
    requestParams: TApiRequestConfigTypes[T],
    config?: Omit<AxiosRequestConfig, 'method' | 'url' | 'data' | 'params'>,
  ) => {
    const method = ApiRequestMethod[action];

    return axiosDefaultInstance.request<TApiResponseTypes[T]>({
      method: ApiRequestMethod[action],
      url: getApiUrl(action),
      [keyByMethodName(method)]: requestParams,
      ...config,
    });
  };
};
