import React, { useMemo } from 'react';
import cn from 'classnames';
import { useInstallmentsModal } from 'stores/installments-modal';
import { INSTALLMENTS_ALLOWED_PRICE } from 'stores/constants';
import { ReactComponent as Icon } from 'assets/svg/plait-pay.svg';
import { TooltipTrigger } from '../TooltipTrigger/TooltipTrigger';
import styles from './PaymentInstallmentStatus.module.scss';

type Props = {
  price: number;
  isInfoType?: boolean;
  showModalInfo?: boolean;
};

const INSTALLMENTS_COUNT = 4;

export const PaymentInstallmentsStatus: React.FC<Props> = ({ price, isInfoType }) => {
  const [, { open }] = useInstallmentsModal();
  const isAllowedPrice = price > INSTALLMENTS_ALLOWED_PRICE;

  const label = useMemo(
    () =>
      !isAllowedPrice && isInfoType
        ? `при заказе от ${INSTALLMENTS_ALLOWED_PRICE} ₽`
        : `по ${Math.ceil(price / 4)} ₽ x ${INSTALLMENTS_COUNT}`,
    [isAllowedPrice, price],
  );

  const onWrapperClick = () => {
    if (!isInfoType || !isAllowedPrice) {
      open();
    }
  };

  return (
    <div
      onClick={onWrapperClick}
      className={cn(styles.status, {
        [styles.noBackground]: isInfoType,
      })}>
      <Icon className={cn(styles.logo)} />
      <div
        className={cn(styles.price, {
          [styles.priceInfo]: isInfoType,
        })}>
        {label}
      </div>
      {isInfoType && !isAllowedPrice && (
        <div className={styles.tooltip}>
          <TooltipTrigger onTrigger={open} />
        </div>
      )}
    </div>
  );
};
